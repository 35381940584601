import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_Get_UpFile_GetData,
    API_PUT_UpFile_SaveFile
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import Cropper from 'react-easy-crop';
import { getCroppedImg, getRotatedImage } from '../js/canvasUtils';
import { getOrientation } from 'get-orientation/browser';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';
import Swal from 'sweetalert2';

function Modify_photo() {
    const history = useHistory();

    //本頁使用檔案類型代號
    const uploadCode = new URLSearchParams(location.search).get("Param");
    //console.log(uploadCode);
    //const queryParams = new URLSearchParams(window.location.search);
    //const path = queryParams.get("path");

    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg ", ".png", "image/png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");

    //資料參數
    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方
    const [userData, setUserData] = useState({});//資料容器
    const [imgBase, setImgBase] = useState({});//伺服器取得圖片容器

    const [croppedImage, setCroppedImage] = useState(null);//Base64裁減後檔案 (單張圖片檔案容器)
    const [openFileName, setOpenFileName] = useState("");//原檔案名稱

    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步

    //本頁功能參數
    const ORIENTATION_TO_ANGLE = {
        '3': 180,
        '6': 90,
        '8': -90,
    }
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropSize, setCropSize] = useState({ width: 280, height: 360 });

    useEffect(() => {
        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });

        getFrontSet();
    }, []);

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/signup/modify_form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
                clearCroppedImage();
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得資料
    function getData() {

        API_Get_UpFile_GetData({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    setUserData(data.result[0]);
                    setImgBase(data.result[0].file);
                    //setUploadDoc
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
                clearCroppedImage();
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //更新圖片
    function putData() {
        if (document.getElementById("putButton") && !document.getElementById("putButton").disabled) {
            document.getElementById("putButton").disabled = true;
        } else {
            return null;
        }
        document.getElementById("loading").style.display = "block";

        if (!croppedImage) {
            document.getElementById("loading").style.display = "none";
            document.getElementById("putButton").disabled = false;
            return (Swal.fire({
                icon: 'error',
                text: "請先上傳圖片並裁剪",
            }))
        }
        if (!openFileName) {
            document.getElementById("loading").style.display = "none";
            document.getElementById("putButton").disabled = false;
            return (Swal.fire({
                icon: 'error',
                text: "請先上傳圖片並裁剪",
            }))
        }

        API_PUT_UpFile_SaveFile({
            "UserID": UserData.userID,
            "DocNo": uploadDoc,
            "DocName": userData.docName,
            "GATTID": userData.uExDocATTID,// --附件檔案流水號
            "UserEKID": userData.userEKID,// --使用考報考類組序號
            "UExDocID": userData.uExDocID,// --使用者文件流水號
            "DocRefFile": userData.docRefFile,// --Y // N
            "File": [
                {
                    "FileName": (openFileName ? openFileName : uploadDoc + "undefindedFile.jpg"),
                    "Base64String": croppedImage
                }
            ]
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    getData();
                    console.log(upFileMail(UserData.userID));
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("putButton").disabled = false;
                document.getElementById("loading").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
                document.getElementById("loading").style.display = "none";
            })
    }

    //下一步驟
    function Next() {
        if (imageSrc || croppedImage) {

            Swal.fire({
                text: "目前圖片尚未儲存，確定離開?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#ddd',
                confirmButtonText: '確定',
                cancelButtonText: '取消',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = next;
                    //history.push(next);
                }
            })
        } else {
            window.location.href = next;
            //history.push(next);
        }
    }

    //裁切圖片函數
    //選擇原始檔案
    const onFileChange = async (e) => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > fileMaxSize) {
                e.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "上傳檔案過大"
                    })
                )
            }
            //console.log(file)
            if (!fileType.includes(file.type)) {
                e.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "請選擇檔案格式 jpg 或 png"
                    })
                )
            }
            //console.log(file);
            let imageDataUrl = await readFile(file);
            try {
                // apply rotation if needed
                const orientation = await getOrientation(file)
                const rotation = ORIENTATION_TO_ANGLE[orientation]
                if (rotation) {
                    imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
                }

                setOpenFileName(file.name);
            } catch (e) {
                e.target.value = "";
                console.warn('failed to detect the orientation')
                console.warn(e)
            }

            setImageSrc(imageDataUrl)
        }
    }
    //清除原始,裁剪檔案
    const clearCroppedImage = useCallback(() => {
        setImageSrc(null);
        setCroppedImage(null);
        setOpenFileName("")
    }, [])
    //清除裁剪檔案
    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, [])
    //選定變形後裁剪
    const showCroppedImage = useCallback(async () => {
        document.getElementById("loading").style.display = "block";
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            //console.log('donee', { croppedImage })
            document.getElementById("loading").style.display = "none";
            setCroppedImage(croppedImage);

        } catch (e) {
            console.error(e);
            document.getElementById("loading").style.display = "none";
        }
    }, [imageSrc, croppedAreaPixels, rotation])
    //裁剪 內部運算函數
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }
    //END 裁剪 內部運算函數
    //本次參考 https://www.npmjs.com/package/react-easy-crop
    //下版本請修改為 https://www.npmjs.com/package/react-image-crop
    //END 裁切圖片函數

    //測試
    function testConsole() {
        console.log(openFileName)
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">

                    <div id="main">
                        <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                        {userData && userData.uDocCF ?
                            userData.uDocCF == "1"
                                ?
                                <div>審核狀態：審核通過<br />
                                    詳細訊息：{userData.uDocCFText || "(無)"}
                                </div>
                                :
                                userData.uDocCF == "2" ?
                                    <div>審核狀態：退回補件<br />
                                        詳細訊息：{userData.uDocCFText || "(無)"}
                                    </div>
                                    :
                                    userData.uDocCF == "4" ?
                                        <div>審核狀態：未通過<br />
                                            詳細訊息：{userData.uDocCFText || "(無)"}
                                        </div>
                                        : null
                            : null}
                        <fieldset id="upload_image">
                            <legend>考生照片</legend>
                            {/*<button onClick={testConsole}>測試按鈕</button>*/}
                            <div>

                                {((Object.keys(imgBase).length > 0
                                    && imgBase.length > 0
                                    && imgBase[0].base64String)
                                    ?
                                    <div>
                                        目前照片：<br />
                                        <img alt="考生大頭照片" src={imgBase[0].base64String} style={{ width: "150px", height: "auto", maxHeight: "200px", margin: "0 auto" }} />
                                    </div>
                                    :
                                    <div style={{ margin: "0", padding: "0", color: "red" }} >查無照片</div>)
                                }
                            </div>
                            {/*裁減功能容器*/}<div>
                                {/*選擇檔案*/}
                                {imageSrc ? null : (
                                    <div>
                                        上傳更新照片：
                                        <input type="file" onChange={onFileChange} accept={fileDotType.join(",")} />
                                    </div>
                                )}

                                {/*編輯調整*/}
                                {imageSrc && !croppedImage ?
                                    <React.Fragment>
                                        <div className="cropContainer">
                                            <Cropper
                                                image={imageSrc}
                                                crop={crop}
                                                rotation={rotation}
                                                zoom={zoom}
                                                aspect={7 / 9}
                                                cropSize={cropSize}
                                                onCropChange={setCrop}
                                                onRotationChange={setRotation}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </div>
                                        <div style={{ textAlign: "center", paddingTop: "2rem" }} >
                                            <label style={{ display: "inline-block" }}>縮放：
                                                <input type="range"
                                                    min={0.1}
                                                    max={3}
                                                    step={0.1}
                                                    value={zoom}
                                                    aria-labelledby="Zoom"
                                                    onChange={(e) => setZoom(e.target.value)}
                                                    style={{ width: "200px", margin: "0 auto" }}
                                                /></label>

                                            <label style={{ display: "inline-block" }}>旋轉：
                                                <input type="range"
                                                    min={0}
                                                    max={360}
                                                    step={1}
                                                    value={rotation}
                                                    aria-labelledby="Rotation"
                                                    onChange={(e) => setRotation(e.target.value)}
                                                    style={{ width: "200px", margin: "0 auto" }} /></label>
                                            <div style={{ padding: "1rem 0" }} >
                                                <input type="button"
                                                    onClick={clearCroppedImage}
                                                    variant="contained"
                                                    value="回上一步"
                                                    style={{ margin: "0.5rem" }}
                                                />
                                                <input type="button"
                                                    onClick={showCroppedImage}
                                                    variant="contained"
                                                    value="裁減"
                                                    style={{ margin: "0.5rem" }}
                                                />
                                            </div>
                                        </div>

                                    </React.Fragment>
                                    : null}

                                {/*結果預覽*/}
                                {croppedImage ?
                                    <div style={{ position: "relative", textAlign: "center", verticalAlign: "middle" }} >
                                        <img src={croppedImage} alt="剪裁前預覽" style={{ width: "200px", height: "auto", maxHeight: "300px", margin: "0 auto" }} />
                                        <div>
                                            <font color="red">※ 已裁剪，按「確認上傳」即可儲存。</font><br />
                                            <input type="button" value="取消" style={{ margin: "0.4rem" }} onClick={onClose} />
                                            <input id="putButton" type="button" value="確認上傳" style={{ margin: "0.4rem" }} onClick={putData} />
                                        </div>
                                    </div>
                                    : null}

                            </div>
                        </fieldset>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                        <div align="center">
                            {imageSrc || croppedImage ?
                                <span className="text-danger">
                                    ※請先儲存後再進入下一步
                                    <br />
                                </span>
                                : null}
                            <input name="button" type="button" value="下一步" onClick={Next} style={{ margin: "0.4rem 0" }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Modify_photo;
