import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_POST_RTUpFile_GetRTUpFileData,
    API_PUT_RTUpFile_SaveRTFileData
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';
import Swal from 'sweetalert2';

function modify_Native() {
    const history = useHistory();
    //const queryParams = new URLSearchParams(window.location.search);
    //const path = queryParams.get("path");

    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg", ".png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [fileList, setFileList] = useState([]);//資料容器
    const [imgBase, setImgBase] = useState([]);//伺服器取得圖片容器
    const [imgBaseItem, setimgBaseItem] = useState({});//彈窗圖片容器
    const [file, setFile] = useState(); //上傳資料
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const menuList = JSON.parse(localStorage.getItem('menuList')); //左側選單
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方
    //非雙語類組者顯示文字
    const [nonNative, setNonNative] = useState("");

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();
    }, []);

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block"; //開啟loading畫面
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                } else if (data.code == "9998") {
                    //彈窗
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none"; //關閉loading畫面
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    //彈窗
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/Second/Form");
                    })
                    document.getElementById("loading").style.display = "none"; //關閉loading畫面
                } else if (data.code == "9998") {
                    //彈窗
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none"; //關閉loading畫面
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得資料
    function getData() {
        API_POST_RTUpFile_GetRTUpFileData({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {

                    var item = [];
                    data.result.map((obj, i) => {
                        obj.base64 = "";
                        if (obj.file.length > 0) {
                            console.log(imgBase);

                            obj.file.map((obj2, i2) => {
                                obj.base64 = obj2.base64String;
                            });
                        }

                        item.push(obj);
                    });
                    //console.log(item);
                    setFileList(item);
                    document.getElementById("loading").style.display = "none";

                }else if (data.code == "3015") {//非雙語類組者
                    setNonNative(data.message); 
                    Swal.fire({
                        icon: 'warning',
                        text: "非報考雙語師資者，請勿上傳雙語資格證明",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "3037") {//非雙語類組者
                        setNonNative(data.message);
                        Swal.fire({
                            icon: 'warning',
                            text: "初試未勾選上傳附件資料，不可上傳。",
                        })
                        document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                          
                            //history.push('Sign_Status');
                        }
                    })
                  
                    
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

    }

    //上傳檔案
    function handleChange(index, event) {
        document.getElementById("loading").style.display = "block";
        //console.log(index);
        if (event.target.files.length > 0) {
            console.log(event.target.files);

            if (event.target.files[0].size > fileMaxSize) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "上傳檔案過大"
                    })
                )

            }
            if (!fileType.includes(event.target.files[0].type)) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "請選擇檔案格式 jpg 或 png"
                    })
                )
            }
            setFile(event.target.files[0]);
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                var list = {};
                if (index == 0) {
                    document.getElementById("loading").style.display = "none";
                    setImgBase({ [index]: reader.result });
                } else {
                    document.getElementById("loading").style.display = "none";
                    setImgBase({ ...imgBase, [index]: reader.result });
                }
            }
            document.querySelector(".viewImg" + index).classList.remove('hide');
            document.getElementById("Img").classList.add("hide");
            fileList[index].base64 = "";
            console.log(fileList);
        } else {
            document.getElementById("loading").style.display = "none";
        }
    }

    //Open彈窗
    function openModal(index) {
        setimgBaseItem(fileList[index].base64);
        var target = document.querySelector("#modal-showImg");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗
    function hideModal() {
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

    //儲存提示訊息
    function saveDataAlert(index) {
        var f = document.getElementsByName("file" + index)[0].value;
        //var f = document.querySelector("input[type='file']");
        //console.log(f.value)

        if (f == "") {
            Swal.fire({
                icon: 'error',
                text: '請上傳檔案'
            })
        } else {
            Swal.fire({
                icon: 'warning',
                text: "請務必留意上傳文件之正確性及是否符合相關條件；倘於審查時發現資績證明文件有誤，該資績分數不予採計",
            }).then((result) => {

                saveImg(index);
            })
        }


    }

    //儲存
    function saveImg(index) {
        document.getElementById("loading").style.display = "block";
        
        if (document.getElementById("putButton" + index) && !document.getElementById("putButton" + index).disabled) {
            document.getElementById("putButton" + index).disabled = true;
        } else {
            return null;
        }

        API_PUT_RTUpFile_SaveRTFileData({
            "UserID": UserData.userID,
            "DocNo": fileList[index].docNo,
            "DocName": fileList[index].docName,
            "GATTID": fileList[index].uExDocATTID,// --附件檔案流水號
            "UserEKID": fileList[index].userEKID,// --使用考報考類組序號
            "UExDocID": fileList[index].uExDocID,// --使用者文件流水號
            "DocRefFile": fileList[index].docRefFile,// --Y // N
            "File": [
                {
                    "FileName": (file.name ? file.name : uploadDoc + "undefindedFile.jpg"),
                    "Base64String": imgBase[index]
                }
            ]
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    document.getElementById("loading").style.display = "none";
                    imgBase[index] = "";
                    Swal.fire({
                        icon: 'success',
                        text: "儲存成功",
                    })
                    getData();
                    console.log(upFileMail(UserData.userID));
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }else {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("putButton" + index).disabled = false;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //history.push(next);
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                        <tbody>
                            <tr>
                                <td>上傳說明</td>
                                <td>瀏覽|選擇檔案</td>
                                <td>儲存</td>
                            </tr>

                            {fileList == null || fileList.length == 0 ?
                                <tr>
                                    <td colSpan="4">
                                        {nonNative}
                                        {/*這裡顯示可能是本土語言無效 或是 雙語無效*/}
                                        {/*2-6,2-12共用網址 有點難分辨這部分要怎麼打*/}
                                    </td>
                                </tr>
                                :
                                fileList.map((y, i) => (
                                    <tr key={"fileList" + i} >
                                        <td>
                                            {y.docName}
                                        </td>

                                        <td>
                                            {y.base64 != "" && (
                                                <div>
                                                    <font color="blue">已上傳成功</font>
                                                    <a href="#" onClick={(e) => openModal(i)} ><font color="red">(預覽)</font></a>
                                                </div>
                                            )}

                                            <div>
                                                {y.docRefFile != '9' && (
                                                     y.uExDocChk == '1' ? (
                                                        y.userDataLock == '1' ? (
                                                            <span>已下載報名表，無法變更檔案。</span>
                                                        ) : (
                                                            y.uDocCF != '0' && y.uDocCF != null ? (
                                                                <span>已審核，無法變更檔案。</span>
                                                            ) : (
                                                                <span>
                                                                    <input name={`file${i}`} type="file" size="10" onChange={(e) => handleChange(i, e)} accept={fileDotType.join(",")} />
                                                                    <br />
                                                                    <font color="red">※如有上傳檔案請按【儲存】</font>
                                                                    <br />
                                                                </span>
                                                            )

                                                        )

                                                        ) : (
                                                            <span>初試未勾選上傳附件資料，不可上傳。</span>
                                                        )
                                                )}
                                                
                                                <img className={y.base64 != "" ? "" : "hide"} id="Img" src={y.base64} name="Std_img" height="110" />
                                                <img className={`hide viewImg${i}`} src={imgBase[i]} name="Std_img" height="110" />
                                            </div>
                                        </td>
                                        <td>
                                            {y.docRefFile != '9' ? (
                                                y.uExDocChk == '1' ? (
                                                    y.userDataLock == '1' ? (
                                                        <span>已下載報名表，無法變更檔案。</span>
                                                    ) : (
                                                        y.uDocCF != '0' && y.uDocCF != null ? (
                                                            <span>已審核，無法變更檔案。</span>
                                                        ) : (
                                                            <input type="button" id={`putButton${i}`} name="saveImg" onClick={(e) => saveDataAlert(i)} value="儲存" />
                                                        )
                                                    )
                                                ) : (
                                                    <span>初試未勾選上傳附件資料，不可上傳。</span>
                                                )
                                                
                                            ) : (
                                                <span>初試上傳附件資料，無法變更。</span>
                                            ) }
                                       </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    <div align="center"><br />
                        <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
                    </div>

                </div>

                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBaseItem} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}


export default modify_Native;
