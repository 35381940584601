import axios from 'axios';
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': token,
        'menuid': localStorage.getItem("cookieCrack")
    }
}
const API_URL = window.apiUrl;
//API URL
/*export const API_HOST = "http://172.16.5.147/api";*/
export const API_HOST = API_URL
//�@�뱡�p��
const baseRequest = axios.create({
    baseURL: API_HOST
});
//�U����
export const downloadRequest = axios.create({
    baseURL: API_HOST,
    responseType: 'blob'
});


//#region �@��api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
//#endregion

//#region �\��-0
//0 �e�O�b��-���դU��
export const API_Get_Front_KindList = data => baseRequest.get('/Front/KindList', { params: data, headers: getHeaders() });
export const API_Get_Account_DefalultMenus = data => baseRequest.get('/Account/DefalultMenus', { params: data, headers: getHeaders() });
//1-�e�O�b��-���U
export const API_Post_Login_SignUp = data => baseRequest.post('/Login/SignUp', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});

//2 - �e�O�b�� - �n�J
export const API_Post_Login_Singin = data => baseRequest.post('/Login/Singin', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});

//2-0 - �ɮפW�Ǫ��A
export const API_GET_EMail_UpFile = data => baseRequest.get('/Front/SendUpFileEMail', { params: data, headers: getHeaders(getAuthToken()) });

//2-1 - �W���ɮ� - �d��
export const API_Get_UpFile_GetData = data => baseRequest.post('/UpFile/GetUpFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-2 - �W���ɮ� - �x�s
export const API_PUT_UpFile_SaveFile = data => baseRequest.put('/UpFile/SaveFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-3 - �W���ɮ� - �R��
export const API_PUT_UpFile_DelFile = data => baseRequest.put('/UpFile/DelFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-4 - �W�ǪA���ҩ� - �d��
export const API_Get_UpFile_GetSvrCertData = data => baseRequest.post('/UpFile/GetSvrCertData', JSON.stringify(data), {
    headers: getHeaders(getAuthToken())
});

//2-5 - �W�ǪA���ҩ� - �x�s
export const API_PUT_UpFile_SaveSvrCertData = data => baseRequest.put('/UpFile/SaveSvrCertData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-6 - �W�ǪA���ҩ� - �R��
export const API_PUT_UpFile_DelSvrCertData = data => baseRequest.put('/UpFile/DelSvrCertData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-7 - �W�Ǻӳդh�Ǧ��Ү� - �d��
export const API_POST_UpFile_GetDiploma = data => baseRequest.post('/UpFile/getDiploma', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-8 - �W�ǪA���ҩ�(�P�ҾǮճs��A�Ⱥ��T�~) - �d��
export const API_Get_UpFile_getDoc3Yata = data => baseRequest.get('/UpFile/getDoc3Y', {
    headers: getHeaders(getAuthToken())
});

//2-9 - �W�ǪA���ҩ�(�P�ҾǮճs��A�Ⱥ��T�~) - �s��
export const API_PUT_UpFile_putDoc3Yta = data => baseRequest.put('/UpFile/putDoc3Y', JSON.stringify(data), { params: data, headers: getHeaders(getAuthToken()) });

//2-10 - �W�Ǻӳդh�Ǧ��Ү� - �d��
export const API_Post_UpFile_GetDiploma = data => baseRequest.get('/UpFile/getDiploma', { params: data, headers: getHeaders(getAuthToken()) });

//2-11 - �W�Ǻӳդh�Ǧ��Ү� - �x�s
export const API_PUT_UpFile_SaveDiploma = data => baseRequest.put('/UpFile/SaveFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-12 - �W�ǭ^���O�˩w�Ү� - �d��
export const API_POST_UpFile_GetEng = data => baseRequest.post('/UpFile/getEng', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-13 - �W�ǭ^���O�˩w�Ү� - �x�s
export const API_PUT_UpFile_SaveEng = data => baseRequest.put('/UpFile/SaveEngFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//3-1 - �ѰO�K�X�H�H(���ҽX)
export const API_Get_Account_ForgetPwdSend = data => baseRequest.get('/Account/ForgetPwdSend', { params: data, headers: getHeaders() });

//3-2 - �ѰO�K�X����
export const API_Post_Account_ForgetPwd = data => baseRequest.post('/Account/ForgetPwd', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});

//3-3 - ���H�b���ҥΫH
export const API_Get_Account_SendEmail = data => baseRequest.get('/Account/SendEmail', { params: data, headers: getHeaders() });

//4-1 - �ק���W���d��
export const API_Get_Modify_Modifyget = data => baseRequest.get('/Modify/Modifyget', { params: data, headers: getHeaders(getAuthToken()) });

//4-2 - �ק���W���s��
export const API_Put_Modify_Modifyput = data => baseRequest.put('/Modify/Modifyput', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//4-3 - �������խק�
export const API_Put_Front_ModifyKind = data => baseRequest.put('/Front/ModifyKind', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//4-4 - �������խק�(����)
export const API_Put_Front_ModifyExKind = data => baseRequest.put('/Front/ModifyExKind', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//5-1 �̷s�����d��
export const API_Get_Front_getNews = data => baseRequest.get('/Front/getNews', { params: data, headers: getHeaders() });

//5-2 ���o�e�x�]�w����(��token)
export const API_Get_UpFile_getFrontSet = data => baseRequest.get('/UpFile/getFrontSet', { params: data, headers: getHeaders(getAuthToken()) });

//6-1 ���o�ֹ���W��
export const API_Get_Confirm_Chinfo = data => baseRequest.get('/Confirm/Chinfo', { params: data, headers: getHeaders(getAuthToken()) });

//6-2 �U�����W���A
export const API_Get_Confirm_RegData = data => baseRequest.get('/Confirm/RegData', { params: data, headers: getHeaders(getAuthToken()) });

//7-1 �C�L�����
export const API_Get_Print_PrintPassNo = data => baseRequest.get('/Print/PrintPassNo', { params: data, headers: getHeaders(getAuthToken()) });

//7-2 ú�O����
export const API_Get_Front_PayInfo = data => baseRequest.get('/Front/PayInfo', { params: data, headers: getHeaders(getAuthToken()) });

//7-3 ����ú�O�渹
export const API_Get_Front_ATMCode = data => baseRequest.get('/Front/ATMCode', { params: data, headers: getHeaders(getAuthToken()) });

//7-4 �C�L���W��
export const API_Get_Print_RegFormPDF = data => baseRequest.get('/Print/RegFormPDF', { params: data, headers: getHeaders(getAuthToken()) });

//8-1 ���o�e�x�]�w����(����token)
export const API_Get_Front_getFrontSet = data => baseRequest.get('/Front/getFrontSet', { params: data, headers: getHeaders() });

//9-1 Ū���O�_���W����g����
export const API_Get_UpFile_getUComplete = data => baseRequest.get('/UpFile/getUComplete', { params: data, headers: getHeaders(getAuthToken()) });

//�Ƹ�
//0.Login
//0 �Ƹյn�J
export const API_POST_RTLogin_Singin = data => baseRequest.post('/RTLogin/RTSingin', JSON.stringify(data), { headers: getHeaders() });

//2.�ק�Ƹճ��W�����
//2-1-0 �Ƹճ��W�� - �d��
export const API_Get_RTModify_RTModifyget = data => baseRequest.get('/RTModify/RTModifyget', { params: data, headers: getHeaders(getAuthToken()) });

//2-1-1 �Ƹճ��W�� - �s��
export const API_Put_Modify_RTModifyput = data => baseRequest.put('/RTModify/RTModifyput', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-2-0 - �W���ɮ� - �d��
export const API_POST_RTUpFile_GetRTUpFileData = data => baseRequest.post('/RTUpFile/GetRTUpFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-2-1 - �W���ɮ� - �x�s
export const API_PUT_RTUpFile_SaveRTFileData = data => baseRequest.put('/RTUpFile/SaveRTFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-3-0 - �W�ǪA���ҩ� - �d��
export const API_Get_RTUpFile_GetRTSvrCertData = data => baseRequest.post('/RTUpFile/GetRTSvrCertData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-3-1 - �W�ǪA���ҩ� - �x�s
export const API_PUT_RTUpFile_SaveRTSvrCertData = data => baseRequest.put('/RTUpFile/SaveRTSvrCertData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-4-0 - �W�ǭ^���O�˩w�Ү�(�@������) - �d��
export const API_POST_RTUpFile_GetRTEng = data => baseRequest.post('/RTUpFile/getRTEng', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//2-4-1 - �W�ǭ^���O�˩w�Ү�(�@������) - �x�s
export const API_PUT_RTUpFile_SaveRTEngFileData = data => baseRequest.put('/RTUpFile/SaveRTEngFileData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) })

//2-2-0 - �t��UexDocMark-�W���ɮ� - �d��
export const API_POST_RTUpFile_GetMarkUP = data => baseRequest.post('/RTUpFile/getMarkUP', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//3.�T�{�Ƹճ��W���A
//3-1�ֹ���W��
export const API_Get_RTConfirm_RTChinfo = data => baseRequest.get('/RTConfirm/RTChinfo', { params: data, headers: getHeaders(getAuthToken()) });

//3-2 �U�����W���A
export const API_Get_RTConfirm_RegData = data => baseRequest.get('/RTConfirm/RegData', { params: data, headers: getHeaders(getAuthToken()) });

//4.��լd��
//4-1 �d���n�J
export const API_POST_Login_ScoreSingin = data => baseRequest.post('/Login/ScoreSingin', JSON.stringify(data), { data: data, headers: getHeaders() });
//4-2 �d������
export const API_Get_Import_StuExam = data => baseRequest.get('/Import/StuExam', { params: data, headers: getHeaders(getAuthToken()) });

//5.���@���
//5-1���@�d��
export const API_Get_Wish_QueryWishData = data => baseRequest.get('/Wish/QueryWishData', { params: data, headers: getHeaders(getAuthToken()) });
//5-2���@�s��
export const API_PUT_Wish_UPWishData = data => baseRequest.put('/Wish/UPWishData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

//�Ƹճ��W���U��
export const API_Get_Print_RTRegFormPDF = data => baseRequest.get('/Print/RTRegFormPDF', { params: data, headers: getHeaders(getAuthToken()) });

//�ɥ�n�J
export const API_POST_Repair_RepairLogin = data => baseRequest.post('/Repair/RepairLogin', JSON.stringify(data), { data: data, headers: getHeaders() });

//�ɥ�

// �W�Ǹɥ� - �d��
export const API_Get_Repair_RepairList = data => baseRequest.get('/Repair/RepairList', { params: data, headers: getHeaders(getAuthToken()) });

// �W�Ǹɥ� - �x�s
export const API_PUT_Repair_UpdateRepairData = data => baseRequest.put('/Repair/UpdateRepairData', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

// �W�ǪA���ҩ� - �d��
export const API_Get_Repair_RepairYear = data => baseRequest.post('/Repair/RepairYear', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

// �W�ǪA���ҩ� - �x�s
export const API_PUT_Repair_RepairYear = data => baseRequest.put('/Repair/RepairYear', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });


// �W�ǭ^���O�˩w�Ү�(�@������) - �d��
export const API_POST_Repair_RepairRTEngFile = data => baseRequest.post('/Repair/GetRepairEn', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });

// �W�ǭ^���O�˩w�Ү�(�@������) - �x�s
export const API_PUT_Repair_RepairRTEngFile = data => baseRequest.put('/Repair/RepairRTEngFile', JSON.stringify(data), { headers: getHeaders(getAuthToken()) })

// �ҥͳ��W��ƤĿ�i�W���x�s
export const API_PUT_RetestCheck_UExDocChkCheck = data => baseRequest.put('/RetestCheck/UExDocChkCheck', JSON.stringify(data), { headers: getHeaders(getAuthToken()) })

//���@
//0.Login
//0 ���@�n�J
export const API_POST_WishLogin_WishSingin = data => baseRequest.post('/WishLogin/WishSingin', JSON.stringify(data), { headers: getHeaders() });

//2-1 �C�L���o���G
export const API_Get_Print_PrintExDist = data => baseRequest.get('/Print/PrintExDist', { params: data, headers: getHeaders(getAuthToken()) });