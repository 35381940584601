import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_POST_WishLogin_WishSingin,
    API_Get_Account_ForgetPwdSend,
    API_Post_Account_ForgetPwd,
    API_Get_Account_SendEmail,
    API_POST_RTLogin_Singin
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import ClientCaptcha from 'react-client-captcha';
import { checkAuthToken } from "../global/TokenUtil";
import Swal from 'sweetalert2';

function Modify_SFLogin() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [UIDNo, setUIDNo] = useState(""); //身分證號
    const [Password, setPassword] = useState(""); //密碼
    const [captchaCode, setCaptchaCode] = useState('');//驗證碼圖片
    const [UserCaptchaCode, setUserCaptchaCode] = useState('');//User驗證碼

    const [findPwdCode, setfindPwdCode] = useState(""); //忘記密碼驗證碼
    const [NewPassword, setNewPassword] = useState(""); //新密碼
    const [CkNewPassword, setCkNewPassword] = useState(""); //確認新密碼
    const [findPwdCodeTime, setfindPwdCodeTime] = useState(""); //驗證碼到期時間

    const [findPwdCodeTimeShow, setfindPwdCodeTimeShow] = useState(false);//驗證碼到期時間顯示

    useEffect(() => {
        //啟用信判斷
        const urlQueryCode = new URLSearchParams(location.search).get("Code");
        if (urlQueryCode == "0000") {
            Swal.fire({
                icon: 'success',
                text: "啟用成功，請重新登入。",
            })
            history.push("../Signup/Signin");
        }
        if (urlQueryCode == "1001") {
            Swal.fire({
                icon: 'success',
                text: "已啟用，請直接登入。",
            })
            history.push("../Signup/Signin");
        }

    }, []);

    //檢查必填 密碼 身分證號 驗證碼
    function formRequired(selector) {
        var result = true;
        const requiredlist = document.querySelectorAll(selector + " [data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        return result;
    }

    //檢查密碼 身分證號 驗證碼
    function chkData(type) {
        //console.log('檢查密碼 身分證號 驗證碼');
        var result = true;
        var err = "";

        // 身分證字號
        var taiwanIdValidator = require("taiwan-id-validator2");

        if (taiwanIdValidator.isNationalIdentificationNumberValid(UIDNo) == false) {
            err += '身分證字號輸入錯誤<br/>';
            result = false;
        }



        if (type == 'findPwd') {
            if (NewPassword.length < 5) {
                err += '<pre>密碼字數請設定5~10位字元<br/>';
                result = false;
            }
            if (NewPassword != CkNewPassword) {
                err += '確認密碼輸入錯誤<br/>';
                result = false;
            }
        }
        if (type == 'login') {
            if (Password.length < 5) {
                err += '密碼字數請設定5~10位字元<br/>';
                result = false;
            }
            if (captchaCode.toLowerCase() != UserCaptchaCode.toLowerCase()) {
                err += '驗證碼輸入錯誤<br/>';
                result = false;
            }
        }
        if (err != "") {
            Swal.fire({
                icon: 'error',
                html: err,
            })
        }
        return result;
    }

    //重新設定
    function Reset() {
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            element.classList.remove("error");
        });
        //console.log('重新設定');
        setUIDNo(""); //身分證號
        setPassword(""); //密碼
        setUserCaptchaCode(""); //User驗證碼
    }

    //儲存，下一步
    function Next() {
        console.log('下一步');
        //document.getElementById("loading").style.display = "block";
        //console.log(document.querySelectorAll("#login [data-required]"));
        var result_Required = formRequired('#login');
        if (result_Required) {
            var result_chkData = chkData("login");

            if (result_chkData === true) {
                console.log('儲存');
                API_POST_WishLogin_WishSingin({
                    "AcntNo": UIDNo,
                    "AcntPwd": Password
                })
                    .then((response) => response.data)
                    .then((data) => {
                        localStorage.clear();
                        if (!checkAuthToken(data)) {
                            /* window.location = "../signup/Signin";*/
                            history.push("/Ambition/Login");
                        }
                        if (data.code == "0000") {
                            if (data.result.data == null) {
                                Swal.fire({
                                    icon: 'error',
                                    text: "資料不齊全,無法登入",
                                })
                            }  else {
                                var UserData = data.result.data;
                                localStorage.setItem('UserData', JSON.stringify(UserData));
                                localStorage.setItem("isLogin", "1");
                                localStorage.setItem('menuList', JSON.stringify(data.result.data.menuList));
                                document.getElementById("loading").style.display = "none";
                                window.location = "../Ambition/New";

                                //history.push("/signup/modify_form");
                            }
                        }
                        else if (data.code == "9998") {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            }).then((result) => {
                                if (data.result && data.result.redirectUrl) {
                                    window.location.href = data.result.redirectUrl;
                                } else {
                                    window.location.href = "/Ambition/Login";
                                }
                            });
                            document.getElementById("loading").style.display = "none";
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            });
                            setUserCaptchaCode(""); //User驗證碼
                            document.getElementById("retryButton").click();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push("/");
                        }
                    });
            }
        }

    }

    function goEnter(e) {
        if (e.key === "Enter") {
            if (UIDNo && Password && UserCaptchaCode) {
                Next();
            }
        }
    }

    //Open彈窗
    function openModal(ModalName) {
        if (ModalName == 'findpwd') {
            setfindPwdCodeTimeShow(false);
            setfindPwdCodeTime("");
            var id = "modal-findpwd";
        }
        if (ModalName == 'accenable') {

            var id = "modal-accenable";
        }

        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    //Close彈窗
    function hideModal(ModalName) {
        if (ModalName == 'findpwd') {
            var id = "modal-findpwd";
        }
        if (ModalName == 'accenable') {
            var id = "modal-accenable";
        }
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {
            element.classList.remove("error");
        });

        var target = document.querySelector('#' + id);
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);

    }
    //寄送驗證碼
    function sendFindPwdCode() {
        if (UIDNo) {
            API_Get_Account_ForgetPwdSend({
                "ID": UIDNo
            })
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {

                        var date = new Date();
                        var min = date.getMinutes();
                        date.setMinutes(min + 30);

                        setfindPwdCodeTime(date.toLocaleString());
                        setfindPwdCodeTimeShow(true);


                        Swal.fire({
                            icon: 'info',
                            html: "<div style='text-align: left'>請至電子信箱：" + data.result.email + "<br/>收取【驗證碼】,有效期限為" + date.toLocaleString() + "。",
                        })
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                        document.getElementById("loading").style.display = "none";
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });

        } else {
            Swal.fire({
                icon: 'error',
                text: "請輸入身分證字號",
            })

        }
    }
    //寄送啟用信
    function sendAccEnable() {
        var result_Required = formRequired('#accenable');
        if (result_Required) {
            API_Get_Account_SendEmail({
                "ID": UIDNo
            })
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {

                        Swal.fire({
                            icon: 'info',
                            html: "<div style='text-align: left'>請至電子信箱：" + data.result.email + "<br/>收取【註冊認證信】。</div>",
                        })
                        hideModal("accenable");
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                        document.getElementById("loading").style.display = "none";
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }

    }
    //驗證必填、更新 新密碼
    function upNewPwd() {
        var result_Required = formRequired('#findPwd');
        if (result_Required) {

            var result_chkData = chkData("findPwd");

            if (result_chkData === true) {

                API_Post_Account_ForgetPwd({
                    "AcntNo": UIDNo,
                    "NewPassword": NewPassword,
                    "RePassword": CkNewPassword,
                    "Verify": findPwdCode

                })
                    .then((response) => response.data)
                    .then((data) => {
                        if (data.code == "0000") {

                            Swal.fire({
                                icon: 'success',
                                text: "更新密碼成功，請重新登入。",
                            })
                            Reset();
                            hideModal("findpwd");
                            setfindPwdCode("");
                            setNewPassword("");
                            setCkNewPassword("");
                        }
                        else if (data.code == "9998") {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            }).then((result) => {
                                if (data.result && data.result.redirectUrl) {
                                    window.location.href = data.result.redirectUrl;
                                } else {
                                    window.location.href = "/";
                                }
                            });
                            document.getElementById("loading").style.display = "none";
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push("/");
                        }
                    });

            }
        }
    }

    function clear() {
        localStorage.clear();
    }
    return (
        <>
            <div id="master-main-bg">
                <div id="left">
                    <div id="submenu-title">
                        <span>志</span>
                        <span>願</span>
                        <span>選</span>
                        <span>填</span>
                        <span>/</span>
                        <span>列</span>
                        <span>印</span>
                        <span>分</span>
                        <span>發</span>
                        <span>函</span>
                    </div>
                </div>
                <div id="content">
                    <h1>志願選填登入</h1>
                    <div id="main">

                        <fieldset>
                            <legend>登入</legend>
                            <table id="login" width="650" border="0" cellPadding="5" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td width="50">身分證號：</td>
                                        <td width="608">
                                            <input name="login_id" type="text" style={{ marginLeft: "-60px" }} className="Ariel" id="login_id2" size="12" maxLength="10" value={UIDNo} onChange={(e) => { setUIDNo(e.target.value) }} onKeyPress={goEnter} data-required />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>密碼：</td>
                                        <td>
                                            <input name="login_pwd" type="password" style={{ marginLeft: "-60px" }} className="Ariel" id="login_pwd" size="12" maxLength="10" value={Password} onChange={(e) => { setPassword(e.target.value) }} onKeyPress={goEnter} data-required />
                                            <input type="button" name="Submit" value="忘記密碼" onClick={(e) => { openModal('findpwd') }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><ClientCaptcha captchaCode={(code) => setCaptchaCode(code)} chars="0123456789" /></td>
                                        <td>
                                            <input name="NewCode" type="text" className="input" style={{ marginLeft: "-60px" }} id="NewCode" maxLength="4" size="12" value={UserCaptchaCode} onChange={(e) => { setUserCaptchaCode(e.target.value) }} onKeyPress={goEnter} data-required /> (請根據左方顯示輸入驗證碼)
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input type="hidden" name="ET" value="" />
                                            <input type="button" name="Submit" value="下一步" onClick={Next} />
                                            <input type="button" name="Submit2" value="重新設定" onClick={Reset} />
                                            <input type="button" name="Submit3" value="清localStorage" onClick={clear} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>

                    </div>
                </div>

                <div id="modal-findpwd" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="findPwd">
                            <fieldset>
                                <legend>忘記密碼</legend>

                                <div className="btn-holder flex-container text-center">
                                    <div className="top-table" style={{ width: "100px" }}>身分證號：</div>
                                    <div className="top-value">
                                        <input name="login_id" type="text" className="Ariel" id="login_id3" maxLength="25" value={UIDNo} onChange={(e) => { setUIDNo(e.target.value) }} data-required />

                                    </div>
                                    <div> <input type="button" name="send" maxLength="25" value="寄送驗證碼" onClick={sendFindPwdCode} /></div>
                                </div>
                                <span className={`form-item text-center ${findPwdCodeTimeShow ? "show-help" : ""}`} style={{ width: '850px' }}>
                                    <div className="btn-holder flex-container">
                                        <div className="top-table" style={{ width: "140px" }}></div>
                                        <span className="help-word" style={{ fontSize: '16px' }}> 有效期限為<span className="time">{findPwdCodeTime}</span></span>
                                    </div>
                                </span>
                                <div className="btn-holder flex-container text-center">
                                    <div className="top-table" style={{ width: "100px" }}>驗證碼：</div>
                                    <div className="top-value">
                                        <input type="text" value={findPwdCode} maxLength="25" onChange={(e) => { setfindPwdCode(e.target.value) }} data-required />
                                    </div>
                                    <div style={{ width: "100px" }}></div>
                                </div>
                                <div className="btn-holder flex-container text-center">
                                    <div className="top-table" style={{ width: "100px" }}>新密碼：</div>
                                    <div className="top-value">
                                        <input type="password" value={NewPassword} maxLength="10" placeholder="密碼字數請設定5~10位字元" onChange={(e) => { setNewPassword(e.target.value) }} data-required />
                                    </div>
                                    <div style={{ width: "100px" }}></div>
                                </div>
                                <div className="btn-holder flex-container text-center">
                                    <div className="top-table" style={{ width: "100px" }}>確認密碼：</div>
                                    <div className="top-value">
                                        <input type="password" value={CkNewPassword} maxLength="10" placeholder="密碼字數請設定5~10位字元" onChange={(e) => { setCkNewPassword(e.target.value) }} data-required />
                                    </div>
                                    <div style={{ width: "100px" }}></div>
                                </div>
                                <br />
                                <div className="btn-holder text-center">
                                    <a className="btn btn-primary" onClick={upNewPwd}>
                                        <span>確定</span>
                                    </a>
                                    <a className="btn btn-dark" onClick={(e) => { hideModal('findpwd') }}>
                                        <span>取消</span>
                                    </a>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>

                <div id="modal-accenable" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>帳號啟用</legend>

                                <div className="btn-holder flex-container text-center">
                                    <div className="top-table" style={{ width: "100px" }}>身分證號：</div>
                                    <div className="top-value">
                                        <input name="login_id" type="text" className="Ariel" id="login_id4" maxLength="25" value={UIDNo} onChange={(e) => { setUIDNo(e.target.value) }} data-required />

                                    </div>
                                </div>
                                <br />
                                <div className="btn-holder text-center">
                                    <a className="btn btn-primary" onClick={sendAccEnable}>
                                        <span>寄送啟用信</span>
                                    </a>
                                    <a className="btn btn-dark" onClick={(e) => { hideModal('accenable') }}>
                                        <span>取消</span>
                                    </a>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default Modify_SFLogin;
