import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_Get_Front_getFrontSet } from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import Swal from 'sweetalert2';

function Process01() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {
        getData();
    }, []);

    //取得資料
    function getData() {
        API_Get_Front_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {

                if (data.code == "0000") {

                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }

            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    
    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="報名流程說明" modalSide="報名流程說明" modalUrl={window.location.pathname} />
                <div id="content">
                    
                    <div id="main">
                        <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                       
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Process01;
