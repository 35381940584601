import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_Import_StuExam,
    API_Get_Front_getFrontSet
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import Swal from 'sweetalert2';
import getDate from "date-fns/esm/fp/getDate";

function Search2Score() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    const [stuPass, setStuData] = useState({});//學生資料

    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {
        let stuPass = localStorage.getItem("userPass");
       
        try {
            if (stuPass) {
                let aqr = JSON.parse(stuPass);
                console.log(aqr);
                if (aqr.SResult && aqr.UserPassNo) {
                    getData(aqr.SResult, aqr.UserPassNo);
                } else {
                    throw "無效參數";
                }
                window.onbeforeunload = function (e) {
                    //console.log("remove Pass");
                    //alert("移除背景")
                    e.returnValue = "關閉將清除資料"
                    localStorage.setItem("userPass", "");
                };
            } else {
                throw "無效參數";
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                text: "重新登入查分",
            }).then((result) => {
                window.location.href = "index";
            });
        }
    }, []);

    //取得資料

    function getData(sr, upn) {
        API_Get_Import_StuExam({
            "UserPassNo": upn, //准考證號
            "SResult": sr,   //初試複試
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {
                    getFrontSet(data.result[0]);
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getFrontSet(stuData) {
        console.log(stuData);
        API_Get_Front_getFrontSet({
            "FrontUrl": window.location.pathname + stuData.DocKindNo,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                console.log(data);
                if (data.code == "0000") {

                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            let aqr = obj.frontUrlText;
                            //console.log(stuData);
                            for (let keyWord of Object.keys(stuData)) {
                                if (aqr.includes(`{${keyWord}}`)) {
                                    const regex = new RegExp(`{${keyWord}}`, 'g');
                                    if (stuData[keyWord]) {
                                        aqr = aqr.replace(regex, stuData[keyWord]);
                                    } else {
                                        aqr = aqr.replace(regex, "-");
                                    }
                                }
                            }
                            //console.log(aqr);
                            setUdocMark_p(aqr);
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }

            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function Next() {
        window.location.href = "index2";
        localStorage.setItem("userPass", "");
        //history.push("index");
    }
    return (
        <>
            <div id="master-main-bg">
                <div id="left">
                    <div id="submenu-title">
                        <span>線</span>
                        <span>上</span>
                        <span>查</span>
                        <span>分</span>
                    </div>
                </div>

                <div id="content">
                    <div id="main">
                        <h2>成績單</h2>
                        <div className="scoreSheet" dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>

                        <div align="center">
                            <input type="button" value="退出" name="sub" onClick={(e) => Next()} style={{ width: "80px", margin: "0.4rem 1rem" }} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default Search2Score;
