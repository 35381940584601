import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_Front_KindList,
    API_Get_RTModify_RTModifyget,
    API_PUT_RetestCheck_UExDocChkCheck
} from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import LeftSideW from '../components/partial/leftSide';
import Jdata from './docMark.json';

import TWzipcode from 'react-twzipcode';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';

function SecondForm2() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const date = new Date();
    const birYStar = date.getFullYear() - 1911 - 65; //生日年起迄
    const [rocDate, setRocDate] = useState("");

    const [KindArrayList, setKindArrayList] = useState([{}]); //報考類科下拉
    const [ExKindNo, setExKindNo] = useState(UserData.exKindNo); //報考類科

    const [UdocList, setUdocList] = useState([]); //使用者上傳文件清單
    const [UserList, setUserList] = useState({}); //使用者資料清單

    const [filtered, setfiltered] = useState([]); //文件列表
    const [filteredd, setfilteredd] = useState([]); //文件列表


    const [Jobchk, setJobchk] = useState("0"); //現職 勾選
    const [C001chk, setC001chk] = useState(""); //c001 文件 勾選
    const [C001chkT, setC001chkT] = useState(""); //c001 文件 原因

    const [resumeSave, setResumeSave] = useState([]); //經歷清單
    const [next, setnext] = useState(""); //下一步
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [Complete, setComplete] = useState(""); //是否為填寫完成
    const areatId = [];//跳轉ID

    useEffect(() => {
        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        console.log(obj3);
                        setnext(obj3.NextUrl);
                    }
                });
            });
        });

        getData();
    }, []);

    function getData() {

        API_Get_RTModify_RTModifyget({
            "UserEKID": UserData.userEKID
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/"
                }
                if (data.code == "0000") {
                    console.log(data);
                    setExKindNo(data.result.User[0].ExKindNo);
                    setUdocList(data.result.data.docList);
                    setUserList(data.result.User[0]);
                    setComplete(data.result.User[0].UComplete);

                    var i = 0;
                    var aa = [];

                    data.result.data.docType.map(obj => { //文件
                        obj.item = [];
                        data.result.data.docBaseList.map(obj2 => {
                            if (obj.DocKType == obj2.docKType) {
                                obj2.doc =
                                {
                                    "CodeText": obj.CodeText,
                                    "DocFileCnt": "1",
                                    "DocKMode": obj2.docKMode,
                                    "DocKType": obj2.docKType,
                                    "DocKUPMode": null,
                                    "DocMark1": obj2.docMark1,
                                    "DocMark2": obj2.docMark2,
                                    "DocMark3": obj2.docMark3,
                                    "DocName": obj2.docName,
                                    "DocNo": obj2.docNo,
                                    "DocRefFile": obj2.docRefFile,
                                    "EndM": "",
                                    "EndY": "",
                                    "StarM": "",
                                    "StarY": "",
                                    "UExDocATTID": null,
                                    "UExDocChk": (obj2.docNo == "B001" ? "1" : "0"),
                                    "UExDocID": null,
                                    "UExDocMark1": null,
                                    "UExDocMark2": null,
                                    "UExDocMark3": null
                                };
                                if (obj2.dM1 != null) {

                                    obj2.dT1List.map((obj4, i4) => {
                                        obj4.attr = [];
                                        obj2.doc[obj4.docMark + i4] = "";
                                       
                                        data.result.data.codeBase.map(obj3 => {

                                            if (obj4.docMark == obj3.codeField) {
                                                aa.name = obj3.codeField;
                                                aa.Cname = obj3.codeText;
                                                aa.value = obj3.codeValue;
                                                aa.chked = "0";

                                                obj4.attr.push(aa);
                                                aa = [];
                                            }


                                        });

                                    });

                                }

                                if (obj2.dM3 != null) {

                                    obj2.dT3List.map((obj4, i4) => {
                                        obj4.value = "";
                                    });

                                }

                                data.result.data.docList.map(doc => {
                                    doc.StarY = "";
                                    doc.StarM = "";
                                    doc.EndY = "";
                                    doc.EndM = "";

                                    if (doc.docNo == obj2.docNo) {
                                        if (doc.uExDocMark1 && obj2.dM1 == "R") {
                                            doc.udT1List.map(docobj => {
                                                obj2.dT1List.map(dTobj => {
                                                    dTobj.attr.map(attobj => {
                                                        if (attobj.Cname == docobj.uDocMark) {
                                                            attobj.chked = "1";
                                                        }

                                                    })
                                                })
                                            })
                                        }

                                        if (doc.uExDocMark2 && obj2.dM2 == "CYM") {
                                            doc.StarY = (doc.uExDocMark2.slice(0, 4)) - 1911;
                                            doc.StarM = parseInt(doc.uExDocMark2.slice(5, 7), 10);
                                        }
                                        if (doc.uExDocMark3 && obj2.dM3 == "CYM") {
                                            doc.EndY = (doc.uExDocMark3.slice(0, 4)) - 1911;
                                            doc.EndM = parseInt(doc.uExDocMark3.slice(5, 7), 10);
                                        }
                                        if (doc.uExDocMark3 && obj2.dM3 == "T") {
                                            doc.udT3List.map((docobj, docinx) => {
                                                obj2.dT3List.map((dTobj, dtinx) => {

                                                    if (docobj.uDocMark != "" && docinx == dtinx) {
                                                        dTobj.value = docobj.uDocMark;
                                                    }
                                                })
                                            })
                                        }


                                        /* obj2.doc = doc;*/
                                        obj2.doc =
                                        {
                                            "CodeText": doc.codeText,
                                            "DocFileCnt": doc.cocFileCnt,
                                            "DocKMode": doc.docKMode,
                                            "DocKType": doc.docKType,
                                            "DocKUPMode": doc.docKUPMode,
                                            "DocMark1": doc.docMark1,
                                            "DocMark2": doc.docMark2,
                                            "DocMark3": doc.docMark3,
                                            "DocName": doc.docName,
                                            "DocNo": doc.docNo,
                                            "DocRefFile": doc.docRefFile,
                                            "EndM": doc.EndM,
                                            "EndY": doc.EndY,
                                            "StarM": doc.StarM,
                                            "StarY": doc.StarY,
                                            "UExDocATTID": doc.uExDocATTID,
                                            "UExDocChk": doc.uExDocChk,
                                            "UExDocID": doc.uExDocID,
                                            "UExDocMark1": doc.uExDocMark1,
                                            "UExDocMark2": doc.uExDocMark2,
                                            "UExDocMark3": doc.uExDocMark3
                                        };
                                        //setUdocList(doc);
                                        const upDocList = UdocList.map((objj, iii) => {
                                            if (objj.DocNo == obj2.docNo) {

                                                return { ...objj, objj: doc };
                                            }
                                            return obj;
                                        });
                                        setUdocList(upDocList);

                                        if (doc.uExDocChk == "1" && obj2.docDisplayChild == "1" && obj2.docGNo == "C001") {
                                            setC001chk(doc.docNo);
                                            setC001chkT(doc.uExDocMark1);

                                        }
                                    }
                                });

                                if (obj2.docGNo == null) {
                                    obj2.item = [];
                                    data.result.data.docBaseList.map(obj33 => {

                                        if (obj33.docGNo == obj2.docNo) {
                                            if (obj33.docNo != "B014-2") {

                                                obj.Cnt = obj.Cnt + 1;
                                                obj2.item.push(obj33);
                                            }
                                        }
                                    });
                                    i++;
                                    obj2.no = i;
                                    obj.item.push(obj2);
                                } else {
                                    if (obj2.docNo == "B014-2" && obj.DocKType == "3") {
                                        i++;
                                        obj2.item = [];
                                        obj2.no = i;
                                        obj.item.push(obj2);
                                    }

                                }
                            }

                        });
                        if (obj.item.length > 0) {


                            setfiltered(filtered => [...filtered, obj]);
                        }
                    });

                    //const numAscending = [...employees].sort((a, b) => a.id - b.id);

                    document.getElementById("loading").style.display = "none";
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


        API_Get_Front_KindList({
            "ExType": ExKindNo.substr(0, 1)
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);

                    setKindArrayList(data.result.kind);
                    
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
      
    //編輯文件資料
    function setDoc(index, index2, docNo, GNo, objName, type = null) {
        if (UserList.UserDataLock == '1') {
            Swal.fire({
                icon: 'warning',
                text: "已下載報名表，無法變更報名表。",
            })
        } else {
            if (docNo == "B001") {

                Swal.fire({
                    icon: 'warning',
                    text: "【國民身分證】為必傳文件，無法取消勾選。",
                })

            } else if (docNo == "B002") {

                Swal.fire({
                    icon: 'warning',
                    text: "【畢業證書】為必傳文件，無法取消勾選。",
                })
            } else {
                if (docNo == GNo) {
                    filtered.map((obj, i) => {
                        obj.item.map((obj2, i2) => {
                            if (obj2.docNo == docNo) {
                                console.log('obj2');
                                if (obj2.doc.UExDocChk == '1') {
                                    console.log('not docGno');
                                    console.log(obj2);
                                    Swal.fire({
                                        text: "是否取消勾選【" + obj2.docName + "】文件?",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: '確定',
                                        cancelButtonText: '取消'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            getChkAlert(docNo, obj2.doc.UExDocID);
                                        }
                                    });
                                }
                            }
                        });
                    });
                    //切結書不另外做處理
                } else if (GNo == "C001") {
                    filtered.map((obj, i) => {
                        obj.item.map((obj2, i2) => {
                            if (obj2.docNo == GNo) {
                                console.log('docGno');
                                obj2.item.map((obj33, i33) => {
                                    if (obj33.docNo == docNo) {
                                        console.log(obj33);
                                        Swal.fire({
                                            text: "是否更改【" + obj33.docName + "】文件?",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: '確定',
                                            cancelButtonText: '取消'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                getChkAlert(docNo, obj33.doc.UExDocID, obj33.doc.UExDocChk == '1'? '0':'1');
                                            }
                                        });
                                    }
                                });
                            }

                        });

                    });
                    
                } else {
                   filtered.map((obj, i) => {
                        obj.item.map((obj2, i2) => {
                            if (obj2.docNo == GNo) {

                                console.log('docGno');
                                obj2.item.map((obj33, i33) => {
                                    if (obj33.docNo == docNo && obj33.doc.UExDocChk == '1') {
                                        console.log(obj33);

                                        Swal.fire({
                                            text: "是否取消勾選【" + obj33.docName + "】文件?",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: '確定',
                                            cancelButtonText: '取消'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                getChkAlert(docNo, obj33.doc.UExDocID);
                                            }
                                        });
                                    }
                                });
                            }

                        });

                   });
                }
            }
        }
       
    }



    //數字補0
    function getChkAlert(DocNo, UExDocID,flag = '0') {
        document.getElementById("loading").style.display = "block";
        API_PUT_RetestCheck_UExDocChkCheck({
                "UserEKID": UserData.userEKID,
                "DocNo": DocNo,
                "UExDocID": UExDocID,
                "UExDocChk": flag
            }).then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        window.location.href = "/";
                    }
                    console.log(data);
                    if (data.code == "0000") {
                        document.getElementById("loading").style.display = "none";
                        Swal.fire({
                            icon: 'success',
                            text: "儲存成功",
                        }).then((result) => {
                        window.location.reload(true);
                        });
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                    }
                    else {

                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })
                    }
                    document.getElementById("loading").style.display = "none";
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                    document.getElementById("loading").style.display = "none";
                })

    }

   
    //下一步驟
    function Next() {

        //console.log("UserList");
        //console.log(UserList);
        //console.log("----------------------");
        
        //console.log("filtered");
        //console.log(filtered);
        //console.log("----------------------");
      
        console.log(next);
        window.location.href = next;
    }


    return (
        <>
            <div id="master-main-bg">

                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">

                    <div id="main">

                        <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                        <br />

                        <table width="100%" border="0" cellPadding="5" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td width="5%">類科：</td>
                                    <td width="15%">
                                        {
                                            KindArrayList.length > 0 && ExKindNo ?
                                                KindArrayList.find(y => y.exKindNo == ExKindNo) ?
                                                    KindArrayList.find(y => y.exKindNo == ExKindNo).exKindName
                                                    : null
                                                : null
                                        }
                                    </td>
                                    <td width="5%">姓名：</td>
                                    <td width="45%">{UserList.UName}</td>
                                </tr>
                            </tbody>
                        </table>
                       
                        <table className="datatable fileTH">
                            <tbody>
                                <tr>
                                    <td width="104" className="datatable-bg2">項目</td>
                                    <td width="25" className="datatable-bg2">序</td>
                                    <td className="datatable-bg2">檢附之證明（請考生依個人實際狀況填寫(勾選)資料）</td>
                                </tr>
                            </tbody>
                        </table>

                        {filtered.map((x, i) => (

                            <table key={i} className="fileTd datatable ">
                                <tbody>
                                    <tr>
                                        <td width="104" rowSpan={x.Cnt + 10} className="datatable-bg2">{x.CodeText}</td>
                                    </tr>

                                    {x.item.map((y, i2) => (
                                        i == "0" && i2 == "0" ? (
                                            <tr key={i + i2} >
                                                <td width="25" className="datatable-bg3">{y.no}</td>
                                                <td className="datatable-bg3">
                                                    {y.docDisplayChild == null &&(
                                                        <span>
                                                            <input type="checkbox" name={`UExDocChk${y.docNo}`} checked={y.doc.UExDocChk == '1'} value={y.doc.UExDocChk == '1' ? '0' : '1'} onClick={(e) => setDoc(i, i2, y.doc.DocNo, y.doc.DocNo, "UExDocChk")} />
                                                            {y.docName}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        ): (
                                            y.docGNo == null &&(
                                                y.item.length == 0 ? (
                                                    <tr>
                                                        <td width="25" className="datatable-bg1">{y.no}</td>
                                                        <td className="datatable-bg1">
                                                            <span>
                                                                {y.doc.UExDocChk == '1' ? (
                                                                    <span>
                                                                        <input type="checkbox" name={`UExDocChk${y.docNo}`} checked={y.doc.UExDocChk == '1'} value={y.doc.UExDocChk == '1' ? '0' : '1'} onClick={(e) => setDoc(i, i2, y.doc.DocNo, y.doc.DocNo, "UExDocChk")} />
                                                                        {y.docName}
                                                                    </span>
                                                                ): (
                                                                <span>{y.docName}</span>
                                                            )}         
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    y.docNo == "C001" ? (
                                                        <tr>
                                                            <td width="25" className="datatable-bg1">{y.no}</td>
                                                            <td>
                                                                {y.docName}
                                                                <br />
                                                                {
                                                                    y.item.map((yy, y2) => (
                                                                         (
                                                                            <div>
                                                                            <span>
                                                                                    <input type="radio" name={`UExDocChkC${yy.docGNo}`} checked={yy.docNo === C001chk} value="1" onClick={(e) => setDoc(i, i2, yy.docNo, y.doc.DocNo, "UExDocChk")} />
                                                                                {yy.docName}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td width="25" className="datatable-bg1">{y.no}</td>
                                                            <td className="datatable-bg1">
                                                                <span>
                                                                    {y.docName}<br />
                                                                    {y.item.map((yy, y2) => (
                                                                        yy.doc.UExDocChk == '1' &&(
                                                                            <div>
                                                                            <span>
                                                                                <input type="checkbox" name={`UExDocChk${y.docNo}`} checked={y.doc.UExDocChk == '1'} value={y.doc.UExDocChk == '1' ? '0' : '1'} onClick={(e) => setDoc(i, i2, yy.docNo, y.doc.DocNo, "UExDocChk")} />
                                                                                {yy.docName}
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    ))}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            )   
                                        )
                                    ))}

                                </tbody>
                            </table>
                        ))}
                        <table>
                            <tbody>
                                <tr>
                                    <td><font color="#CC0066">(修改資料後，務必按「下一步」，以完成修改動作)</font></td>
                                </tr>
                            </tbody>
                        </table>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                        <div align="center">
                            <input type="button" value="下一步" name="sub" onClick={(e) => Next()} style={{ width: "80px", margin: "0.4rem 1rem" }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecondForm2;
