import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_Front_KindList,
    API_Put_Front_ModifyKind,
    API_Put_Front_ModifyExKind,
    API_Get_Modify_Modifyget,
    API_Put_Modify_Modifyput
} from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import LeftSideW from '../components/partial/leftSide';
import Jdata from './docMark.json';

import TWzipcode from 'react-twzipcode';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';

function Modifyform() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const date = new Date();
    const birYStar = date.getFullYear() - 1911 - 65; //生日年起迄
    const [rocDate, setRocDate] = useState("");

    const [KindArrayList, setKindArrayList] = useState([{}]); //報考類科下拉
    const [ExKindNo, setExKindNo] = useState(UserData.exKindNo); //報考類科

    const [resumeList, setResumeList] = useState([]); //經歷清單
    const [UdocList, setUdocList] = useState([]); //使用者上傳文件清單
    const [UserList, setUserList] = useState({}); //使用者資料清單

    const [photo, setPhoto] = useState("../images/photo-sample.gif");
    const [birthY, setbirthY] = useState(""); //生日-年
    const [birthM, setbirthM] = useState(""); //生日-月
    const [birthD, setbirthD] = useState(""); //生日-日

    const [CityName, setCityName] = useState(""); //住址-城市
    const [AreaName, setAreaName] = useState(""); //住址-地區
    const [AddrZIP, setAddrZIP] = useState(""); //住址-郵遞區號

    const [Add, setAdd] = useState({ "county": "基隆市", "district": "仁愛區", "zipcode": "200" }); //住址-郵遞區號

    const [filtered, setfiltered] = useState([]); //文件列表
    const [filteredd, setfilteredd] = useState([]); //文件列表


    const [Jobchk, setJobchk] = useState("0"); //現職 勾選
    const [C001chk, setC001chk] = useState(""); //c001 文件 勾選
    const [C001chkT, setC001chkT] = useState(""); //c001 文件 原因

    const [resumeSave, setResumeSave] = useState([]); //經歷清單
    const [next, setnext] = useState(""); //下一步
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [Complete, setComplete] = useState(""); //是否為填寫完成
    const areatId = [];//跳轉ID

    useEffect(() => {
        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        console.log(obj3);
                        setnext(obj3.NextUrl);
                    }
                });
            });
        });

        getFrontSet();
    }, []);


    function putExKindNo(e) {
        API_Put_Front_ModifyKind({
            "UserID": UserData.userID,
            "UserEKID": UserData.userEKID,
            "ExKindNo": e

        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/"
                }
                if (data.code == "0000") {
                    console.log(data);
                    UserData.exKindNo = e;
                    setExKindNo(e);
                    //setExKindNo(prevState => [...prevState, UserData.exKindNo]);
                    console.log(ExKindNo);
                    //getData();
                    window.location.href = "../signup/modify_form";
                } else if (data.code == "3023") {
                    Swal.fire({
                        text: data.result,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: '確定',
                        cancelButtonText: '取消'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            chageExKindNo(e)
                        }
                    })

                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })

                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //更換跨類組
    function chageExKindNo(e) {
        API_Put_Front_ModifyExKind({
            "UserID": UserData.userID,
            "UserEKID": UserData.userEKID,
            "ExKindNo": e

        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/"
                }
                if (data.code == "0000") {
                    console.log(data);
                    UserData.exKindNo = e;
                    setExKindNo(e);
                    //setExKindNo(prevState => [...prevState, UserData.exKindNo]);
                    console.log(ExKindNo);
                    //getData();
                    window.location.href = "../signup/modify_form";

                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })

                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //取得是否報名表填寫完成
    function getData() {

        API_Get_Modify_Modifyget({
            "UserEKID": UserData.userEKID
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/"
                }
                if (data.code == "0000") {
                    console.log(data);
                    setExKindNo(data.result.user[0].ExKindNo);
                    setUdocList(data.result.docList);
                    setUserList(data.result.user[0]);
                    setComplete(data.result.user[0].UComplete);
                    var i = 0;
                    var temp = [];
                    var aa = [];
                    var bb = [];
                    //filtered.length = 0;
                    data.result.docType.map(obj => { //文件
                        obj.item = [];
                        data.result.docBaseList.map(obj2 => {
                            if (obj.DocKType == obj2.docKType) {
                                obj2.doc =
                                {
                                    "CodeText": obj.CodeText,
                                    "DocFileCnt": "1",
                                    "DocKMode": obj2.docKMode,
                                    "DocKType": obj2.docKType,
                                    "DocKUPMode": null,
                                    "DocMark1": obj2.docMark1,
                                    "DocMark2": obj2.docMark2,
                                    "DocMark3": obj2.docMark3,
                                    "DocName": obj2.docName,
                                    "DocNo": obj2.docNo,
                                    "DocRefFile": obj2.docRefFile,
                                    "EndM": "",
                                    "EndY": "",
                                    "StarM": "",
                                    "StarY": "",
                                    "UExDocATTID": null,
                                    "UExDocChk": (obj2.docNo == "B001" ? "1" : "0"),
                                    "UExDocMark1": null,
                                    "UExDocMark2": null,
                                    "UExDocMark3": null
                                };
                                if (obj2.dM1 != null) {

                                    obj2.dT1List.map((obj4, i4) => {
                                        obj4.attr = [];
                                        obj2.doc[obj4.docMark + i4] = "";
                                        //bb.uDocMark = "";
                                        //obj2.doc.UdT1List.push(bb);
                                        data.result.codeBase.map(obj3 => {

                                            if (obj4.docMark == obj3.codeField) {
                                                aa.name = obj3.codeField;
                                                aa.Cname = obj3.codeText;
                                                aa.value = obj3.codeValue;
                                                aa.chked = "0";

                                                obj4.attr.push(aa);
                                                aa = [];
                                            }


                                        });

                                    });

                                }

                                if (obj2.dM3 != null) {

                                    obj2.dT3List.map((obj4, i4) => {
                                        obj4.value = "";
                                    });

                                }

                                data.result.docList.map(doc => {
                                    doc.StarY = "";
                                    doc.StarM = "";
                                    doc.EndY = "";
                                    doc.EndM = "";

                                    if (doc.docNo == obj2.docNo) {
                                        if (doc.uExDocMark1 && obj2.dM1 == "R") {
                                            doc.udT1List.map(docobj => {
                                                obj2.dT1List.map(dTobj => {
                                                    dTobj.attr.map(attobj => {
                                                        if (attobj.Cname == docobj.uDocMark) {
                                                            attobj.chked = "1";
                                                        }

                                                    })
                                                })
                                            })
                                        }

                                        if (doc.uExDocMark2 && obj2.dM2 == "CYM") {
                                            doc.StarY = (doc.uExDocMark2.slice(0, 4)) - 1911;
                                            doc.StarM = parseInt(doc.uExDocMark2.slice(5, 7), 10);
                                        }
                                        if (doc.uExDocMark3 && obj2.dM3 == "CYM") {
                                            doc.EndY = (doc.uExDocMark3.slice(0, 4)) - 1911;
                                            doc.EndM = parseInt(doc.uExDocMark3.slice(5, 7), 10);
                                        }
                                        if (doc.uExDocMark3 && obj2.dM3 == "T") {
                                            doc.udT3List.map((docobj, docinx) => {
                                                obj2.dT3List.map((dTobj, dtinx) => {

                                                    if (docobj.uDocMark != "" && docinx == dtinx) {
                                                        dTobj.value = docobj.uDocMark;
                                                    }
                                                })
                                            })
                                        }


                                        /* obj2.doc = doc;*/
                                        obj2.doc =
                                        {
                                            "CodeText": doc.codeText,
                                            "DocFileCnt": doc.cocFileCnt,
                                            "DocKMode": doc.docKMode,
                                            "DocKType": doc.docKType,
                                            "DocKUPMode": doc.docKUPMode,
                                            "DocMark1": doc.docMark1,
                                            "DocMark2": doc.docMark2,
                                            "DocMark3": doc.docMark3,
                                            "DocName": doc.docName,
                                            "DocNo": doc.docNo,
                                            "DocRefFile": doc.docRefFile,
                                            "EndM": doc.EndM,
                                            "EndY": doc.EndY,
                                            "StarM": doc.StarM,
                                            "StarY": doc.StarY,
                                            "UExDocATTID": doc.uExDocATTID,
                                            "UExDocChk": doc.uExDocChk,
                                            "UExDocMark1": doc.uExDocMark1,
                                            "UExDocMark2": doc.uExDocMark2,
                                            "UExDocMark3": doc.uExDocMark3
                                        };
                                        //setUdocList(doc);
                                        const upDocList = UdocList.map((objj, iii) => {
                                            if (objj.DocNo == obj2.docNo) {

                                                return { ...objj, objj: doc };
                                            }
                                            return obj;
                                        });
                                        setUdocList(upDocList);

                                        if (doc.uExDocChk == "1" && obj2.docDisplayChild == "1" && obj2.docGNo == "C001") {
                                            setC001chk(doc.docNo);
                                            setC001chkT(doc.uExDocMark1);

                                        }
                                    }
                                });

                                if (obj2.docGNo == null) {
                                    obj2.item = [];
                                    data.result.docBaseList.map(obj33 => {

                                        if (obj33.docGNo == obj2.docNo) {
                                            if (obj33.docNo != "B014-2") {

                                                obj.Cnt = obj.Cnt + 1;
                                                obj2.item.push(obj33);
                                            }
                                        }
                                    });
                                    i++;
                                    obj2.no = i;
                                    obj.item.push(obj2);
                                } else {
                                    if (obj2.docNo == "B014-2" && obj.DocKType == "3") {
                                        i++;
                                        obj2.item = [];
                                        obj2.no = i;
                                        obj.item.push(obj2);
                                    }

                                }
                            }

                        });
                        if (obj.item.length > 0) {


                            setfiltered(filtered => [...filtered, obj]);
                        }
                    });

                    //const numAscending = [...employees].sort((a, b) => a.id - b.id);

                    var len = 3 - parseInt(data.result.resumeList.length, 10); //經歷
                    if (data.result.resumeList.length != 0) {
                        data.result.resumeList.map((item, i) => {
                            if (item.UserRStartDate != null) {
                                item.resumeYs = item.UserRStartDate.slice(0, 4) - 1911;
                                item.resumeMs = parseInt(item.UserRStartDate.slice(5, 7), 10);
                                item.resumeDs = parseInt(item.UserRStartDate.slice(8, 10), 10);
                            }
                            if (item.UserREndDate != null) {
                                item.resumeYe = item.UserREndDate.slice(0, 4) - 1911;
                                item.resumeMe = parseInt(item.UserREndDate.slice(5, 7), 10);
                                item.resumeDe = parseInt(item.UserREndDate.slice(8, 10), 10);
                            }
                        })
                        setResumeList(data.result.resumeList);
                    }

                    for (let i = 1; i <= len; i++) {
                        setResumeList(resumeList => [...resumeList, {
                            "UserRID": null,
                            "UserEKID": null,
                            "UserID": null,
                            "UserROrgName": null,
                            "UserRJobName": null,
                            "UserRStartDate": null,
                            "UserREndDate": null,
                            "UserROrd": i,
                            "CreateDate": null,
                            "CreateIP": null,
                            "resumeYs": "",
                            "resumeMs": "",
                            "resumeDs": "",
                            "resumeYe": "",
                            "resumeMe": "",
                            "resumeDe": ""

                        }]);
                    }


                    if (data.result.user[0].UBirDay != null) { //生日
                        console.log(data.result.user[0].UBirDay.slice(5, 7));
                        setbirthY(parseInt((data.result.user[0].UBirDay.slice(0, 4)) - 1911));
                        setbirthM(parseInt(data.result.user[0].UBirDay.slice(5, 7)));
                        setbirthD(parseInt(data.result.user[0].UBirDay.slice(8, 10)));
                    }

                    if (data.result.user[0].UPJobSch != null && data.result.user[0].UPJobSch != "") {  //現職 setJobchk(e);
                        setJobchk("1");
                    }

                    if (data.result.photo.length > 0) { //證件照
                        if (data.result.photo[0].file.length > 0) { //證件照
                            setPhoto(data.result.photo[0].file[0].base64String);
                        }
                    }

                    if (data.result.user[0].UCityName != "") {
                        setCityName(data.result.user[0].UCityName);
                    } else {
                        setCityName("基隆市");
                    }
                    if (data.result.user[0].UCityName != "") {
                        setAreaName(data.result.user[0].UAreaName);
                    } else {
                        setAreaName("仁愛區");
                    }
                    if (data.result.user[0].UCityName != "") {
                        setAddrZIP(data.result.user[0].UAddrZIP);
                    } else {
                        setAddrZIP("200");
                    }
                    if (data.result.user[0].UCityName == null && data.result.user[0].UAreaName == null && data.result.user[0].UAddrZIP == null) {
                        set("Address", Add);
                    }
                    document.getElementById("loading").style.display = "none";
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


        API_Get_Front_KindList({
            "ExType": ExKindNo.substr(0, 1)
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);

                    setKindArrayList(data.result.kind);
                    //setExKindNo(UserData.exKindNo);

                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    //setKindArrayList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/";
                }
                if (data.code == "0000") {

                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })

                    getData();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }



            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //radio btn
    function chk(radioName, tagName) {

        var chkList = document.querySelectorAll("input[name='" + tagName + "']");
        chkList.forEach((element) => {

            if (element.checked == true) {
                set(radioName, element.value);

            }
        });
    }

    //生日
    function chk_birth(birth, birthName) {
        if (birth != "") {
            if (birthName == "birthY") {
                setbirthY(birth);
            } else if (birthName == "birthM") {
                setbirthM(birth);
            } else if (birthName == "birthD") {
                setbirthD(birth);
            }
            if (birthName == "birthY" && birthM != "" && birthD != "") {
                var birDay = (parseInt(birth, 10) + 1911) + "-" + autoSupplement(parseInt(birthM)) + "-" + autoSupplement(parseInt(birthD))
                set("UBirDay", birDay);
            } else if (birthName == "birthM" && birthY != "" && birthD != "") {
                var birDay = (parseInt(birthY, 10) + 1911) + "-" + autoSupplement(parseInt(birth)) + "-" + autoSupplement(parseInt(birthD))
                set("UBirDay", birDay);
            } else if (birthName == "birthD" && birthY != "" && birthM != "") {
                var birDay = (parseInt(birthY, 10) + 1911) + "-" + autoSupplement(parseInt(birthM)) + "-" + autoSupplement(parseInt(birth))
                console.log(birDay);
                set("UBirDay", birDay);
            }
        }
    }

    //現職
    function chk_show2(e) {
        setJobchk(e);

        if (e == "1") {
            document.querySelector("#teach_span").classList.remove("hide");
        } else {
            document.querySelector("#teach_span").classList.add("hide");
            set("UPJobSch", "");
        }
    }

    //地址
    function handleChange(data) {
        console.log(data);
        console.log(CityName);
        if (CityName != "" && AreaName != "" && AddrZIP != "") {
            set("Address", data);
        }
    }


    //編輯基本資料
    function set(objName, e) {

        if (objName == "UAddTC" && e == "0") {
            var updateUserList = {
                ...UserList,
                UAddTC: 0,
                UAddTCMode: "0",
                UAddTCType: "0",
                UAddTCFunds: "0"
            };

        } else if (objName == "UAddTC" && e == "1") {
            var updateUserList = {
                ...UserList,
                UAddTC: 1,
                UAddTCMode: null,
                UAddTCType: null,
                UAddTCFunds: null
            };

        } else if (objName == "Address") {
            var updateUserList = {
                ...UserList,
                UCityName: e.county,
                UAreaName: e.district,
                UAddrZIP: e.zipcode
            };

        } else if (objName == "UDataUse") {
            var updateUserList = {
                ...UserList,
                UDataUse: (e == "1" ? 1 : 0),
            };

        } else {
            var updateUserList = { ...UserList, [objName]: e };
        }
        setUserList(updateUserList);
        //console.log(updateUserList);
    }

    //編輯經歷資料
    function setResume(index, e, objName) {

        const upList = resumeList.map((obj, i) => {
            if (i == index) {

                return { ...obj, [objName]: e };
            }
            return obj;
        });
        setResumeList(upList);
    }

    //編輯文件資料
    function setDoc(index, index2, docNo, e, objName, type = null) {

        if (type == null && docNo != "B002") {

            const upDocList = filtered.map((obj, i) => {
                obj.item.map((obj2, i2) => {
                    if (obj2.docNo == "C001") { //切結書
                        obj2.item.map((obj33, i33) => {
                            if (obj33.docNo == docNo) {
                                obj33.doc[objName] = e;
                                if (objName == "UExDocChk" && e == "0") {
                                    obj33.doc.UExDocMark1 = null;
                                    obj33.doc.UExDocMark2 = null;
                                    obj33.doc.UExDocMark3 = null;
                                    obj33.doc.EndM = null;
                                    obj33.doc.EndY = null;
                                    obj33.doc.StarM = null;
                                    obj33.doc.StarY = null;
                                }
                            }
                            return { ...obj2.item, obj33 };

                        });
                    } else { //其它文件
                        if (obj2.docNo == docNo) {
                            console.log("ccc");
                            obj2.doc[objName] = e;
                            if (objName == "UExDocChk" && e == "0") {
                                obj2.doc.UExDocMark1 = null;
                                obj2.doc.UExDocMark2 = null;
                                obj2.doc.UExDocMark3 = null;
                                obj2.doc.EndM = null;
                                obj2.doc.EndY = null;
                                obj2.doc.StarM = null;
                                obj2.doc.StarY = null;

                            }
                        }

                    }

                    return { ...obj.item, obj2 };
                });
                return obj;
            });
            setfiltered(upDocList);
        }
        //文件 畢業證書
        if (docNo == "B002") {

            console.log(index, index2, docNo, e, objName, type);
            const upDocList = filtered.map((obj, i) => {
                obj.item.map((obj2, i2) => {
                    if (obj2.docNo == "B002") {
                        if (type == null) { //勾選
                            obj2.doc[objName] = e;
                            if (e == "0") {
                                obj2.doc.UExDocMark1 = null;
                                obj2.doc.UExDocMark2 = null;
                                obj2.doc.UExDocMark3 = null;


                                obj2.dT1List.map((obj3, i3) => {
                                    obj3.attr.map((obj4, i4) => {
                                        obj4.chked = "0";

                                        return { ...obj3.attr, obj4 };
                                    });
                                    return { ...obj2.dT1List, obj3 };
                                });

                                obj2.dT3List.map((obj5, i5) => {
                                    obj5.value = "";
                                    return { ...obj2.dT1List, obj5 };
                                });
                            }
                        } else {
                            if (type == "R") {
                                obj2.dT1List.map((obj3, i3) => {
                                    if (obj3.docMark == objName) {
                                        obj2.doc[objName] = e;
                                        obj3.attr.map((obj4, i4) => {
                                            obj4.chked = "0"
                                            if (obj4.value == e && obj2.doc.UExDocChk == "1") {
                                                obj4.chked = "1"
                                            }
                                        });
                                        return { ...obj2.dT1List, };
                                    }
                                });
                            }
                            if (type == "T") {
                                obj2.dT3List.map((obj5, i5) => {
                                    if ((obj2.docNo + i5) == objName) {
                                        if (obj2.doc.UExDocChk == "1") {
                                            obj5.value = e;
                                        } else {
                                            obj5.value = "";
                                        }

                                        return { ...obj2.dT3List, };
                                    }
                                });
                            }
                        }

                    }
                    return { ...obj.item, obj2 };
                });
                return obj;
            });
            setfiltered(upDocList);
        }


        //設定 切結書 -C式 若沒有選此項 清空此文字輸入框
        //console.log(index, index2, docNo, e, objName, type);
        if (type == "radio") {
            if (docNo != "C001-3") {
                filtered[1].item[0].item[2].doc.UExDocMark1 = null;
            }
            if (docNo == C001chk) {
                setC001chk("");
                setC001chkT("");
            } else {
                setC001chk(docNo);
                setC001chkT("");
            }

        }
    }

    //檢查資料
    function chkData() {
        var result = true;
        const requiredlist = document.querySelectorAll(".required");
        requiredlist.forEach((element) => {

            if (element.type == "text" && element.name != "Teach_sch" && element.name != "phone") { //其它文字輸入框
                if (element.value == "") {
                    element.classList.add("error");
                    areatId.push(element.id);
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            } else if (element.type == "text" && element.name == "Teach_sch" && Jobchk == "1") { //現職有勾選 文字輸入框為必填
                if (element.value == "") {
                    element.classList.add("error");;
                    areatId.push(element.id);
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            } else if (element.type == "text" && element.name == "phone") { //手機

                if (element.value == "") {
                    element.classList.add("error");
                    areatId.push(element.id);
                    result = false;
                } else {

                    element.classList.remove("error");

                    //手機格式
                    var rules = /^09\d{2}-\d{6}$/;

                    if (!rules.test(element.value)) {
                        document.querySelector("[id='" + element.name + "_" + element.id + "']").classList.remove("hide");
                        result = false;
                        areatId.push(element.name + "_" + element.id);
                    } else {
                        document.querySelector("[id='" + element.name + "_" + element.id + "']").classList.add("hide");
                    }
                }

            } else if (element.type == "radio") { //單選框

                const radiolist = document.querySelectorAll("[name='" + element.name + "']");
                var chk = "";
                radiolist.forEach((element2) => {

                    if (element2.checked != "") {
                        chk = element2.checked;
                    }
                });
                if (chk == "") {
                    document.querySelector("[id='" + element.name + "']").classList.remove("hide");
                    result = false;
                    areatId.push(element.name);
                } else {
                    document.querySelector("[id='" + element.name + "']").classList.add("hide");
                }
            } else if (element.name != "Teach_sch") { //下拉選單

                const selectlist = document.querySelectorAll("[name='" + element.name + "']");
                var chk = true;
                selectlist.forEach((element2) => {

                    if (element2.value == "") {
                        chk = false;
                    }
                });
                if (!chk) {
                    document.querySelector("[id='" + element.name + "']").classList.remove("hide");
                    result = false;
                    areatId.push(element.name);
                } else {
                    document.querySelector("[id='" + element.name + "']").classList.add("hide");
                }
            }
        });

        //文件檢查
        filtered.forEach((element3) => {
            element3.item.forEach((element4) => {
                if (element4.docNo == "C001") { //切結書
                    element4.item.forEach((element44) => {

                        if (C001chk == "C001-3" && element44.doc.DocNo == "C001-3") {
                            console.log(element44);
                            if (element44.doc.DocMark1 != null && element44.doc.UExDocMark1 == null) {
                                document.querySelector("[id='C001-3']").classList.remove("hide");
                                result = false;
                                areatId.push('C001-3');
                            } else {
                                document.querySelector("[id='C001-3']").classList.add("hide");
                            }
                        }
                    });
                } else { //其它文件
                    if (element4.doc.UExDocChk == "1" && element4.docNo != "B016") {
                        var chk1 = true;
                        var chk2 = true;
                        var chk3 = true;
                        if (element4.doc.DocMark1 != null) {
                            if (element4.dM1 == "CYM") {
                                if (element4.doc.StarY == null || element4.doc.StarY == "") {
                                    chk1 = false;
                                }
                                if (element4.doc.StarM == null || element4.doc.StarM == "") {
                                    chk1 = false;
                                }
                                if (element4.doc.EndY == null || element4.doc.EndY == "") {
                                    chk1 = false;
                                }
                                if (element4.doc.EndM == null || element4.doc.EndM == "") {
                                    chk1 = false;
                                }
                            } else if (element4.dM1 == "N") {
                                if (element4.doc.UExDocMark1 == null || element4.doc.UExDocMark1 == "" || element4.doc.UExDocMark1 == "0") {
                                    chk1 = false;
                                }
                                if (element4.doc.UExDocMark1 <= 0) {
                                    chk1 = false;
                                    document.querySelector("[id='" + element4.doc.DocNo + element4.dM1 + "']").classList.remove("hide");
                                } else {
                                    document.querySelector("[id='" + element4.doc.DocNo + element4.dM1 + "']").classList.add("hide");
                                }
                            } else if (element4.dM1 == "R") {
                                var chk_R = [];
                                element4.dT1List.forEach((element5, i5) => {
                                    chk_R[i5] = "";
                                    element5.attr.forEach((element6) => {
                                        if (element6.chked == "1") {
                                            chk_R[i5] = "1";
                                        }
                                    });

                                });
                                const found = chk_R.find(element => element == "");
                                if (found == "") {
                                    document.querySelector("[id='" + element4.doc.DocNo + "']").classList.remove("hide");
                                    chk1 = false;
                                } else {
                                    //console.log(element4);
                                    document.querySelector("[id='" + element4.doc.DocNo + "']").classList.add("hide");
                                }
                                console.log(chk_R);
                                console.log(found);
                            } else {
                                if (element4.doc.UExDocMark1 == null) {
                                    chk1 = false;
                                }
                            }
                        }

                        if (element4.doc.DocMark2 != null) {
                            if (element4.dM2 == "CYM") {
                                if (element4.doc.StarY == null || element4.doc.StarY == "") {
                                    chk2 = false;
                                }
                                if (element4.doc.StarM == null || element4.doc.StarM == "") {
                                    chk2 = false;
                                }
                                if (element4.doc.EndY == null || element4.doc.EndY == "") {
                                    chk2 = false;
                                }
                                if (element4.doc.EndM == null || element4.doc.EndM == "") {
                                    chk2 = false;
                                }
                                if (element4.dM1 == "CYM" && element4.dM2 == "CYM" && element4.doc.StarY != null && element4.doc.EndY != null) {
                                    if (element4.doc.StarY > element4.doc.EndY) {
                                        chk2 = false;
                                        document.querySelector("[id='" + element4.doc.DocNo + element4.dM2 + "']").classList.remove("hide");
                                    } else {
                                        document.querySelector("[id='" + element4.doc.DocNo + element4.dM2 + "']").classList.add("hide");
                                    }
                                }
                            } else if (element4.dM2 == "N") {
                                if (element4.doc.UExDocMark2 == null || element4.doc.UExDocMark2 == "" || element4.doc.UExDocMark2 == "0") {
                                    chk2 = false;
                                }
                                if (element4.doc.UExDocMark2 <= 0) {
                                    chk2 = false;
                                    document.querySelector("[id='" + element4.doc.DocNo + element4.dM2 + "']").classList.remove("hide");
                                } else {
                                    document.querySelector("[id='" + element4.doc.DocNo + element4.dM2 + "']").classList.add("hide");
                                }
                            } else if (element4.dM2 == "R") {
                                var chk_R = [];
                                element4.dT1List.forEach((element5, i5) => {
                                    chk_R[i5] = "";
                                    element5.attr.forEach((element6) => {
                                        if (element6.chked == "1") {
                                            chk_R[i5] = "1";
                                        }
                                    });

                                });
                                const found = chk_R.find(element => element == "");
                                if (found == "") {
                                    document.querySelector("[id='" + element4.doc.DocNo + "']").classList.remove("hide");
                                    chk2 = false;
                                } else {
                                    document.querySelector("[id='" + element4.doc.DocNo + "']").classList.add("hide");
                                }
                                console.log(chk_R);
                                console.log(found);
                            } else {
                                if (element4.doc.UExDocMark2 == null) {
                                    chk2 = false;
                                }
                            }
                        }

                        if (element4.doc.DocMark3 != null) {
                            if (element4.dM3 == "CYM") {
                                if (element4.doc.StarY == null || element4.doc.StarY == "") {
                                    chk3 = false;
                                }
                                if (element4.doc.StarM == null || element4.doc.StarM == "") {
                                    chk3 = false;
                                }
                                if (element4.doc.EndY == null || element4.doc.EndY == "") {
                                    chk3 = false;
                                }
                                if (element4.doc.EndM == null || element4.doc.EndM == "") {
                                    chk3 = false;
                                }
                                if (element4.dM2 == "CYM" && element4.dM3 == "CYM" && element4.doc.StarY != null && element4.doc.EndY != null) {
                                    //if (element4.doc.StarY > element4.doc.EndY) {
                                    //var s = new Date((parseInt(element4.doc.StarY, 10) + 1911) + '-' + parseInt(element4.doc.EndY, 10) +'-'+)
                                    if (parseInt(element4.doc.StarY, 10) > parseInt(element4.doc.EndY, 10)) {

                                        chk3 = false;
                                        document.querySelector("[id='" + element4.doc.DocNo + element4.dM3 + "']").classList.remove("hide");
                                    } else {
                                        document.querySelector("[id='" + element4.doc.DocNo + element4.dM3 + "']").classList.add("hide");
                                    }
                                }
                            } else if (element4.dM3 == "N") {
                                if (element4.doc.UExDocMark3 == null || element4.doc.UExDocMark3 == "") {
                                    chk3 = false;
                                }
                                if (element4.doc.UExDocMark3 <= 0) {
                                    chk3 = false;
                                    document.querySelector("[id='" + element4.doc.DocNo + element4.dM3 + "']").classList.remove("hide");
                                } else {
                                    document.querySelector("[id='" + element4.doc.DocNo + element4.dM3 + "']").classList.add("hide");
                                }
                            } else if (element4.dM3 == "R") {
                                var chk_R = [];
                                element4.dT1List.forEach((element5, i5) => {
                                    chk_R[i5] = "";
                                    element5.attr.forEach((element6) => {
                                        if (element6.chked == "1") {
                                            chk_R[i5] = "1";
                                        }
                                    });

                                });
                                const found = chk_R.find(element => element == "");
                                if (found == "") {
                                    document.querySelector("[id='" + element4.doc.DocNo + "']").classList.remove("hide");
                                    chk3 = false;
                                } else {
                                    document.querySelector("[id='" + element4.doc.DocNo + "']").classList.add("hide");
                                }
                                console.log(chk_R);
                                console.log(found);
                            } else if (element4.dM3 == "T") {

                                element4.dT3List.forEach((element5, i5) => {
                                    if (element5.value == "") {
                                        chk3 = false;
                                    }
                                });
                            } else {

                                if (element4.doc.UExDocMark3 == null) {
                                    chk3 = false;
                                }
                            }
                        }

                        if (element4.doc.DocMark1 != null || element4.doc.DocMark2 != null || element4.doc.DocMark3 != null) {
                            if (chk1 && chk2 && chk3) {
                                console.log(element4);
                                document.querySelector("[id='" + element4.doc.DocNo + "']").classList.add("hide");
                            } else {
                                document.querySelector("[id='" + element4.doc.DocNo + "']").classList.remove("hide");
                                result = false;
                                areatId.push(element4.doc.DocNo);
                            }
                        }

                    }

                }

            });
        });

        return result;
    }

    //數字補0
    function autoSupplement(val) {
        var n = String(val);
        return n.padStart(2, '0')

    }

    function saveDataAlert() {
        if (Complete == "1") {
            Next();
        } else {
            Swal.fire({
                icon: 'warning',
                text: "選擇報考類組為「一般類組」科目，報名表填寫完成後，無法再修改為「雙語類組」科目，確認無誤。",
            }).then((result) => {

                Next();
            })
        }

    }
    //下一步驟
    function Next() {

        areatId.length = 0;

        var chk = chkData();
        var resumeSave = [];
        var docSave = [];

        resumeList.forEach((obj, i) => {
            var item = {
                "UserEKID": UserData.userEKID,
                "UserROrgName": resumeList[i].UserROrgName,
                "UserRJobName": resumeList[i].UserRJobName,
                "UserRStartDate": (resumeList[i].resumeYs != null && resumeList[i].resumeYs != "" ? (parseInt(resumeList[i].resumeYs, 10) + 1911) + "-" + autoSupplement(parseInt(resumeList[i].resumeMs)) + "-" + autoSupplement(parseInt(resumeList[i].resumeDs)) : null),
                "UserREndDate": (resumeList[i].resumeYe != null && resumeList[i].resumeYe != "" ? (parseInt(resumeList[i].resumeYe, 10) + 1911) + "-" + autoSupplement(parseInt(resumeList[i].resumeMe)) + "-" + autoSupplement(parseInt(resumeList[i].resumeDe)) : null),
            };
            resumeSave.push(item);
        });

        filtered.forEach((obj1, i) => {
            obj1.item.forEach((obj2, i2) => {
                var item = {
                    "UserID": UserList.UserID,
                    "UExDocChk": obj2.doc.UExDocChk,
                    "DocNo": obj2.doc.DocNo,
                    "UserEKID": UserData.userEKID,
                    "UExDocMark1": obj2.doc.UExDocMark1,
                    "UExDocMark2": obj2.doc.UExDocMark2,
                    "UExDocMark3": obj2.doc.UExDocMark3,
                };

                if (obj2.dM1 == "R") {
                    var mark1 = "";
                    obj2.dT1List.forEach((obj3, i3) => {
                        obj3.attr.forEach((obj4, i4) => {
                            if (obj4.chked == "1") {
                                mark1 += obj4.Cname + ",";
                            }
                        })
                    });
                    item.UExDocMark1 = mark1.substring(0, mark1.length - 1);
                } else if (obj2.dM1 == "CYM" && obj2.doc.StarY != null && obj2.doc.StarM != null) {
                    item.UExDocMark1 = (parseInt(obj2.doc.StarY, 10) + 1911) + "-" + autoSupplement(parseInt(obj2.doc.StarM)) + "-" + autoSupplement(parseInt("01"));
                } else {
                    item.UExDocMark1 = obj2.doc.UExDocMark1;
                }

                if (obj2.dM2 == "R") {
                    var mark2 = "";
                    obj2.dT1List.forEach((obj3, i3) => {
                        obj3.attr.forEach((obj4, i4) => {
                            if (obj4.chked == "1") {
                                mark2 += obj4.Cname + ",";
                            }
                        })
                    });
                    item.UExDocMark2 = mark2.substring(0, mark2.length - 1);
                } else if (obj2.dM2 == "CYM" && obj2.doc.StarY != null && obj2.doc.StarM != null) {
                    item.UExDocMark2 = (parseInt(obj2.doc.StarY, 10) + 1911) + "-" + autoSupplement(parseInt(obj2.doc.StarM)) + "-" + autoSupplement(parseInt("01"));
                } else {
                    item.UExDocMark2 = obj2.doc.UExDocMark2;
                }

                if (obj2.dM3 == "R") {
                    var mark3 = "";
                    obj2.dT1List.forEach((obj3, i3) => {
                        obj3.attr.forEach((obj4, i4) => {
                            if (obj4.chked == "1") {
                                mark3 += obj4.Cname + ",";
                            }
                        })
                    });
                    item.UExDocMark3 = mark3.substring(0, mark3.length - 1);
                } else if (obj2.dM3 == "CYM" && obj2.doc.StarY != null && obj2.doc.StarM != null) {
                    item.UExDocMark3 = (parseInt(obj2.doc.EndY, 10) + 1911) + "-" + autoSupplement(parseInt(obj2.doc.EndM)) + "-" + autoSupplement(parseInt("01"));
                } else if (obj2.dM3 == "T") {
                    var mark3 = "";
                    obj2.dT3List.forEach((obj3, i3) => {
                        mark3 += obj3.value + ",";
                    });
                    item.UExDocMark3 = mark3.substring(0, mark3.length - 1);
                } else {
                    item.UExDocMark3 = obj2.doc.UExDocMark3;
                }
                docSave.push(item);

                if (obj2.item.length > 0) {
                    obj2.item.forEach((obj3, i3) => {
                        if (obj2.docNo == "C001") {

                            var item = {
                                "UserID": UserList.UserID,
                                "UExDocChk": (C001chk == obj3.docNo ? "1" : "0"),
                                "DocNo": obj3.docNo,
                                "UserEKID": UserData.userEKID,
                                "UExDocMark1": null,
                                "UExDocMark2": null,
                                "UExDocMark3": null,
                            };
                        } else {
                            var item = {
                                "UserID": UserList.UserID,
                                "UExDocChk": obj3.doc.UExDocChk,
                                "DocNo": obj3.doc.DocNo,
                                "UserEKID": UserData.userEKID,
                                "UExDocMark1": obj3.doc.UExDocMark1,
                                "UExDocMark2": obj3.doc.UExDocMark2,
                                "UExDocMark3": obj3.doc.UExDocMark3,
                            };
                        }
                        if (obj3.dM1 == "R") {
                            var mark1 = "";
                            obj3.dT1List.forEach((obj33, i33) => {
                                obj33.attr.forEach((obj44, i44) => {
                                    if (obj44.chked == "1") {
                                        mark1 += obj44.Cname + ",";
                                    }
                                })
                            });
                            item.UExDocMark1 = mark1.substring(0, mark1.length - 1);
                        } else if (obj3.dM1 == "CYM" && obj3.doc.StarY != null && obj3.doc.StarM != null) {
                            item.UExDocMark1 = (parseInt(obj3.doc.StarY, 10) + 1911) + "-" + autoSupplement(parseInt(obj3.doc.StarM)) + "-" + autoSupplement(parseInt("01"));
                        } else {
                            item.UExDocMark1 = obj3.doc.UExDocMark1;
                        }

                        if (obj3.dM2 == "R") {
                            var mark2 = "";
                            obj3.dT1List.forEach((obj33, i33) => {
                                obj33.attr.forEach((obj44, i44) => {
                                    if (obj44.chked == "1") {
                                        mark2 += obj44.Cname + ",";
                                    }
                                })
                            });
                            item.UExDocMark2 = mark2.substring(0, mark2.length - 1);
                        } else if (obj3.dM2 == "CYM" && obj3.doc.StarY != null && obj3.doc.StarM != null) {
                            item.UExDocMark2 = (parseInt(obj3.doc.StarY, 10) + 1911) + "-" + autoSupplement(parseInt(obj3.doc.StarM)) + "-" + autoSupplement(parseInt("01"));
                        } else {
                            item.UExDocMark2 = obj3.doc.UExDocMark2;
                        }

                        if (obj3.dM3 == "R") {
                            var mark3 = "";
                            obj3.dT1List.forEach((obj3, i3) => {
                                obj3.attr.forEach((obj4, i4) => {
                                    if (obj4.chked == "1") {
                                        mark3 += obj4.Cname + ",";
                                    }
                                })
                            });
                            item.UExDocMark3 = mark3.substring(0, mark3.length - 1);
                            //item.UExDocMark3 = obj3.doc.B002A + "," + obj3.doc.B002B;
                        } else if (obj3.dM3 == "CYM" && obj3.doc.StarY != null && obj3.doc.StarM != null) {
                            item.UExDocMark3 = (parseInt(obj3.doc.EndY, 10) + 1911) + "-" + autoSupplement(parseInt(obj3.doc.EndM)) + "-" + autoSupplement(parseInt("01"));
                        } else if (obj3.dM3 == "T") {
                            var mark3 = "";
                            obj3.dT3List.forEach((obj33, i33) => {
                                mark3 += obj33.value + ",";
                            });
                            item.UExDocMark3 = mark3.substring(0, mark3.length - 1);
                        } else {
                            //item.UExDocMark3 = obj3.doc.B002A + "," + obj3.doc.B002B;{
                            item.UExDocMark3 = obj3.doc.UExDocMark3;
                        }
                        docSave.push(item);
                    });
                }
            });


        });

        console.log("UserList");
        console.log(UserList);
        console.log("----------------------");
        console.log("resumeSave");
        console.log(resumeSave);
        console.log("----------------------");
        console.log("filtered");
        console.log(filtered);
        console.log("----------------------");
        console.log("docSave");
        console.log(docSave);
        console.log("----------------------");
        //console.log(areatId)
        //console.log(filteredd); 

        //chk = false;
        if (chk) {
            document.getElementById("loading").style.display = "block";
            API_Put_Modify_Modifyput({
                "UserID": UserList.UserID,
                "UName": UserList.UName,
                "USex": UserList.USex,
                "UBirDay": UserList.UBirDay,
                "UAddTC": (UserList.UAddTC == "1" ? true : false),
                "UAddrZIP": UserList.UAddrZIP,
                "UCityName": UserList.UCityName,
                "UAreaName": UserList.UAreaName,
                "UAddrs": UserList.UAddrs,
                "UTelO": UserList.UTelO,
                "UTelH": UserList.UTelH,
                "UPhone": UserList.UPhone,
                "UTeaEduSch": UserList.UTeaEduSch,
                "UPJobSch": UserList.UPJobSch,
                "UAddTCMode": UserList.UAddTCMode,
                "UAddTCType": UserList.UAddTCType,
                "UAddTCFunds": UserList.UAddTCFunds,
                "UDataUse": (UserList.UDataUse == 1 ? true : false),
                "UComplete": (chk ? true : false),
                "userResumes": resumeSave,
                "userExDoc": docSave
            }).then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        window.location.href = "/";
                    }
                    console.log(data);
                    if (data.code == "0000") {
                        console.log('dddd');
                        console.log(next);
                        window.location.href = next;
                        //history.push(next);
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                    }
                    else {

                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })
                    }
                    document.getElementById("loading").style.display = "none";
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                    document.getElementById("loading").style.display = "none";
                })
        } else {
            Swal.fire({
                icon: 'error',
                text: "報名表尚未填寫完整，請重新確認",
            }).then((result) => {
                document.getElementById("loading").style.display = "block";
                setTimeout(() => {
                    console.log("Delayed for 1 second.");
                    document.querySelector("#" + areatId[0]).scrollIntoView(true);
                }, "500");

                API_Put_Modify_Modifyput({
                    "UserID": UserList.UserID,
                    "UName": UserList.UName,
                    "USex": UserList.USex,
                    "UBirDay": UserList.UBirDay,
                    "UAddTC": (UserList.UAddTC == "1" ? true : false),
                    "UAddrZIP": UserList.UAddrZIP,
                    "UCityName": UserList.UCityName,
                    "UAreaName": UserList.UAreaName,
                    "UAddrs": UserList.UAddrs,
                    "UTelO": UserList.UTelO,
                    "UTelH": UserList.UTelH,
                    "UPhone": UserList.UPhone,
                    "UTeaEduSch": UserList.UTeaEduSch,
                    "UPJobSch": UserList.UPJobSch,
                    "UAddTCMode": UserList.UAddTCMode,
                    "UAddTCType": UserList.UAddTCType,
                    "UAddTCFunds": UserList.UAddTCFunds,
                    "UDataUse": (UserList.UDataUse == 1 ? true : false),
                    "UComplete": false,
                    "userResumes": resumeSave,
                    "userExDoc": docSave
                }).then((response) => response.data)
                    .then((data) => {
                        if (!checkAuthToken(data)) {
                            window.location.href = "/";
                        }
                        console.log(data);
                        if (data.code == "0000") {
                            console.log('dddd');
                            console.log(next);
                            //history.push(next);
                        } else if (data.code == "9998") {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            }).then((result) => {
                                if (data.result && data.result.redirectUrl) {
                                    window.location.href = data.result.redirectUrl;
                                } else {
                                    window.location.href = "/";
                                }
                            });
                        }
                        else {

                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            })
                        }
                        document.getElementById("loading").style.display = "none";
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                        document.getElementById("loading").style.display = "none";
                    })
            })
        }
    }


    return (
        <>
            <div id="master-main-bg">

                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">

                    <div id="main">

                        <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                        <br />

                        <table width="100%" border="0" cellPadding="5" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td width="5%">類科：</td>
                                    <td width="50%">
                                        <select name="exam_subj" id="exam_subj" style={{ marginLeft: "-60px" }} value={ExKindNo} onChange={(e) => { putExKindNo(e.target.value) }} data-required>
                                            <option value="">請選擇</option>
                                            {KindArrayList.map((y, i) => (
                                                <option key={i} value={y.exKindNo}>{y.exKindName} </option>
                                            ))}
                                        </select>(考生僅限報考一科)
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="datatable">
                            <tbody>
                                <tr>
                                    <td width="10%">姓名<span className="color-3">＊</span></td>
                                    <td colSpan="2"><input className="required" id="UName" name="textfield" type="text" size="15" value={UserList.UName} onChange={(e) => { set("UName", e.target.value) }} /><br />(若您的姓名有特殊造字，或無法鍵入的中文字，煩請填入小寫英文「x」字母)</td>

                                    <td width="20%" colSpan="2" rowSpan="4">
                                        <div align="center">
                                            <img src={photo} width="105" height="140" /><br />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100">性別<span className="color-3">＊</span></td>
                                    <td colSpan="2">

                                        <input className="required" onClick={(e) => chk("USex", "sex")} type="radio" value="M" name="sex" checked={UserList.USex === 'M'} />
                                        男
                                        <input className="required" onClick={(e) => chk("USex", "sex")} type="radio" value="F" name="sex" checked={UserList.USex === 'F'} />
                                        女 <span id="sex" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【性別】</span></td>
                                </tr>
                                <tr>
                                    <td>身分證字號：</td>
                                    <td colSpan="2">{UserList.UIDNo}</td>
                                </tr>
                                <tr>
                                    <td>出生日期<span className="color-3">＊</span></td>
                                    <td colSpan="2">
                                        民國
                                        <select className="required" id="birthY" name="birth" value={birthY} onChange={(e) => { chk_birth(e.target.value, "birthY") }}>
                                            <option value="" selected="selected">請選擇...</option>
                                            {[...new Array(46)].map((value, i) => (
                                                <option key={i} value={i + birYStar}>{i + birYStar}</option>
                                            ))}
                                        </select>
                                        年
                                        <select className="required" id="birthM" name="birth" value={birthM} onChange={(e) => { chk_birth(e.target.value, "birthM") }}>
                                            <option value="" selected="selected">請選擇...</option>
                                            {[...new Array(12)].map((value, i) => (
                                                <option key={i} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                        月
                                        <select className="required" id="birthD" name="birth" value={birthD} onChange={(e) => { chk_birth(e.target.value, "birthD") }}>
                                            <option value="" selected="selected">請選擇...</option>
                                            {[...new Array(31)].map((value, i) => (
                                                <option key={i} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                        日<span id="birth" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【出生日期】</span><br />
                                        <p>(<span className="color-3">限制</span>：具有中華民國國籍之國民，未達「公立學校教職員退休資遣撫卹條例」規定之屆齡退休條件，無教師法第19條情事及無教育人員任用條例第31條、第33條規定情事者，始得報考。)<br />(僅限民國45年08月01日(含)以後出生，才能參加報名。)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>現職</td>
                                    <td colSpan="3">
                                        <input id="Teach_subj" onClick={(e) => chk_show2(e.target.value)} type="checkbox" value={Jobchk == "0" ? "1" : "0"} name="Teach_subj" checked={Jobchk == "1"} />
                                        是否為現職教師 (若您為現職教師，請於勾選後填寫現職服務學校(含縣市))<br />
                                        <span id="teach_span" className={Jobchk == "0" || Jobchk == "" ? "hide" : ""}> 請填入現職服務學校：<input className="required" id="Teach_sch" type="text" name="Teach_sch" value={UserList.UPJobSch} onChange={(e) => { set("UPJobSch", e.target.value) }} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>住址<span className="color-3">＊</span></td>
                                    <td colSpan="3">
                                        <TWzipcode className="required" css={['county-sel', 'district-sel', 'zipcode']} handleChangeCounty={handleChange} handleChangeDistrict={handleChange} handleChangeZipcode={handleChange}
                                            zipcodeValue={UserList.UAddrZIP} countyValue={UserList.UCityName} districtValu={UserList.UAreaName} />
                                        <input type="text" id="addr" name="addr" className="required Ariel" size="32" maxLength="100" value={UserList.UAddrs} onChange={(e) => { set("UAddrs", e.target.value) }} />
                                        門牌街道
                                    </td>
                                </tr>
                                <tr>
                                    <td>電話</td>
                                    <td colSpan="3"><span className="datatable-bg1">( O )：
                                        <input name="phone" type="text" className="Ariel" value={UserList.UTelO} id="tel1" size="20" maxLength="30" onChange={(e) => { set("UTelO", e.target.value) }} />
                                        <span className="sign">(ex: 02-22187777#分機)</span> {/*<span id="phone_tel1" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>【電話格式錯誤】</span>*/}</span><br />
                                        ( H )：
                                        <input name="phone" type="text" className="Ariel" value={UserList.UTelH} id="tel2" size="20" maxLength="30" onChange={(e) => { set("UTelH", e.target.value) }} />
                                        <span className="sign">(ex:02-22187777#分機)</span>{/*<span id="phone_tel2" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>【電話格式錯誤】</span>*/}<span> </span><br />
                                        <span className="font">手機<span className="color-3">＊</span>：</span>
                                        <input name="phone" type="text" className="Ariel required" value={UserList.UPhone} id="Smphone2" size="20" maxLength="30" onChange={(e) => { set("UPhone", e.target.value) }} />
                                        <span className="sign">(ex:0912-123456)</span><span id="phone_Smphone2" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>【手機格式錯誤】</span><span></span></td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td colSpan="3">
                                        <input name="email" type="text" id="email" value={UserList.UEmail} disabled={UserList.UEmail != null ? "disabled" : ""} size="30" maxLength="100" />建議填入E-mail，以利日後寄送資料之用
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="datatable">
                            <tbody>
                                <tr>
                                    <td>
                                        師資培育課程修畢學校<span className="color-3">＊</span>
                                    </td>
                                    <td>
                                        <p>校名：<input className="required" name="Tc_GrdSch" type="text" id="Tc_GrdSch" maxLength="40" value={UserList.UTeaEduSch} onChange={(e) => { set("UTeaEduSch", e.target.value) }} /></p>
                                        <span className="color-3">請填寫校名全銜</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>教育部增置教師<span className="color-3">＊</span></td>
                                    <td>是否為教育部增置教師：
                                        <input className="required" type="radio" name="IsAddTc" value="0" onClick={(e) => chk("UAddTC", "IsAddTc")} checked={UserList.UAddTC === 0} />否
                                        <input className="required" type="radio" name="IsAddTc" value="1" onClick={(e) => chk("UAddTC", "IsAddTc")} checked={UserList.UAddTC === 1} />是
                                        <span id="IsAddTc" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【教育部增置教師】</span>
                                        <br />
                                        <font color="red">註：「增置教師」之定義，係指依據《教育部國民及學前教育署補助直轄市縣（市）
                                            政府增置國小教師員額實施要點》及《教育部國民及學前教育署補助直轄市縣（市）政府增置偏遠及
                                            小型國民中學教師員額實施要點》，所規劃進用之人力，以共聘或方式為原則補足學校所需師資，進用
                                            人員類別分為兼任教師、代課教師、代理教師及教學支援工作人員等四類，其進用方式應符合教師法、
                                            國民教育法第十一條、中小學兼任代課及代理教師聘任辦法、國中小學教學支援工作人員聘任辦法等相關法令規定。
                                        </font>
                                        <br />
                                        教育部增置教師進用方式：<span id="IsAddTcMethod" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【教育部增置教師進用方式】</span>
                                        <br />
                                        <span className={UserList.UAddTC == "0" || UserList.UAddTC == null ? "" : "hide"} id="Iat1">
                                            <input className="required" type="radio" name="IsAddTcMethod" id="Iatm1" value="0" onChange={(e) => chk("UAddTCMode", "IsAddTcMethod")} checked={UserList.UAddTCMode === "0"} />無 <br />
                                        </span>
                                        <span className={UserList.UAddTC == "1" || UserList.UAddTC == null ? "" : "hide"} id="INat1" >
                                            <input className="required" type="radio" name="IsAddTcMethod" id="Iatm2" value="A" onChange={(e) => chk("UAddTCMode", "IsAddTcMethod")} checked={UserList.UAddTCMode === "A"} />一般 <br />
                                            <input className="required" type="radio" name="IsAddTcMethod" id="Iatm3" value="B" onChange={(e) => chk("UAddTCMode", "IsAddTcMethod")} checked={UserList.UAddTCMode === "B"} />共聘 <br />
                                        </span>

                                        <font color="red">1.若非教育部增置教師，請填入無 <br />
                                            2.若為教育部增置教師，請填入一般、共聘
                                        </font><br />
                                        教育部增置教師進用類別：<span id="IsAddTcClass" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【教育部增置教師進用類別】</span>
                                        <br />

                                        <span className={UserList.UAddTC == "0" || UserList.UAddTC == null ? "" : "hide"} id="Iat2">
                                            <input className="required" type="radio" name="IsAddTcClass" value="0" onChange={(e) => chk("UAddTCType", "IsAddTcClass")} checked={UserList.UAddTCType === "0"} />無 <br />
                                        </span>
                                        <span className={UserList.UAddTC == "1" || UserList.UAddTC == null ? "" : "hide"} id="INat2" >
                                            <input className="required" type="radio" name="IsAddTcClass" value="1" onChange={(e) => chk("UAddTCType", "IsAddTcClass")} checked={UserList.UAddTCType === "1"} />兼任教師 <br />
                                            <input className="required" type="radio" name="IsAddTcClass" value="2" onChange={(e) => chk("UAddTCType", "IsAddTcClass")} checked={UserList.UAddTCType === "2"} />代課教師 <br />
                                            <input className="required" type="radio" name="IsAddTcClass" value="3" onChange={(e) => chk("UAddTCType", "IsAddTcClass")} checked={UserList.UAddTCType === "3"} />代理教師 <br />
                                            <input className="required" type="radio" name="IsAddTcClass" value="4" onChange={(e) => chk("UAddTCType", "IsAddTcClass")} checked={UserList.UAddTCType === "4"} />教學支援工作人員 <br />
                                        </span>

                                        <font color="red">1.若非教育部增置教師，請填入無 <br />
                                            2.若為教育部增置教師，請填入兼任教師、代課教師、代理教師、教學支援工作人員 </font>
                                        <br /> 教育部增置教師經費來源依據：  <span id="IsAddTcSource" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【教育部增置教師經費來源依據】</span><br />
                                        <span className={UserList.UAddTC == "0" || UserList.UAddTC == null ? "" : "hide"} id="Iat3">
                                            <input className="required" type="radio" name="IsAddTcSource" value="0" onChange={(e) => chk("UAddTCFunds", "IsAddTcSource")} checked={UserList.UAddTCFunds == "0"} />無 <br />
                                        </span>
                                        <span className={UserList.UAddTC == "1" || UserList.UAddTC == null ? "" : "hide"} id="INat3" >
                                            <input className="required" type="radio" name="IsAddTcSource" value="1" onChange={(e) => chk("UAddTCFunds", "IsAddTcSource")} checked={UserList.UAddTCFunds == "1"} />教育部國民及學前教育署補助直轄市縣（市）政府增置國小教師員額實施要點 <br />
                                            <input className="required" type="radio" name="IsAddTcSource" value="2" onChange={(e) => chk("UAddTCFunds", "IsAddTcSource")} checked={UserList.UAddTCFunds == "2"} />教育部國民及學前教育署補助直轄市縣（市）政府增置偏遠及小型國民中學教師員額實施要點 <br />
                                        </span>

                                        <font color="red">1.若非教育部增置教師，請填入無 <br />
                                            2.若為教育部增置教師，請填入教育部國民及學前教育署補助直轄市縣（市）政府增置國小教師員額實施要點、教育部國民及學前教育署補助直轄市縣（市）政府增置偏遠及小型國民中學教師員額實施要點
                                        </font>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="datatable fileTH">
                            <tbody>
                                <tr>
                                    <td width="104" className="datatable-bg2">項目</td>
                                    <td width="25" className="datatable-bg2">序</td>
                                    <td className="datatable-bg2">檢附之證明（請考生依個人實際狀況填寫(勾選)資料）</td>
                                    <td width="70" className="datatable-bg2">初審</td>
                                    <td width="70" className="datatable-bg2">複審</td>
                                </tr>
                            </tbody>
                        </table>

                        {filtered.map((x, i) => (

                            <table key={i} className="fileTd datatable ">
                                <tbody>
                                    <tr>
                                        <td width="104" rowSpan={x.Cnt + 10} className="datatable-bg2">{x.CodeText}</td>
                                    </tr>
                                    { console.log("GOOD",x)}
                                    {x.item.map((y, i2) => (

                                        i == "0" && i2 == "0"
                                            ? (

                                                <tr key={i + i2} >
                                                    <td width="25" className="datatable-bg3">{y.no}</td>
                                                    <td className="datatable-bg3">
                                                        <input type="checkbox" name={`UExDocChk${y.docNo}`} checked={y.doc.UExDocChk == '1'} value={y.doc.UExDocChk == '1' ? '0' : '1'} onClick={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocChk")} disabled />{y.docName}
                                                        <br /><span id={y.docNo} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請填入【資料】</span>
                                                    </td>
                                                    <td width="70" rowSpan={x.Cnt}>&nbsp;</td>
                                                    <td width="70" rowSpan={x.Cnt}>&nbsp;</td>
                                                </tr>

                                            )
                                            : (
                                                y.docGNo == null || y.docGNo == "C001" || y.docGNo == "B014"
                                                    ? (
                                                        y.docGNo == null && y.docNo != "C001"
                                                            ? (
                                                                <tr>
                                                                    <td width="25" className="datatable-bg1">{y.no}</td>
                                                                    <td className="datatable-bg1"  >

                                                                        {y.docRefFile == "N" ? (
                                                                            y.docDisplayChild == null ? (
                                                                                <span>
                                                                                    <input type="checkbox" name={`UExDocChk${y.docNo}`} checked={y.doc.UExDocChk == '1'} value={y.doc.UExDocChk == '1' ? '0' : '1'} onClick={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocChk")} />
                                                                                    {y.docName}
                                                                                    {y.docMark1 != null && (
                                                                                        <span>：
                                                                                            {y.dM1 == "T" ? (
                                                                                                <span>
                                                                                                    <input type="text" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark1")} />{y.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ) : (
                                                                                                y.dM1 == "N" ? (
                                                                                                    <span><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <input type="number" min="0" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "60px" }} maxLength="1" onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark1")} />{y.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <span id={y.docNo + y.dM1} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    y.dM1 == "R" ? (
                                                                                                        y.dT1List.map((zz, i4) => (
                                                                                                            <span>
                                                                                                                <br /> <span style={{ marginLeft: "30px" }}>{i4 + 1}. </span>
                                                                                                                {zz.attr.map((zy, i5) => (
                                                                                                                    //y.doc.UdT1List.map((zyy, i6) => (
                                                                                                                    <span>
                                                                                                                        <input type="radio" value={zy.value} checked={zy.chked == "1"} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                                                    </span>
                                                                                                                    //))checked={y.doc.UdT1List[i4].uDocMark == zy.value}
                                                                                                                ))}

                                                                                                            </span>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        y.dM1 == "CYM" ? (
                                                                                                            <span>
                                                                                                                {y.dT1}
                                                                                                                <select id="" name={y.docNo + y.dM1 + y.doc.StarY} value={y.doc.UExDocChk == '1' ? y.doc.StarY1 : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(66)].map((value, j) => (
                                                                                                                        <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                年
                                                                                                                <select id="" name={y.docNo + y.dM1 + y.doc.StarM} value={y.doc.UExDocChk == '1' ? y.doc.StarM : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(12)].map((value, j) => (
                                                                                                                        <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span> </span>
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                                    {y.docMark2 != null && (
                                                                                        y.dM2 == "T" ? (



                                                                                            y.docNo == "B002" ? (
                                                                                                <span><br />
                                                                                                    <input type="text" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "150px", marginLeft: "30px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark2")} />{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                </span>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    <input type="text" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "150px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark2")} />{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                </span>
                                                                                            )

                                                                                        ) : (
                                                                                            y.dM2 == "N" ? (
                                                                                                <span>
                                                                                                    <input type="number" min="0" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "60px" }} m onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark2")} />{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    <span id={y.docNo + y.dM2} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                                                </span>
                                                                                            ) : (
                                                                                                y.dM2 == "R" ? (
                                                                                                    y.dT1List.map((zz, i4) => (
                                                                                                        <span>
                                                                                                            {zz.attr.map((zy, i5) => (
                                                                                                                <span>
                                                                                                                    <input type="radio" value={zy.value} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                                                </span>
                                                                                                            ))}
                                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ))

                                                                                                ) : (
                                                                                                    y.dM2 == "CYM" ? (
                                                                                                        <span>
                                                                                                            {y.dT2}
                                                                                                            <select id="" name={y.docNo + y.dM2 + y.doc.StarY} value={y.doc.UExDocChk == '1' ? y.doc.StarY : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(66)].map((value, j) => (
                                                                                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            年
                                                                                                            <select id="" name={y.docNo + y.dM2 + y.doc.StarM} value={y.doc.UExDocChk == '1' ? y.doc.StarM : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(12)].map((value, j) => (
                                                                                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span> </span>
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )

                                                                                    )}
                                                                                    {y.docMark3 != null && (
                                                                                        y.dM3 == "T" ? (
                                                                                            <span>
                                                                                                {y.dT3List.map((zz, i4) => (
                                                                                                    <span>
                                                                                                        <span>
                                                                                                            <input type="text" value={zz.value} name={y.docNo + i4} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, y.docNo + i4, "T")} />{zz.docMark}
                                                                                                        </span>
                                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        {(i4 == 1 && (
                                                                                                            <font color="red">請填入組別或不分組。</font>
                                                                                                        ))}

                                                                                                    </span>
                                                                                                ))}
                                                                                            </span>
                                                                                        ) : (
                                                                                            y.dM3 == "N" ? (
                                                                                                <span>
                                                                                                    <input type="number" min="0" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark3 : ""} name={y.docNo + y.dM3} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark3")} />{y.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    <span id={y.docNo + y.dM3} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                                                </span>
                                                                                            ) : (
                                                                                                y.dM3 == "R" ? (
                                                                                                    y.dT1List.map((zz, i4) => (
                                                                                                        <span>
                                                                                                            {zz.attr.map((zy, i5) => (
                                                                                                                <span>
                                                                                                                    <input type="radio" value={zy.value} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, y.docNo + i4, "R")} />{zy.Cname}
                                                                                                                </span>
                                                                                                            ))}
                                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    y.dM3 == "CYM" ? (
                                                                                                        <span>
                                                                                                            {y.dT3}
                                                                                                            <select id="" name={y.docNo + y.dM3 + y.doc.EndY} value={y.doc.UExDocChk == '1' ? y.doc.EndY : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "EndY") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(66)].map((value, j) => (
                                                                                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            年
                                                                                                            <select id="" name={y.docNo + y.dM3 + y.doc.EndM} value={y.doc.UExDocChk == '1' ? y.doc.EndM : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "EndM") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(12)].map((value, j) => (
                                                                                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            月

                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span> </span>
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )}
                                                                                    <br /><span id={y.docNo} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請填入【資料】</span>
                                                                                    <span id={y.docNo + y.dM3} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入結束年月需大於開始年月</span>
                                                                                </span>
                                                                            ) : (
                                                                                <span>

                                                                                </span>
                                                                            )
                                                                        ) : (
                                                                            <span>
                                                                                {y.docName}
                                                                                <font color="red">※上傳相關證明後，此項目將於表單列印時自動勾選。</font>

                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt}></td>)}
                                                                    {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt}></td>)}
                                                                </tr>
                                                            )
                                                            : (
                                                                y.docNo == "C001" ? (
                                                                    <tr>
                                                                        <td width="25" className="datatable-bg1">{y.no}</td>
                                                                        <td>
                                                                            {
                                                                                y.item.map((yy, y2) => (
                                                                                    <div>
                                                                                        <input type="radio" name={`UExDocChkC${yy.docGNo}`} checked={yy.docNo === C001chk} value="1" onClick={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocChk", "radio")} />
                                                                                        {yy.docName}
                                                                                        {yy.docMark1 != null && (
                                                                                            <span>：
                                                                                                {yy.dM1 == "T" ? (
                                                                                                    <span>
                                                                                                        {yy.dT1}<input type="text" value={yy.docNo === C001chk ? yy.doc.UExDocMark1 : ""} name={yy.docNo + yy.dM1} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark1")} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    yy.dM1 == "N" ? (
                                                                                                        <span><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            {yy.dT1}<input type="number" value={yy.doc.UExDocMark1} name={yy.docNo + yy.dM1} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark1")} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        yy.dM1 == "R" ? (
                                                                                                            yy.dT1List.map((zz, i4) => (
                                                                                                                <span>
                                                                                                                    {zz.attr.map((zy, i5) => (
                                                                                                                        <span>
                                                                                                                            <input type="radio" value={zy.value} name={zz.docMark + i4} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, zz.docMark + i4, "R")} />{zy.Cname}
                                                                                                                        </span>
                                                                                                                    ))}
                                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                                </span>
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            yy.dM1 == "CYM" ? (
                                                                                                                <span>
                                                                                                                    {yy.dT1}
                                                                                                                    <select id="" name={yy.docNo + yy.dM1 + yy.doc.StarY} value={yy.doc.StarY} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                                                        {[...new Array(66)].map((value, j) => (
                                                                                                                            <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                        ))}
                                                                                                                    </select>
                                                                                                                    年
                                                                                                                    <select id="" name={yy.docNo + yy.dM1 + yy.doc.StarM} value={yy.doc.StarM} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                                                        {[...new Array(12)].map((value, j) => (
                                                                                                                            <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                        ))}
                                                                                                                    </select>
                                                                                                                    月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                                </span>
                                                                                                            ) : (
                                                                                                                <span> </span>
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                        {yy.docMark2 != null && (
                                                                                            yy.dM2 == "T" ? (
                                                                                                <span>
                                                                                                    <input type="text" value={yy.doc.UExDocMark2} name={yy.docNo + yy.dM2} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark2")} />{yy.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ) : (
                                                                                                yy.dM2 == "N" ? (
                                                                                                    <span>
                                                                                                        <input type="number" value={yy.doc.UExDocMark2} name={yy.docNo + yy.dM2} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark2")} />{yy.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    yy.dM2 == "R" ? (
                                                                                                        yy.dT1List.map((zz, i4) => (
                                                                                                            <span key={"Rzz" + i4} >
                                                                                                                {zz.attr.map((zy, i5) => (
                                                                                                                    <span key={"Rzy" + i5} >
                                                                                                                        <input type="radio" value={zy.value} name={zz.docMark + i4} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, zz.docMark + i4, "R")} />{zy.Cname}
                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ))

                                                                                                    ) : (
                                                                                                        yy.dM2 == "CYM" ? (
                                                                                                            <span>
                                                                                                                {yy.dT2}
                                                                                                                <select id="" name={yy.docNo + yy.dM2 + yy.doc.StarY} value={yy.doc.StarY} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(66)].map((value, j) => (
                                                                                                                        <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                年
                                                                                                                <select id="" name={yy.docNo + yy.dM2 + yy.doc.StarM} value={yy.doc.StarM} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(12)].map((value, j) => (
                                                                                                                        <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span> </span>
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )

                                                                                        )}
                                                                                        {yy.docMark3 != null && (
                                                                                            yy.dM3 == "T" ? (
                                                                                                <span>
                                                                                                    <input type="text" value={yy.doc.UExDocMark3} name={yy.docNo + yy.dM3} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark3")} />{yy.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ) : (
                                                                                                yy.dM3 == "N" ? (
                                                                                                    <span>
                                                                                                        <input type="number" value={yy.doc.UExDocMark3} name={yy.docNo + yy.dM3} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark3")} />{yy.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    yy.dM3 == "R" ? (
                                                                                                        yy.dT1List.map((zz, i4) => (
                                                                                                            <span key={"zz" + i4} >
                                                                                                                {zz.attr.map((zy, i5) => (
                                                                                                                    <span key={"zy" + i5} >
                                                                                                                        <input type="radio" value={zy.value} name={zy.name} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, zz.docMark + i4, "R")} />{zy.Cname}
                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        yy.dM3 == "CYM" ? (
                                                                                                            <span>
                                                                                                                {yy.dT3}
                                                                                                                <select id="" name={yy.docNo + yy.dM3 + yy.doc.EndY} value={yy.doc.EndY} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "EndY") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(66)].map((value, j) => (
                                                                                                                        <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                年
                                                                                                                <select id="" name={yy.docNo + yy.dM3 + yy.doc.EndM} value={yy.doc.EndM} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "EndM") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(12)].map((value, j) => (
                                                                                                                        <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                月
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span> </span>
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )}
                                                                                        <br /> <span id={yy.docNo} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請填入【資料】</span>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </td>
                                                                        {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={i2 == "0" ? x.Cnt : "0"}></td>)}
                                                                        {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={i2 == "0" ? x.Cnt : "0"}></td>)}
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <td width="25" className="datatable-bg1">{y.no}</td>
                                                                        <td className="datatable-bg1"  >

                                                                            {y.docRefFile == "N" ? (
                                                                                y.docDisplayChild == null ? (
                                                                                    <span>
                                                                                        <input type="checkbox" name={`UExDocChk${y.docNo}`} checked={y.doc.UExDocChk == '1'} value={y.doc.UExDocChk == '1' ? '0' : '1'} onClick={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocChk")} />
                                                                                        {y.docName}
                                                                                        {y.docMark1 != null && (
                                                                                            <span>：
                                                                                                {y.dM1 == "T" ? (
                                                                                                    <span>
                                                                                                        <input type="text" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark1")} />{y.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    y.dM1 == "N" ? (
                                                                                                        <span><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            <input type="number" min="0" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "60px" }} maxLength="1" onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark1")} />{y.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            <span id={y.docNo + y.dM1} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        y.dM1 == "R" ? (
                                                                                                            y.dT1List.map((zz, i4) => (
                                                                                                                <span>
                                                                                                                    {zz.attr.map((zy, i5) => (
                                                                                                                        //y.doc.UdT1List.map((zyy, i6) => (
                                                                                                                        <span>
                                                                                                                            <input type="radio" value={zy.value} checked={zy.chked == "1"} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                                                        </span>
                                                                                                                        //))checked={y.doc.UdT1List[i4].uDocMark == zy.value}
                                                                                                                    ))}
                                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                                </span>
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            y.dM1 == "CYM" ? (
                                                                                                                <span>
                                                                                                                    {y.dT1}
                                                                                                                    <select id="" name={y.docNo + y.dM1 + y.doc.StarY} value={y.doc.UExDocChk == '1' ? y.doc.StarY1 : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                                                        {[...new Array(66)].map((value, j) => (
                                                                                                                            <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                        ))}
                                                                                                                    </select>
                                                                                                                    年
                                                                                                                    <select id="" name={y.docNo + y.dM1 + y.doc.StarM} value={y.doc.UExDocChk == '1' ? y.doc.StarM : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                                                        {[...new Array(12)].map((value, j) => (
                                                                                                                            <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                        ))}
                                                                                                                    </select>
                                                                                                                    月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                                </span>
                                                                                                            ) : (
                                                                                                                <span> </span>
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )}
                                                                                            </span>
                                                                                        )}
                                                                                        {y.docMark2 != null && (
                                                                                            y.dM2 == "T" ? (



                                                                                                y.docNo == "B002" ? (
                                                                                                    <span>
                                                                                                        <br />
                                                                                                        <input type="text" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "150px", marginLeft: "30px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark2")} />{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <span>
                                                                                                        <input type="text" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "150px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark2")} />{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                    </span>
                                                                                                )

                                                                                            ) : (
                                                                                                y.dM2 == "N" ? (
                                                                                                    <span>
                                                                                                        <input type="number" min="0" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "60px" }} m onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark2")} />{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <span id={y.docNo + y.dM2} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    y.dM2 == "R" ? (
                                                                                                        y.dT1List.map((zz, i4) => (
                                                                                                            <span>
                                                                                                                {zz.attr.map((zy, i5) => (
                                                                                                                    <span>
                                                                                                                        <input type="radio" value={zy.value} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ))

                                                                                                    ) : (
                                                                                                        y.dM2 == "CYM" ? (
                                                                                                            <span>
                                                                                                                {y.dT2}
                                                                                                                <select id="" name={y.docNo + y.dM2 + y.doc.StarY} value={y.doc.UExDocChk == '1' ? y.doc.StarY : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(66)].map((value, j) => (
                                                                                                                        <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                年
                                                                                                                <select id="" name={y.docNo + y.dM2 + y.doc.StarM} value={y.doc.UExDocChk == '1' ? y.doc.StarM : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(12)].map((value, j) => (
                                                                                                                        <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span> </span>
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )

                                                                                        )}
                                                                                        {y.docMark3 != null && (
                                                                                            y.dM3 == "T" ? (
                                                                                                <span>
                                                                                                    {y.dT3List.map((zz, i4) => (
                                                                                                        <span>
                                                                                                            <span>
                                                                                                                {zz.docMark}<input type="text" value={zz.value} name={y.docNo + i4} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "T")} />
                                                                                                            </span>
                                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ))}
                                                                                                </span>
                                                                                            ) : (
                                                                                                y.dM3 == "N" ? (
                                                                                                    <span>
                                                                                                        <input type="number" min="0" value={y.doc.UExDocChk == '1' ? y.doc.UExDocMark3 : ""} name={y.docNo + y.dM3} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, "UExDocMark3")} />{y.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <span id={y.docNo + y.dM3} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    y.dM3 == "R" ? (
                                                                                                        y.dT1List.map((zz, i4) => (
                                                                                                            <span>
                                                                                                                {zz.attr.map((zy, i5) => (
                                                                                                                    <span>
                                                                                                                        <input type="radio" value={zy.value} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        y.dM3 == "CYM" ? (
                                                                                                            <span>
                                                                                                                {y.dT3}
                                                                                                                <select id="" name={y.docNo + y.dM3 + y.doc.EndY} value={y.doc.UExDocChk == '1' ? y.doc.EndY : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "EndY") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(66)].map((value, j) => (
                                                                                                                        <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                年
                                                                                                                <select id="" name={y.docNo + y.dM3 + y.doc.EndM} value={y.doc.UExDocChk == '1' ? y.doc.EndM : ""} onChange={(e) => { setDoc(i, i2, y.doc.DocNo, e.target.value, "EndM") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(12)].map((value, j) => (
                                                                                                                        <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                月

                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span> </span>
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )}
                                                                                        <br /><span id={y.docNo} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請填入【資料】</span>
                                                                                        <span id={y.docNo + y.dM3} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入結束年月需大於開始年月</span>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>

                                                                                    </span>
                                                                                )
                                                                            ) : (
                                                                                <span>
                                                                                    {y.docName}
                                                                                    <font color="red">※上傳相關證明後，此項目將於表單列印時自動勾選。</font>

                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt + 3}></td>)}
                                                                        {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt + 3}></td>)}
                                                                    </tr>

                                                                )



                                                                //y.docDisplayChild == "1" && y.docGNo != null && (
                                                                //    //y.docGNo == null && (
                                                                //    <tr><td width="25" className={`datatable-bg1${y.docNo != "C001-1" ? " hide" : ""}  `} rowSpan={y.docNo == "C001-1" ? "4" : ""}>{y.docNo == "C001-1" ? "16" : y.no}</td>
                                                                //        <td className="datatable-bg1">
                                                                //            {/*) : (*/}

                                                                //        </td></tr>
                                                                //)




                                                            )

                                                    ) : (
                                                        <tr className="hide"><td></td></tr>
                                                    )
                                            )


                                    ))}

                                </tbody>
                            </table>

                        ))
                        }

                        <table className="fileTd datatable">
                            <tbody>
                                <tr>
                                    <td colSpan="5" className="datatable-bg4">※上述所有證件於進入複試報名時審查，正本驗畢當場發還。</td>
                                </tr>
                                <tr>
                                    <td width="104" rowSpan="4" align="center" className="datatable-bg2">經歷<br />
                                        <br /> (最近三項經歷)
                                        <br />      (請依序由上而下填)</td>
                                </tr>

                                {resumeList.map((x, index) => (
                                    <tr key={index} >
                                        <td colSpan="4" valign="top" className="datatable-bg2">機關（學校）：
                                            <input name="exp_school1" type="text" maxLength="10" size="18" value={x.UserROrgName} onChange={(e) => { setResume(index, e.target.value, "UserROrgName") }} />
                                            （職稱）：
                                            <input name="exp_sen1" type="text" size="10" value={x.UserRJobName} onChange={(e) => { setResume(index, e.target.value, "UserRJobName") }} />
                                            <br />
                                            服務期間：

                                            <select id={`resumeYs${index + 1}`} name={`resumes${index + 1}`} value={x.resumeYs} onChange={(e) => { setResume(index, e.target.value, "resumeYs") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(66)].map((value, j) => (
                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                ))}
                                            </select>
                                            年
                                            <select id={`resumeMs ${index + 1}`} name={`resumes${index + 1}`} value={x.resumeMs} onChange={(e) => { setResume(index, e.target.value, "resumeMs") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(12)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            月
                                            <select id={`resumeDs ${index + 1}`} name={`resumes${index + 1}`} value={x.resumeDs} onChange={(e) => { setResume(index, e.target.value, "resumeDs") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(31)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            日&nbsp;至&nbsp;
                                            <select id={`resumeYe${index + 1}`} name={`resumee${index + 1}`} value={x.resumeYe} onChange={(e) => { setResume(index, e.target.value, "resumeYe") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(66)].map((value, j) => (
                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                ))}
                                            </select>
                                            年
                                            <select id={`resumeMe ${index + 1}`} name={`resumee${index + 1}`} value={x.resumeMe} onChange={(e) => { setResume(index, e.target.value, "resumeMe") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(12)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            月
                                            <select id={`resumeDe ${index + 1}`} name={`resumee${index + 1}`} value={x.resumeDe} onChange={(e) => { setResume(index, e.target.value, "resumeDe") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(31)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            日
                                        </td>
                                    </tr>
                                ))}

                                <tr>
                                    <td colSpan="5">
                                        <br />
                                        是否同意將個人資料提供本市各市立國民中學聘任代理、代課、兼任教師及教育統計時使用？<span className="color-3">＊</span>
                                        <input className="required" type="radio" name="DataUse" id="DataUse1" value="0" onClick={(e) => chk("UDataUse", "DataUse")} checked={UserList.UDataUse === 0} />否
                                        <input className="required" type="radio" name="DataUse" id="DataUse2" value="1" onClick={(e) => chk("UDataUse", "DataUse")} checked={UserList.UDataUse === 1} />是
                                        <br />
                                        <br /><span id="DataUse" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請確認是否同意【個人資料提供本市各市立國民中學使用】</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td><font color="#CC0066">(修改資料後，務必按「下一步」，以完成修改動作)</font></td>
                                </tr>
                            </tbody>
                        </table>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                        <div align="center">
                            <input type="button" value="下一步" name="sub" onClick={(e) => Next()} style={{ width: "80px", margin: "0.4rem 1rem" }} />
                        </div>

                    </div>

                </div>

            </div>
        </>
    );
}

export default Modifyform;
