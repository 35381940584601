import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_Get_RTUpFile_GetRTSvrCertData,
    API_PUT_RTUpFile_SaveRTSvrCertData,
    API_PUT_UpFile_DelSvrCertData,
    API_Get_UpFile_getDoc3Yata,
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';//console.log(upFileMail(UserData.userID));
import Swal from 'sweetalert2';

function Second_file() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg", ".png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [fileList, setFileList] = useState([]);//資料容器
    const [imgBase, setImgBase] = useState([]);//伺服器取得圖片容器
    const [imgBaseItem, setimgBaseItem] = useState({});//彈窗圖片容器
    const [file, setFile] = useState();

    const [year, setyear] = useState(""); //服務時間（年度)
    const [Doc3Y, setDoc3Y] = useState(""); //同所學校連續服務滿三年
    const [attState, setattState] = useState(""); //服務時間（起）-日
    const [DocRefFile, setDocRefFile] = useState(""); //是否上傳
    const [UserDataLock, setUserDataLock] = useState(""); //是否已下載報名表
    
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {
        console.log(menuList);
        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();
    }, []);

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/signup/modify_form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得資料
    function getData() {
        API_Get_RTUpFile_GetRTSvrCertData({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }

                console.log(data);
                if (data.code == "0000") {
                    setUserDataLock(data.result.UserDataLock);
                    setDocRefFile(data.result.DocRefFile);
                    setDoc3Y(data.result.YN3);
                    fileList.length = 0;
                    var item = [];
                    data.result.data.map((obj, i) => {
                        obj.userID = UserData.userID;
                        obj.base64 = "";
                        obj.StarY = parseInt(obj.userSCYear);
                        obj.StarM = "";
                        obj.StarD = "";
                        obj.EndY = parseInt(obj.userSCYear) + 1;
                        obj.EndM = "";
                        obj.EndD = "";
                        obj.attState = null;
                        if (obj.file.length > 0) {
                            console.log(imgBase);

                            obj.file.map((obj2, i2) => {
                                obj.base64 = obj2.base64String;

                            });
                        }
                        if (obj.userSCStartDate) {
                            obj.StarM = parseInt(obj.userSCStartDate.slice(5, 7), 10);
                            obj.StarD = parseInt(obj.userSCStartDate.slice(8, 10), 10);
                        }
                        if (obj.userSCEndDate) {
                            obj.EndM = parseInt(obj.userSCEndDate.slice(5, 7), 10);
                            obj.EndD = parseInt(obj.userSCEndDate.slice(8, 10), 10);
                        }

                        item.push(obj);
                    });
                    console.log(item);
                    setFileList(item);
                    document.getElementById("loading").style.display = "none";
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

    }

    //服務時間（起迄）
    function chk_date(year, date, birthName) {
        setyear(year);
        const updatefileList = fileList.map((obj, i) => {
            if (year == obj.userSCYear) {
                if (birthName == "StarM") {
                    return { ...obj, StarM: date };
                }
                if (birthName == "StarD") {
                    return { ...obj, StarD: date };
                }
                if (birthName == "EndM") {
                    return { ...obj, EndM: date };
                }
                if (birthName == "EndD") {
                    return { ...obj, EndD: date };
                }


            }
            return obj;
        });
        //var updatefileList = { ...fileList, [objName]: e };
        setFileList(updatefileList);
        //console.log(updatefileList);
    }
    //radio btn
    function chk_rBtn(year, radioName, tagName, chgVal) {

        var chkList = document.querySelectorAll("input[name='" + tagName + "']");
        chkList.forEach((event) => {

            if (event.value == chgVal) {
                event.checked = true;
                set(year, radioName, event.value);

            }
        });
    }

    //上傳檔案
    function handleChange(year, index, event) {
        document.getElementById("loading").style.display = "block";
        //console.log(index);
        if (event.target.files.length>0) {
            console.log(event.target.files);
            setattState("1");
            if (event.target.files[0].size > fileMaxSize) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "上傳檔案過大"
                    })
                )

            }
            if (!fileType.includes(event.target.files[0].type)) {
                console.log("檔案格式");
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "請選擇檔案格式 jpg 或 png"
                    })
                )
            }
            if (index == 0) {
                setFile({ [index]: event.target.files[0] });
            } else {
                setFile({ ...imgBase, [index]: event.target.files[0] });
            }

            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                var list = {};

                if (index == 0) {
                    document.getElementById("loading").style.display = "none";
                    setImgBase({ [index]: reader.result });
                } else {
                    document.getElementById("loading").style.display = "none";
                    setImgBase({ ...imgBase, [index]: reader.result });
                }
            }
            document.querySelector(".viewImg" + index).classList.remove('hide');

            if (document.querySelector("#Img" + index).src != "" && fileList[index].base64 != "") {
                document.querySelector("#Img" + index).classList.add('hide');
                document.querySelector("#succ" + index).classList.add('hide');
                set(year, "base64", "");
            }
        } else {
            document.getElementById("loading").style.display = "none";
        }
        set(year, "attState", "U");
    }

    //編輯資料
    function set(year, objName, e) {
        const updatefileList = fileList.map((obj, i) => {
            if (year == obj.userSCYear) {
                return { ...obj, [objName]: e };
            }
            return obj;
        });
        //var updatefileList = { ...fileList, [objName]: e };
        setFileList(updatefileList);
    }
    //檢查資料
    function chkData(year, index) {
        var result = true;
        var err = "";
        console.log('fileList');
        console.log(fileList);

        if (fileList[index].userSCCityNo == null) {
            err += '請填寫服務學校縣市<br>';
            result = false;
        }
        if (fileList[index].userSCJobType == null) {
            err += '請填寫服務學校職稱<br>';
            result = false;
        }
        if (fileList[index].userSCSchType == null) {
            err += '請填寫服務學校單位<br>';
            result = false;
        }
        if (fileList[index].userSCSchName == null) {
            err += '請填寫服務學校名稱<br>';
            result = false;
        }
        if (fileList[index].StarM == "" || fileList[index].StarD == "") {
            err += '請填寫服務起始時間<br>';
            result = false;
        }
        if (fileList[index].EndM == "" || fileList[index].EndD == "") {
            err += '請填寫服務結束時間<br>';
            result = false;
        }
        // 新增
        if (fileList[index].userSCID == null) {
            if (!imgBase[index]) {
                err += '請上傳檔案<br>';
                result = false;
            }
        }
        if (fileList[index].attState == "U") {
            if (!imgBase[index]) {
                err += '請上傳檔案<br>';
                result = false;
            }
        }

        if (err != "") {
            document.getElementById("tr" + index).classList.add('svrErr');
            Swal.fire({
                icon: 'error',
                html: err,
            })
        } else {
            document.getElementById("tr" + index).classList.remove('svrErr');
        }
        return result;
    }

    //數字補0
    function autoSupplement(val) {
        var n = String(val);
        return n.padStart(2, '0')

    }
    //儲存提示訊息
    function saveDataAlert(year, index) {
        var chk = chkData(year, index);
        if (chk) {
           
        Swal.fire({
            icon: 'warning',
            text: "請務必留意上傳文件之正確性及是否符合相關條件；倘於審查時發現資績證明文件有誤，該資績分數不予採計",
        }).then((result) => {
            saveImg(year, index);
        })
        }

    }
    //儲存
    function saveImg(year, index) {
       
        document.getElementById("loading").style.display = "block";

        if (document.getElementById("putButton" + index) && !document.getElementById("putButton" + index).disabled) {
            document.getElementById("putButton" + index).disabled = true;
        } else {
            return null;
        }
        fileList[index].userSCStartDate = (parseInt(fileList[index].StarY, 10) + 1911) + "-" + autoSupplement(parseInt(fileList[index].StarM)) + "-" + autoSupplement(parseInt(fileList[index].StarD));
        fileList[index].userSCEndDate = (parseInt(fileList[index].EndY, 10) + 1911) + "-" + autoSupplement(parseInt(fileList[index].EndM)) + "-" + autoSupplement(parseInt(fileList[index].EndD));

        //if (chk) {

        API_PUT_RTUpFile_SaveRTSvrCertData({
                "attState": fileList[index].attState,
                "file": [{
                    "attid": (attState == "1" ? null : fileList[index].file[0].attid),
                    "fileName": (attState == "1" ? file[index].name : fileList[index].file[0].fileName),
                    "base64String": (attState == "1" ? imgBase[index] : fileList[index].base64)
                }],
                "userSCID": fileList[index].userSCID,
                "userSCATTID": fileList[index].userSCATTID,
                "userEKID": fileList[index].userEKID,
                "userID": fileList[index].userID,
                "userSCYear": fileList[index].userSCYear,
                "userSCCityNo": fileList[index].userSCCityNo,
                "userSCSchType": fileList[index].userSCSchType,
                "userSCJobType": fileList[index].userSCJobType,
                "userSCSchName": fileList[index].userSCSchName,
                "userSCStartDate": fileList[index].userSCStartDate,
                "userSCEndDate": fileList[index].userSCEndDate
            }).then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        window.location.href = "../";
                    }
                    console.log(data);
                    document.getElementById("loading").style.display = "none";
                    if (data.code == "0000") {
                        Swal.fire({
                            icon: 'success',
                            text: "儲存成功",
                        })
                        console.log(upFileMail(UserData.userID));
                        location.reload()
                       
                        imgBase[index] = "";
                        //window.location.href = "../signup/Modify_file";
                        getData();
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                        document.getElementById("loading").style.display = "none";
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })
                    }
                    document.getElementById("putButton" + index).disabled = false;
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
        //}
    }

    //刪除
    function delImg(year, index) {
        Swal.fire({
            text: "刪除圖片資料?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '確定',
            cancelButtonText: '取消'
        }).then((result) => {
            if (result.isConfirmed) {
                API_PUT_UpFile_DelSvrCertData({
                    "userSCID": fileList[index].userSCID,
                    "UserSCATTID": fileList[index].userSCATTID
                }).then((response) => response.data)
                    .then((data) => {
                        if (!checkAuthToken(data)) {
                            window.location.href = "../";
                        }
                        console.log(data);
                        if (data.code == "0000") {
                            console.log(upFileMail(UserData.userID));
                            getData();
                            document.getElementsByName("file" + index)[0].value="";
                            //setImgBase({ [index]: "" });
                           
                            Swal.fire({
                                icon: 'success',
                                text: "刪除成功",
                            })

                            const upList = fileList.map((obj, i) => {
                                if (i == index) {

                                    return { ...obj, userSCCityNo: "", userSCSchName: "" };
                                }
                                return obj;
                            });
                            setFileList(upList);

                           
                            //window.location.href = "Modify_file";
                        } else if (data.code == "9998") {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            }).then((result) => {
                                if (data.result && data.result.redirectUrl) {
                                    window.location.href = data.result.redirectUrl;
                                } else {
                                    window.location.href = "/";
                                }
                            });
                            document.getElementById("loading").style.display = "none";
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                    })
            }
        })

       
    }

    //Open彈窗
    function openModal(index) {
        setimgBaseItem(fileList[index].base64);
        var target = document.querySelector("#modal-showImg");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗
    function hideModal() {
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //history.push(next);
    }
    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    <font color="red" size="4"></font><br />

                    <div style={{ fontSize: "12px" }}>
                        <table width="100%" border="1" className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)", fontSize: "12px" }}>
                            <tbody>
                                <tr style={{ textAlign: "center" }}>
                                    <td width="5%">學年度</td>
                                    <td width="35%">服務學校及服務時間</td>
                                    <td>服務證明文件</td>
                                    <td width="20%">儲存</td>
                                </tr>
                                {fileList.map((y, i) => (
                                    
                                    y.file.length > 0 ? (
                                        <tr id={`tr${i}`}>
                                            <td>{y.userSCYear}</td>
                                            <td>
                                                縣市：
                                                <select name="city" size="1" id="city" value={y.userSCCityNo} onChange={(e) => { set(y.StarY, "userSCCityNo", e.target.value) }}>
                                                    <option value="">請選擇</option>
                                                    <option value="宜蘭縣">宜蘭縣</option>
                                                    <option value="基隆市" selected="">基隆市</option>
                                                    <option value="新北市">新北市</option>
                                                    <option value="臺北市">臺北市</option>
                                                    <option value="桃園市">桃園市</option>
                                                    <option value="新竹市">新竹市</option>
                                                    <option value="新竹縣">新竹縣</option>
                                                    <option value="苗栗縣">苗栗縣</option>
                                                    <option value="臺中市">臺中市</option>
                                                    <option value="彰化縣">彰化縣</option>
                                                    <option value="南投縣">南投縣</option>
                                                    <option value="嘉義市">嘉義市</option>
                                                    <option value="嘉義縣">嘉義縣</option>
                                                    <option value="雲林縣">雲林縣</option>
                                                    <option value="臺南市">臺南市</option>
                                                    <option value="高雄市">高雄市</option>
                                                    <option value="屏東縣">屏東縣</option>
                                                    <option value="臺東縣">臺東縣</option>
                                                    <option value="花蓮縣">花蓮縣</option>
                                                    <option value="澎湖縣">澎湖縣</option>
                                                    <option value="金門縣">金門縣</option>
                                                    <option value="連江縣">連江縣</option>
                                                    <option value="其他">其他</option>
                                                </select>
                                                <br />
                                                服務學校職稱：
                                                <input type="radio" onChange={(e) => chk_rBtn(y.StarY, "userSCJobType", "sch_type" + i, e.target.value)} name={`sch_type${i}`} value="1" checked={y.userSCJobType === '1'} />公立正式教師
                                                <input type="radio" onChange={(e) => chk_rBtn(y.StarY, "userSCJobType", "sch_type" + i, e.target.value)} name={`sch_type${i}`} value="2" checked={y.userSCJobType === '2'} />私立學校教師
                                                <input type="radio" onChange={(e) => chk_rBtn(y.StarY, "userSCJobType", "sch_type" + i, e.target.value)} name={`sch_type${i}`} value="3" checked={y.userSCJobType === '3'} />公立代理教師
                                                <br />
                                                服務學校單位：
                                                <input type="radio" onChange={(e) => chk_rBtn(y.StarY, "userSCSchType", "sch_class" + i, e.target.value)} name={`sch_class${i}`} value="C" checked={y.userSCSchType === 'C'} />國中
                                                <input type="radio" onChange={(e) => chk_rBtn(y.StarY, "userSCSchType", "sch_class" + i, e.target.value)} name={`sch_class${i}`} value="D" checked={y.userSCSchType === 'D'} />高中
                                                <input type="radio" onChange={(e) => chk_rBtn(y.StarY, "userSCSchType", "sch_class" + i, e.target.value)} name={`sch_class${i}`} value="E" checked={y.userSCSchType === 'E'} />高職
                                                <br />
                                                服務學校：
                                                <input size="25" type="text" name="sch_name" value={y.userSCSchName} onChange={(e) => { set(y.StarY, "userSCSchName", e.target.value) }} />
                                                <br />
                                                時間（起）:
                                                <span style={{ fontSize: "15px", padding: "7px 7px" }}>{y.StarY}</span>
                                                年
                                                <select id="StarM" name="StarM" value={y.StarM} onChange={(e) => { chk_date(y.StarY, e.target.value, "StarM") }}>
                                                    <option value="" selected="selected">請選擇...</option>
                                                    {[...new Array(12)].map((value, i) => (
                                                        <option value={i + 1}>{i + 1}</option>
                                                    ))}
                                                </select>
                                                月
                                                <select id="StarD" name="StarD" value={y.StarD} onChange={(e) => { chk_date(y.StarY, e.target.value, "StarD") }}>
                                                    <option value="" selected="selected">請選擇...</option>
                                                    {[...new Array(31)].map((value, i) => (
                                                        <option value={i + 1}>{i + 1}</option>
                                                    ))}
                                                </select>
                                                日<br />
                                                時間（迄）:
                                                <span style={{ fontSize: "15px", padding: "7px 7px" }}>{y.EndY}</span>
                                                年
                                                <select id="EndM" name="EndM" value={y.EndM} onChange={(e) => { chk_date(y.StarY, e.target.value, "EndM") }}>
                                                    <option value="" selected="selected">請選擇...</option>
                                                    {[...new Array(12)].map((value, i) => (
                                                        <option value={i + 1}>{i + 1}</option>
                                                    ))}
                                                </select>
                                                月
                                                <select id="EndD" name="EndD" value={y.EndD} onChange={(e) => { chk_date(y.StarY, e.target.value, "EndD") }}>
                                                    <option value="" selected="selected">請選擇...</option>
                                                    {[...new Array(31)].map((value, i) => (
                                                        <option value={i + 1}>{i + 1}</option>
                                                    ))}
                                                </select>
                                                日
                                            </td>
                                            <td>
                                                {y.base64 != "" && (
                                                    <div id={`succ${i}`}>
                                                        <font color="blue">已上傳成功</font>
                                                        <a href="#" onClick={(e) => openModal(i)} ><font color="red">(預覽)</font></a>
                                                    </div>
                                                )}

                                                <div>
                                                    
                                                    {DocRefFile != '9' && (
                                                      
                                                        UserDataLock == '1' ? (
                                                                <span>已下載報名表，無法變更檔案。</span>
                                                            ) : (
                                                                y.uDocCF != '0' && y.uDocCF != null ? (
                                                                    <span>已審核，無法變更檔案。</span>
                                                                ) : (
                                                                    <span>
                                                                        <input name={`file${i}`} type="file" size="10" onChange={(e) => handleChange(y.StarY, i, e)} accept={fileDotType.join(",")} />
                                                                        <br />
                                                                        <font color="red">※如有上傳檔案請按【儲存】</font>
                                                                        <br />
                                                                    </span>
                                                                )

                                                            )
                                                    )}


                                                    <img id={`Img${i}`} className={y.base64 != "" ? "" : "hide"} src={y.base64} name="Std_img" height="110" />
                                                    <img className={`hide viewImg${i}`} src={imgBase[i]} name="Std_img" height="110" />
                                                </div>
                                            </td>

                                            <td>
                                                {DocRefFile != '9' ? (
                                                    UserDataLock == '1' ? (
                                                            <span>已下載報名表，無法變更檔案。</span>
                                                        ) : (
                                                            y.uDocCF != '0' && y.uDocCF != null ? (
                                                                <span>已審核，無法變更檔案。</span>
                                                            ) : (
                                                                <input type="button" id={`putButton${i}`} name="saveImg" onClick={(e) => saveDataAlert(y.StarY, i)} value="儲存" />
                                                            )
                                                        )
                                                    
                                                ) : (
                                                    <span>初試上傳附件資料，無法變更。</span>
                                                )}
                                                
                                            </td>
                                        </tr>

                                        ): (
                                            <tr id={`tr${i}`} >
                                                <td>{y.userSCYear}</td>
                                                <td colSpan="3" ><p className="text-center" style={{ fontSize: "16px"}}>初試填寫資料，不可上傳。</p></td>
                                            </tr>
                                        )
                          
                                ))}

                                <tr>
                                    <td colSpan="6">
                                        <input id="Doc3Y" type="checkbox" className="checkbox2" name="Doc3Y" value="1"  checked={Doc3Y === '1'} />同所學校連續服務滿三年
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    <div align="center"><br />
                        <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
                    </div>

                </div>

                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBaseItem} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default Second_file;
