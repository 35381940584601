import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { API_Get_Account_DefalultMenus, API_Get_UpFile_getFrontSet, API_Get_Front_getFrontSet } from '../../global/constants';

function Header() {
    var history = useHistory();
    const path = window.location.pathname;
    let currentPage = path.toLowerCase();
    if (path === '/') {
        currentPage = '/news';
    }
    const [menuList, setMenuList] = useState([]);
    const [docMark_h, setUdocMark_h] = useState("");//說明內容-首頁

    useEffect(() => {
        let getMenu = false;
        if (localStorage.getItem('isLogin') == "1") {
            let dbmenu = localStorage.getItem('menuList');
            if (dbmenu) {
                let menuData = JSON.parse(localStorage.getItem('menuList'))
                if (menuData.length > 0) {//remove this if 不做例外
                    getMenu = true;
                    setMenuList(menuData);
                }
            }
        }//else{callDefaultMenu();}
        if (!getMenu) {
            checkBackMenu();
        }
        console.log(123)
        getFrontSet();
        console.log(456)
    }, []);


    function checkBackMenu() {
        if (localStorage.getItem("defaultMenu")) {
            let tempList = JSON.parse(localStorage.getItem("defaultMenu"));
            if (tempList.length > 0) {
                //console.log(tempList);
                setMenuList(tempList);
            }
        } else {
            window.setTimeout(function () {
                checkBackMenu();
            }, 100)
        }
    }

    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_Front_getFrontSet({
            "FrontUrl": "/header",
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {

                if (data.code == "0000") {

                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_h(obj.frontUrlText)
                       }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }

            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function goHref(link, data) {
        if (data.ExSchdEndDate && data.ExSchdStartDate) {
            if (new Date() - new Date(data.ExSchdStartDate) > 0) {
                if (new Date() - new Date(data.ExSchdEndDate) > 0) {
                    alert("已經結束功能");
                    return window.location.href = "/";
                }
            } else {
                alert("尚未開放功能");
                return window.location.href = "/";
            }
        }
        window.location.href = link;
    }
    //登入
    function logout() {
        localStorage.clear();
        window.location = "../";
    }

    return (
        <div id="header">
            <div id="top">
                <div id="logo">
                    {/*<div id="logo-text01">臺北市112學年度市立國民中學</div>*/}
                    {/*<div id="logo-text02">正式教師聯合甄選</div>*/}
                    <div dangerouslySetInnerHTML={{ __html: docMark_h }}></div>
                </div>
                <div id="banner">
                    {/*目前線上人數：<strong className="color-1">42</strong>位*/}
                </div>
            </div>
            <div id="menu">
                <ul style={{ maxWidth: "unset" }}>
                    {
                        menuList.map((value, key) => {
                            if (Object.keys(value).length > 0) {
                                //console.log(value);
                                if (value.FuncUrl == "logout" || value.FuncUrl == "/logout") {
                                    return (
                                        <li key={key} >
                                            <a style={{ whiteSpace: "nowrap", cursor: "pointer" }} onClick={logout} >{value.MenuCpation}</a>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={key} >
                                            <a href="#"
                                                className={value.FuncUrl.toLowerCase() == currentPage || currentPage.includes(`${value.FuncUrl.toLowerCase()}/`) ? "active current" : ""}
                                                style={{ whiteSpace: "nowrap" }}
                                                onClick={(e) => goHref(value.subMenu[0].FuncUrl, value)}>
                                                {value.MenuCpation}
                                            </a>
                                        </li>
                                    )
                                }
                            }
                        })
                    }
                </ul>
            </div>
        </div>
    );


}
export default Header;