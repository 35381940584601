import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_Confirm_RegData
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';

function Modify_Bankinfo() {
    const history = useHistory();

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [UdocList, setUdocList] = useState([]); //使用者上傳文件清單
    const [Uphoto, setUphoto] = useState({}); //使用者證件照
    const [UComplete, setUComplete] = useState({}); //報名資料狀態
    const [PayState, setPayState] = useState({}); //繳費記錄
    const [UserList, setUserList] = useState({}); //使用者資料清單

    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方


    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {

                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                    }
                });
            });
        });

        getData();
    }, []);

    //取得資料
    function getData() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })


                    API_Get_Confirm_RegData({
                        "UserEKID": UserData.userEKID
                    }).then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                window.location.href = "../";
                            }
                            //console.log(data);
                            if (data.code == "0000") {
                                console.log(data);

                                setUComplete(data.result.user[0].UComplete);
                                setPayState(data.result.user[0].PayState);
                                setUserList(data.result.user[0]);

                                data.result.docList.map((obj, i) => {
                                    //console.log(obj);
                                    if (obj.DocRefFile == "Y") {
                                        //console.log(obj);
                                        setUdocList(current => [...current, obj]);
                                    }
                                    if (obj.DocNo == "A001") {
                                        //console.log(obj);
                                        setUphoto(obj);
                                    }
                                })

                                document.getElementById("loading").style.display = "none";

                            } else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                                document.getElementById("loading").style.display = "none";
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })
                                document.getElementById("loading").style.display = "none";
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push('/');
                            }
                        })


                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })


    }

    //下一步驟
    function Next(type = null) {
        //console.log("type");
        //console.log(type);
        if (type == "showContent1") {
            document.querySelector("#content").classList.add("hide");
            document.querySelector("#content1").classList.remove("hide");
        } else {
            window.location.href = next;
            //history.push(next);
        }

    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content" className="">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>



                    <div align="center" >
                        <input name="button1" type="button" onClick={(e) => Next('showContent1')} value="下一步" style={{ width: "80px", height: "35px" }} />
                    </div>

                </div>

                <div id="content1" className="hide">

                    <div>

						<div>
							<div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
							
						</div>
						<table border="1" cellpadding="1" cellspacing="1" style={{ width: "1000px" } }>
							<tbody>
								<tr>
									<td colspan="2" style={{ textAlign: "center" } }>報名狀態</td>
								</tr>

								<tr>
									<td width="70%">考生姓名</td>
									<td width="30%">{UserList.UName }</td>
								</tr>

                                <tr>
                                    <td>身分證字號</td>
                                    <td>{UserList.UIDNo}</td>
                                </tr>

                                <tr>
                                    <td>報名資料狀態</td>
                                    <td>
                                        {UComplete == 0 && (
                                            <span>【<font color="red">必填欄位資料未完整</font>】<br /></span>
                                        )}
                                        {UComplete == 1 && (
                                            <span>【<font color="blue">必填欄位資料完整</font>】<br /></span>
                                        )}
                                    </td>
                                </tr>

								<tr>
									<td>照片狀態</td>
									<td>狀態：
										{Uphoto.UExDocATTID == "未上傳" && (
											<span>【<font color="#CoC0C0">無資料</font>】</span>
										)}
										{Uphoto.UExDocATTID == "已上傳成功" && (
											<span>【<font color="blue">已上傳成功</font>】</span>
										)}
										{Uphoto.UDocCF == "0" && (
											<span>【<font color="#CoC0C0">{Uphoto.CodeText}</font>】</span>
										)}
										{Uphoto.UDocCF == "1" && (
											<span>【<font color="blue">{Uphoto.CodeText}</font>】</span>
										)}
										{Uphoto.UDocCF == "2" && (
											<span>【<font color="##ffb8">{Uphoto.CodeText}</font>】</span>
										)}
										{Uphoto.UDocCF == "3" && (
											<span>【<font color="green">{Uphoto.CodeText}</font>】</span>
										)}
										{Uphoto.UDocCF == "4" && (
											<span>【<font color="red">{Uphoto.CodeText}</font>】</span>
										)}
										{/*【<font color="blue">已上傳成功</font>】【<font color="red">相片尚未審核</font>】<br />			其他說明：<font color="red"><font color="#C0C0C0">(無)</font></font>*/}
									</td>
								</tr>
								<tr>
									<td>繳費記錄</td>
									<td>
										{PayState == "未繳費" && (
											<span><font color="red">尚無繳費紀錄</font><br /></span>
										)}
										{PayState == "已繳費" && (
											<span>【<font color="blue">已繳費</font>】<br /></span>
										)}</td>
								</tr>
								{UdocList.map((x, i) => (
									<tr>
										<td>{x.DocName}</td>
										<td>
											狀態：
											{x.UExDocATTID == "無資料"  && (
												<span>【<font color="#CoC0C0">無資料</font>】</span>
											)}
											{x.UExDocATTID == "未上傳" && (
												<span>【<font color="#CoC0C0">未上傳</font>】</span>
											)}
											{x.UExDocATTID == "已上傳成功"  && (
												<span>【<font color="blue">已上傳成功</font>】</span>
											)}
											{x.UDocCF == "0" && (
												<span>【<font color="#CoC0C0">{x.CodeText}</font>】</span>
											)}
											{x.UDocCF == "1" && (
												<span>【<font color="blue">{x.CodeText}</font>】</span>
											)}
											{x.UDocCF == "2" && (
												<span>【<font color="##ffb8">{x.CodeText}</font>】</span>
											)}
											{x.UDocCF == "3" && (
												<span>【<font color="green">{x.CodeText}</font>】</span>
											)}
											{x.UDocCF == "4" && (
												<span>【<font color="red">{x.CodeText}</font>】</span>
											)}
										</td>
									</tr>
									))}
							</tbody>
						</table>
						<div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
						<p>&nbsp;</p>
						<div align="center">
							<input name="button1" type="button" onClick={(e) => Next()} value="下一步" style={{ width: "80px", height: "35px", margin:"0.4rem 0" }} />
						</div>
						
					</div>
				</div>
			</div>


        </>
    );
}


export default Modify_Bankinfo;
