import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_HOST,
    API_Get_UpFile_getFrontSet,
    API_Get_Print_PrintPassNo
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';

function Second_show_paper() {
    const history = useHistory();

    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [uName, setuName] = useState("");//姓名
    const [uidNo, setuidNo] = useState("");//身份證號
    const [exKindName, setexKindName] = useState("");//類組

    const [fileData, setfileData] = useState([]);//下載文件
    //未核發淮考證顯示文字
    const [nonNative, setNonNative] = useState("");

    useEffect(() => {

      
        getData();
    }, []);

    //取得資料
    function getData() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })

                    API_Get_Print_PrintPassNo().then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                window.location.href = "../";
                            }
                            //console.log(data);

                            if (data.code == "0000") {
                                console.log(data);
                                setuName(data.result.uName);
                                setuidNo(data.result.uidNo);
                                setexKindName(data.result.exKindName);
                                setfileData(data.result.fileData);
                                document.getElementById("loading").style.display = "none";
                            } else if (data.code == "4007") {//未核發淮考證
                                setNonNative(data.message);
                                document.getElementById("loading").style.display = "none";
                            } else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                                document.getElementById("loading").style.display = "none";
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })
                                document.getElementById("loading").style.display = "none";
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push('/');
                            }
                        })

                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    {/*<div>*/}
                    {/*    登入者：<span className="color-1">{uName}&nbsp;&nbsp;</span>*/}
                    {/*    身分證號：<span className="color-1">{uidNo}&nbsp;&nbsp;</span>*/}
                    {/*    報考類科：<span className="color-1">{exKindName}</span>*/}

                    {/*</div>*/}
                    <table className="datatable">
                        <tbody>
                            <tr>
                                <th width="40">項目</th>
                                <th width="438">表件名稱</th>
                                <th width="226">備註</th>
                            </tr>
                            {nonNative != "" && (
                                <tr><td colSpan="3">{nonNative}</td></tr>
                            )}

                            {fileData.map((item, i) => (

                                <tr>
                                    <td>{i + 1}</td>
                                    <td>
                                        <a href={`${API_HOST}/${item.fileUrl}`} target="_blank">
                                            <font color="blue" size="4">
                                                <b>下載准考證</b>
                                            </font>
                                        </a>
                                    </td>
                                    <td>
                                        <font size="4">初試、複試必備准考證，請自行下載列印 </font></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    {/*<div align="center"><br />*/}
                    {/*    <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
}


export default Second_show_paper;
