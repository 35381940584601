import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_Get_UpFile_GetData,
    API_PUT_UpFile_SaveFile,
    API_PUT_UpFile_DelFile
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';
import Swal from 'sweetalert2';



function ModifySpecialty() {
    const history = useHistory();

    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg", ".png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [userData, setUserData] = useState({});//資料容器
    const [imgBase, setImgBase] = useState();//伺服器取得圖片容器
    const [imglen, setImglen] = useState({});//伺服器取得圖片容器
    const [file, setFile] = useState({});

    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();
    }, []);

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/signup/modify_form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得資料
    function getData() {
        document.querySelector("input[name='file']").value = "";
        setFile({});

        API_Get_UpFile_GetData({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    setUserData(data.result[0]);

                    setImglen(data.result[0].file.length);
                    if (data.result[0].file.length > 0) {
                        setImgBase(data.result[0].file[0].base64String);

                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                        document.getElementById("loading").style.display = "none";
                    } else {
                        setImgBase("");
                    }

                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

       
    }

    //上傳檔案
    function handleChange(event) {
        document.getElementById("loading").style.display = "block";
        if (event.target.files.length > 0) {
            //console.log(event.target.files);
            let fileDoc = event.target.files[0];
            if (fileDoc.size > fileMaxSize) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "上傳檔案過大"
                    })
                )
            }
            if (!fileType.includes(fileDoc.type)) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "請選擇檔案格式 jpg 或 png"
                    })
                )
            }

            var reader = new FileReader();

            reader.readAsDataURL(fileDoc);
            reader.onload = function () {
                document.getElementById("loading").style.display = "none";
                setFile({
                    fileName: fileDoc.name,
                    imgBase: reader.result
                });
            }
        } else {
            document.getElementById("loading").style.display = "none";
        }
    }

    //Open彈窗
    function openModal() {
        var target = document.querySelector("#modal-showImg");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗
    function hideModal() {
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }
     //儲存提示訊息
    function saveDataAlert() {
        var f = document.querySelector("input[name='file']").value;

        if (f == "") {
            Swal.fire({
                icon: 'error',
                text: '請上傳檔案'
            })
        } else {
            Swal.fire({
                icon: 'warning',
                text: "請務必留意上傳文件之正確性及是否符合相關條件；倘於審查時發現資績證明文件有誤，該資績分數不予採計",
            }).then((result) => {

                saveImg();
            })
        }
       

    }
    //儲存
    function saveImg() {
        if (document.getElementById("putButton") && !document.getElementById("putButton").disabled) {
            document.getElementById("putButton").disabled = true;
        } else {
            return null;
        }
        document.getElementById("loading").style.display = "block";
        API_PUT_UpFile_SaveFile({
            "UserID": UserData.userID,
            "DocNo": uploadDoc,
            "DocName": userData.docName,
            "GATTID": userData.uExDocATTID,// --附件檔案流水號
            "UserEKID": userData.userEKID,// --使用考報考類組序號
            "UExDocID": userData.uExDocID,// --使用者文件流水號
            "DocRefFile": userData.docRefFile,// --Y // N
            "File": [
                {
                    "FileName": (file.fileName || uploadDoc + "undefindedFile.jpg"),
                    "Base64String": file.imgBase
                }
            ]
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'success',
                        text: "儲存成功",
                    })
                    getData();
                    console.log(upFileMail(UserData.userID));
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("putButton").disabled = false;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //刪除
    function delImg() {
        if (userData.uExDocATTID) {
            Swal.fire({
                text: "刪除圖片資料?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '確定',
                cancelButtonText: '取消'
            }).then((result) => {
                if (result.isConfirmed) {

                    API_PUT_UpFile_DelFile({
                        "UserID": UserData.userID,
                        "DocNo": uploadDoc,
                        "UExDocID": userData.uExDocID,
                        "GATTID": userData.uExDocATTID,// --附件檔案流水號
                    }).then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                window.location.href = "../";
                            }
                            console.log(data);
                            if (data.code == "0000") {
                                getData();
                                console.log(upFileMail(UserData.userID));
                            } else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                                document.getElementById("loading").style.display = "none";
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push('/');
                            }
                        })
                }
            })
        } 
    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //history.push(next);
    }

    return (
        <>
            <div id="master-main-bg">

                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    <div id="status">

                    </div>

                    <div id="main">
                        <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)", fontSize: "12px" }}>
                            <tbody>
                                <tr align="center">
                                    <td>瀏覽|選擇檔案</td>
                                    <td>儲存</td>
                                    <td>刪除</td>
                                </tr>
                                <tr>
                                    <td>
                                        {imglen > 0 && (
                                            <div>
                                                <font color="blue">已上傳成功</font>
                                                <a href="#" onClick={openModal} ><font color="red">(預覽)</font></a>
                                            </div>

                                        )}
                                        <div>
                                            <input name="file"id="file" type="file" size="10" onChange={handleChange} accept={fileDotType.join(",")} /><br /><font color="red">※如有上傳檔案請按【儲存】</font><br />
                                            {file != null && file.fileName ?
                                                <img src={file.imgBase} name="Std_img" height="110" />
                                                :
                                                imgBase ?
                                                    <img src={imgBase} name="Std_img" height="110" />
                                                    :
                                                    null
                                            }

                                        </div>


                                    </td>
                                    <td><input id="putButton" type="button" name="saveImg" onClick={saveDataAlert} value="儲存" /></td>
                                    <td><input type="button" value="刪除" onClick={delImg} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                        <div align="center" style={{ marginTop: "20px" }}>
                            <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
                        </div>

                    </div>

                </div>


                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBase} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}


export default ModifySpecialty;
