import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_Confirm_RegData
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';

function Second_Intro() {
    const history = useHistory();

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [UdocList, setUdocList] = useState([]); //使用者上傳文件清單
    const [Uphoto, setUphoto] = useState({}); //使用者證件照
    const [UComplete, setUComplete] = useState({}); //報名資料狀態
    const [PayState, setPayState] = useState({}); //繳費記錄
    const [UserList, setUserList] = useState({}); //使用者資料清單

    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {

                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                    }
                });
            });
        });

        getData();
    }, []);

	//取得資料
	function getData() {
		document.getElementById("loading").style.display = "block";
		API_Get_UpFile_getFrontSet({
			"FrontUrl": window.location.pathname,
			"FrontUrlCode": null
		}).then((response) => response.data)
			.then((data) => {
				if (!checkAuthToken(data)) {
					//window.location.href = "../";
				}
				//console.log(data);
				if (data.code == "0000") {
					console.log(data);
					data.result.map((obj, i) => {
						if (obj.frontUrlTextCode == "page") {
							setUdocMark_p(obj.frontUrlText)
						}
						if (obj.frontUrlTextCode == "top") {
							setUdocMark_t(obj.frontUrlText)
						}
						if (obj.frontUrlTextCode == "bottom") {
							setUdocMark_b(obj.frontUrlText)
						}
					})
					document.getElementById("loading").style.display = "none";
				}else if (data.code == "9998") {
					Swal.fire({
						icon: 'error',
						text: data.message,
					}).then((result) => {
						if (data.result && data.result.redirectUrl) {
							window.location.href = data.result.redirectUrl;
						} else {
							window.location.href = "/";
						}
					});
					document.getElementById("loading").style.display = "none";
				}
				else {
					Swal.fire({
						icon: 'error',
						text: data.message,
					})
				}
			})
			.catch(err => {
				console.log(err);
				if (err.response.status === 401 || err.response.data.code == "1001") {
					localStorage.clear();
					//history.push('/');
				}
			})


	}

	//下一步驟
	function Next(type = null) {
		//history.push(next);
		window.location.href = next;
		
	}

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />

				<div id="content">
					<div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
					<div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
					<div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
					<div align="center"><br />
						<input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
					</div>

                </div>
            </div>
        </>
    );
}


export default Second_Intro;
