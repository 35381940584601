import "../js/jquery.min.js";
import "../css/layout.css";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_POST_UpFile_GetEng,
    API_Get_UpFile_getUComplete,
    API_Get_UpFile_getFrontSet,
    API_PUT_UpFile_SaveEng,
    API_PUT_UpFile_DelFile
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';//console.log(upFileMail(UserData.userID));
import Swal from 'sweetalert2';

function modify_Engfile() {

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg", ".png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [userData, setUserData] = useState({});//資料容器
    const [fileList, setFileList] = useState([]);//資料容器
    const [imgBase, setImgBase] = useState();//伺服器取得圖片容器
    const [file, setFile] = useState([]);

    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    //非雙語類組者顯示文字
    const [nonNative, setNonNative] = useState("");

    //次要選項功能
    const [secItem, setSecItem] = useState({});
    const [secRadio, setSecRadio] = useState();
    const [BLvRadio, setBLvRadio] = useState();

    //svg
    const DelSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>)
    //svg

    useEffect(() => {
        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();
    }, []);

    //綁定投影片
    Fancybox.bind("[data-fancybox]", {

        Toolbar: {
            display: {
                left: ["infobar"],
                middle: [],
                right: ["close"],
            },
        },

    });

    //取得資料
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
               
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    //
                    //setUdocMark(data.result[0].frontUrlText);
                    getUComplete()
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/signup/modify_form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getData() {
        API_POST_UpFile_GetEng({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    setUserData(data.result.data);
                    if (data.result.data && data.result.data.file) {
                        setFileList(data.result.data.file)
                    } else {
                        setFileList([])
                    }
                    if (data.result.Item && Object.keys(data.result.Item).length > 0) {
                        setSecItem(data.result.Item)
                        let rad = [];
                        for (let opt of Object.keys(data.result.Item)) {
                            rad.push(data.result.Item[opt]);
                        }
                        setSecRadio(rad);
                    }
                    if (data.result.data && data.result.data.docName) {
                        setBLvRadio(data.result.data.docName);
                    } else {
                        setBLvRadio("");
                    }
                    document.getElementById("loading").style.display = "none";
                }
                else if (data.code == "3015") {//非雙語類組者
                    setNonNative(data.message);
                    Swal.fire({
                        icon: 'warning',
                        text: "報考雙語師資者，請勿上傳上傳英文能力檢定證書",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    //history.push('/');
                }
            })
    }

    //上傳檔案
    function handleChange(event) {
        document.getElementById("loading").style.display = "block";
        if (event.target.files.length > 0) {
            //console.log(event.target.files);
            let fileDoc = event.target.files[0];
            if (fileDoc.size > fileMaxSize) {
                event.target.value = "";
                document.getElementById("loading").style.display = "none";
                return
                (
                    Swal.fire({
                        icon: 'error',
                        text: "上傳檔案過大"
                    })
                )

            }
            if (!fileType.includes(fileDoc.type)) {
                event.target.value = "";
                document.getElementById("loading").style.display = "none";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "請選擇檔案格式 jpg 或 png"
                    })
                )
            }
            if (file.find(x => x.FileName == fileDoc.name)) {
                event.target.value = "";
                document.getElementById("loading").style.display = "none";
                return
                (
                    Swal.fire({
                        icon: 'error',
                        text: "已選擇相同檔名檔案"
                    })
                )
            }
            var reader = new FileReader();

            reader.readAsDataURL(fileDoc);
            reader.onload = function () {
                setFile([...file, { FileName: fileDoc.name, Base64String: reader.result }]);
                document.getElementById("loading").style.display = "none";
            }

        } else {
            document.getElementById("loading").style.display = "none";
        }
    }

    //Open彈窗
    function openModal() {
        console.log('aaa');
        var target = document.querySelector("#modal-showImg");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗
    function hideModal() {
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

    //儲存提示訊息
    function saveDataAlert() {
        Swal.fire({
            icon: 'warning',
            text: "請務必留意上傳文件之正確性及是否符合相關條件；倘於審查時發現資績證明文件有誤，該資績分數不予採計",
        }).then((result) => {

            saveImg();
        })

    }

    //儲存
    function saveImg() {
        if (document.getElementById("putButton") && !document.getElementById("putButton").disabled) {
            document.getElementById("putButton").disabled = true;
        } else {
            return null;
        }
        document.getElementById("loading").style.display = "block";
        if (!BLvRadio) {
            document.getElementById("loading").style.display = "none";
            document.getElementById("putButton").disabled = false;
            return
            (
                Swal.fire({
                    icon: 'error',
                    text: "選擇證書等級"
                })
            )
        }
        let returnJson = {
            "UserID": UserData.userID,
            "DocNo": uploadDoc,
            "File": file,
            "DocName": "",
            "UserEKID": UserData.userEKID
        };
        if (fileList && fileList.length > 0) {
            for (let pic of fileList) {
                if (pic.fileName && !pic.attDel) {
                    returnJson["File"].push({
                        "FileName": pic.fileName,
                        "Base64String": pic.base64String
                    })
                }
            }
        }

        if (secItem && Object.keys(secItem).length > 0) {
            for (let ie of Object.keys(secItem)) {
                if (secItem[ie] == BLvRadio) {
                    returnJson["DocName"] = ie;
                    returnJson["DocNo"] = ie;
                }
            }
        }

        if (!userData) {
            //returnJson["GATTID"] = "";
            //returnJson["UExDocID"] = "";
            //returnJson["DocRefFile"] = "";
        } else {
            returnJson["GATTID"] = userData['uExDocATTID'];
            returnJson["UExDocID"] = userData['uExDocID'];
            returnJson["DocRefFile"] = userData['docRefFile'];
        }

        API_PUT_UpFile_SaveEng(returnJson)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    setFile([]);
                    Swal.fire({
                        icon: 'success',
                        text: "儲存成功",
                    })
                    getData();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("putButton").disabled = false;
                document.getElementById("loading").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
                document.getElementById("loading").style.display = "none";
            })
    }

    //刪除
    function delImg() {
        if (!BLvRadio) { return null; }
        if (userData.uExDocATTID) {
            Swal.fire({
                text: "刪除圖片資料?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '確定',
                cancelButtonText: '取消'
            }).then((result) => {
                if (result.isConfirmed) {
                    API_PUT_UpFile_DelFile({
                        "UserID": UserData.userID,
                        "DocNo": userData.docNo,
                        "GATTID": userData.uExDocATTID,// --附件檔案流水號
                        "UExDocID": userData.uExDocID,
                        "UExDocATTID": userData.uExDocATTID,
                        "UserEKID": userData.userEKID
                    }).then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                window.location.href = "../";
                            }
                            console.log(data);
                            if (data.code == "0000") {
                                getData();

                            } else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                                document.getElementById("loading").style.display = "none";
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push('/');
                            }
                        })
                }
            })
        } 
    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //history.push(next);
    }

    //移除伺服器儲存圖片
    function removeSpic(target) {

        setFileList(
            fileList.map(x => {
                if (x.fileName == target) {
                    x.attDel = true;
                }
                return x;
            })
        );
    }
    //移除本次新增圖片
    function removePic(fileDoc) {
        setFile(file.filter((x) => x.FileName !== fileDoc));
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)", fontSize: "12px" }}>
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <td style={{ width: "325px" }} >上傳說明</td>
                                <td>瀏覽|選擇檔案</td>
                                <td style={{ width: "80px" }} >儲存</td>
                                <td style={{ width: "80px" }} >刪除</td>
                            </tr>
                        </thead>
                        {nonNative
                            ?
                            <tbody>
                                <tr>
                                    <td colSpan="4">{nonNative}</td>
                                </tr>
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div style={{ padding: "0 7px" }}>
                                                {secRadio == null || secRadio.length == 0 ? null :
                                                    secRadio.map((x, i) =>
                                                        <label key={i} htmlFor={"engBLv" + x} style={{ display: "block", padding: "2px 0" }} >
                                                            {x}<input id={"engBLv" + x} type="radio" name="engBLevel" checked={x == BLvRadio} onChange={() => setBLvRadio(x)} />
                                                        </label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </td>


                                    {!BLvRadio ? <td>請先選擇證書等級</td> :
                                        <td>
                                            {fileList && fileList.length > 0 ?
                                                <div>
                                                    <font color="blue">已上傳成功</font>
                                                    {/*<a onClick={() => openModal(i)} style={{ cursor: "pointer" }} ><font color="red">(預覽)</font></a>*/}
                                                </div>
                                                : null}
                                            <div>
                                                <input name="file" type="file" size="10" onChange={(e) => handleChange(e)} accept={fileDotType.join(",")} /><br />
                                                <span className="text-danger">※如有上傳檔案請按【儲存】</span><br />
                                                {(fileList && fileList.length > 0) || (file && file.length > 0) ?
                                                    <hr /> : null}
                                                {
                                                    fileList && fileList.length > 0 ?
                                                        <div className="modify_fancy_Div d-inline-flex" style={{ maxWidth: (file && file.length > 0 ? "50%" : "450px") }} >
                                                            {
                                                                fileList.map((y, i) =>
                                                                    y.attDel ?
                                                                        <div key={i} className="fancy_Item text-danger">儲存後<br />圖片刪除</div>
                                                                        :
                                                                        <div key={i} className="fancy_Item">
                                                                            <span className="fancy_Del_sapn d-flex" onClick={(e) => removeSpic(y.fileName)} ><DelSVG />刪除</span>
                                                                            <a href={y.base64String} data-fancybox="gallery-a" data-caption={y.fileName} >
                                                                                <img src={y.base64String} style={{ width: "100px" }} />
                                                                            </a>
                                                                        </div>

                                                                )}
                                                        </div>
                                                        : null
                                                }
                                                {file && file.length > 0 ?
                                                    <div className="d-inline-block" style={{ width: (fileList && fileList.length > 0 ? "50%" : "450px") }} >本次新增：
                                                        <div className="modify_fancy_Div d-flex">
                                                            {file.map((y2, i2) =>
                                                                <div key={i2} className="fancy_Item">
                                                                    <span className="fancy_Del_sapn d-flex" onClick={() => removePic(y2.FileName)} ><DelSVG />刪除</span>
                                                                    <a href={y2.Base64String} data-fancybox="gallery-new" data-caption={y2.FileName}>
                                                                        <img src={y2.Base64String} style={{ width: "100px" }} />
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    : null}

                                            </div>
                                        </td>
                                    }
                                    <td>
                                        <input id="putButton" type="button" value="儲存" onClick={saveDataAlert} style={{ margin: "0.5rem 0" }} />
                                    </td>
                                    <td>
                                        <input type="button" value="刪除" onClick={delImg} style={{ margin: "0.5rem 0" }} />
                                    </td>
                                </tr>
                            </tbody>
                        }

                    </table>
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    <div align="center"><br />
                        <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
                    </div>

                </div>
                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBase} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}


export default modify_Engfile;
