import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_POST_UpFile_GetDiploma,
    API_PUT_UpFile_SaveDiploma,
    API_PUT_UpFile_DelFile
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';
import Swal from 'sweetalert2';

function modify_EduFile() {
    const history = useHistory();
    
    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg", ".png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號

    //本頁為單張圖片,且有次要選項功能
    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [fileList, setFileList] = useState({});//資料容器
    const [imgBase, setImgBase] = useState({});//伺服器取得圖片容器
    const [imgBaseItem, setimgBaseItem] = useState({});//彈窗圖片容器
    const [file, setFile] = useState();
    //次要選項功能
    const [rendItem, setRendItem] = useState({});//渲染物件
    const [markT1, setMarkT1] = useState([]);//次要資料清單
    const [radioT1List, setRadioT1List] = useState([]);//次要資料可選項
    const [dataT1, setDataT1] = useState({});//次要資料

    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const [uploadSign, setUploadSign] = useState("");
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();

    }, []);

    //取得資料
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadSign(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/signup/modify_form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    function getData() {
        document.getElementById("loading").style.display = "block";
        API_POST_UpFile_GetDiploma({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data.code);
                if (data.code == "0000") {
                   
                    //console.log(data.result);
                    let radioTemp = [];
                    if (data.result.Item && Object.keys(data.result.Item).length > 0) {
                        console.log("item", data.result.Item);
                        setRendItem(data.result.Item);
                        console.log("markT1", data.result.Item.dT1.split(","));
                        setMarkT1(data.result.Item.dT1.split(","))
                        console.log("dT1List", data.result.Item.dT1List);
                        setRadioT1List(data.result.Item.dT1List);
                        radioTemp = data.result.Item.dT1List;
                    } else {
                        setRendItem({});
                        setMarkT1([]);
                        setRadioT1List([]);
                    }
                    console.log("data", data.result.data);
                    if (data.result.data && Object.keys(data.result.data).length > 0) {
                        setFileList(data.result.data);
                        //載入伺服器圖片
                        if (data.result.data.file.length > 0) {
                            console.log(data.result.data.file[0]);
                            setImgBase({ 0: data.result.data.file[0] });
                        } else {
                            setImgBase({})
                        }
                        //載入過去次要資料
                        if (data.result.data.dT1List && data.result.data.dT1List.length > 0 && radioTemp.length > 0) {
                            let teSqu = new Object();
                            data.result.data.dT1List.filter(x => {
                                radioTemp.filter(x1 => {
                                    if (x.rNo == x1.rNo) {
                                        teSqu[x1.rField] = x1.rNo;
                                    }
                                })
                            })
                            console.log(teSqu);
                            setDataT1(teSqu)
                        } else {
                            setDataT1({});
                        }
                    } else {
                        setFileList([]);
                        setImgBase({});
                        setDataT1({});
                    }
                    document.getElementById("loading").style.display = "none";
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
                document.getElementById("loading").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
                document.getElementById("loading").style.display = "none";
            })
    }

    //上傳檔案
    function handleChange(index, event) {
        document.getElementById("loading").style.display = "block";
        console.log(index);
        if (event.target.files.length > 0) {
            let fileDoc = event.target.files[0]
            console.log(fileDoc);

            if (fileDoc.size > fileMaxSize) {
                event.target.value = "";
                document.getElementById("loading").style.display = "none";
                return (Swal.fire({
                    icon: 'error',
                    text: "上傳檔案過大",
                }))
            }
            if (!fileType.includes(fileDoc.type)) {
                event.target.value = "";
                document.getElementById("loading").style.display = "none";
                return (Swal.fire({
                    icon: 'error',
                    text: "請選擇檔案格式 jpg 或 png",
                }))
            }
            setFile(fileDoc);
            var reader = new FileReader();

            reader.readAsDataURL(fileDoc);
            reader.onload = function () {
                var list = {};
                if (index == 0) {
                    setImgBase({ [index]: reader.result });
                } else {
                    setImgBase({ ...imgBase, [index]: { "fileName": fileDoc.name, "base64String": reader.result } });
                }
                document.getElementById("loading").style.display = "none";
            }
            //document.querySelector(".viewImg" + index).classList.remove('hide');
        } else {
            document.getElementById("loading").style.display = "none";
        }
    }

    //Open彈窗
    function openModal(index) {
        setimgBaseItem(imgBase[index].base64String);
        var target = document.querySelector("#modal-showImg");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗
    function hideModal() {
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

    //儲存提示訊息
    function saveDataAlert(index) {
        Swal.fire({
            icon: 'warning',
            text: "請務必留意上傳文件之正確性及是否符合相關條件；倘於審查時發現資績證明文件有誤，該資績分數不予採計",
        }).then((result) => {

            saveImg(index);
        })

    }

    //儲存
    function saveImg(index) {
        if (document.getElementById("putButton") && !document.getElementById("putButton").disabled) {
            document.getElementById("putButton").disabled = true;
        } else {
            return null;
        }
        document.getElementById("loading").style.display = "block";
        let aqr = "";
        try {
            aqr = markT1.map(x => radioT1List.find(x1 => x1.rField == x && x1.rNo == dataT1[x])['rName']).join(",");
        } catch (e) {
            document.getElementById("loading").style.display = "none";
            document.getElementById("putButton").disabled = false;
            return (Swal.fire({
                icon: 'error',
                text: "選擇最高學歷",
            }))
        }
        if (!aqr) {
            document.getElementById("loading").style.display = "none";
            document.getElementById("putButton").disabled = false;
            return (Swal.fire({
                icon: 'error',
                text: "選擇學歷資料",
            }))  }
        if (imgBase[1] || imgBase[0]) { }
        let updateFile = false;
        let putJSON = {
            "UserID": UserData.userID,
            "DocNo": uploadSign,
            "DocName": fileList.docName,
            "GATTID": fileList.uExDocATTID,// --附件檔案流水號
            "ATTFCaption": uploadSign,
            "UserEKID": UserData.userEKID,// --使用考報考類組序號
            "UExDocID": fileList.uExDocID,// --使用者文件流水號
            "DocRefFile": fileList.docRefFile,// --Y // N
            "UExDocMark1": markT1.map(x => radioT1List.find(x1 => x1.rField == x && x1.rNo == dataT1[x])['rName']).join(","),
            "File": null
        }
        //更新圖片
        if (imgBase[1] && imgBase[1].fileName) {
            putJSON.File = [
                {
                    "FileName": (imgBase[1].fileName ? imgBase[1].fileName : uploadDoc + "undefindedFile.jpg"),
                    "Base64String": imgBase[1].base64String
                }
            ]
            updateFile = true;
        } else if (imgBase[0] && imgBase[0].fileName) {
            putJSON.File = [
                {
                    "FileName": imgBase[0].fileName,
                    "Base64String": imgBase[0].base64String
                }
            ]
        }
        //console.log(putJSON)
        
        API_PUT_UpFile_SaveDiploma(putJSON).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    imgBase[index] = "";
                    getData();
                    if (updateFile) {
                        Swal.fire({
                            icon: 'success',
                            text: "儲存成功",
                        })
                        upFileMail(UserData.userID);
                    }
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })

                }
                document.getElementById("loading").style.display = "none";
                document.getElementById("putButton").disabled = false;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
                document.getElementById("loading").style.display = "none";
            })
    }

    //刪除
    function delImg(index) {

        if (fileList.uExDocATTID) {
            Swal.fire({
                text: "刪除圖片資料?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: '確定',
                cancelButtonText: '取消'
            }).then((result) => {
                if (result.isConfirmed) {

            API_PUT_UpFile_DelFile({
                "UserID": UserData.userID,
                "DocNo": fileList.docNo,
                "UExDocID": fileList.uExDocID,
                "GATTID": fileList.uExDocATTID,// --附件檔案流水號
            }).then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        window.location.href = "../";
                    }
                    console.log(data);
                    if (data.code == "0000") {
                        getData();
                        console.log(upFileMail(UserData.userID));
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                        document.getElementById("loading").style.display = "none";
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })


                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                })
                }
            })
        } 

    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //history.push(next);
    }

    //次選項功能 編輯
    function radioChange(e, vRad) {
        setDataT1(prevState => ({
            ...prevState,
            [vRad]: e,
            isEdit: true
        }));
    }

    return (
        <>
            <div id="master-main-bg">

                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    
                    <div style={{ fontSize: "12px" }}>
                        <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                            <thead>
                                <tr>
                                    <td>最高學歷</td>
                                    <td>瀏覽|選擇檔案</td>
                                    <td>儲存</td>
                                    <td>刪除</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {markT1 == null || markT1.length == 0 ? "(無選項)" :
                                            markT1.map((x, i) => (
                                                <div key={i} >
                                                    {radioT1List == null || radioT1List.length == 0 ? "(無有效選項)" :
                                                        <>
                                                            {console.log("檢查", radioT1List)}
                                                            {radioT1List.map((x1, i1) => (x1.rField != x ? null :
                                                                <label key={i + i1} >
                                                                    { console.log("i",i ,i1) }
                                                                    <input type="radio" value={x1.rNo} name={x1.rField} checked={dataT1[x1.rField] == x1.rNo} onChange={(e) => radioChange(x1.rNo, x1.rField)} />
                                                                    {x1.rName}
                                                                    {i1 === 1 && <hr style={{ border: '0', borderTop: '2px dashed #a2a9b6'} }></hr>}
                                                                </label>
                                                            ))}
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                    </td>
                                    <td>
                                        <div>
                                            {imgBase == null || imgBase[0] == null || Object.keys(imgBase[0]).length == 0 ?
                                                null
                                                :
                                                <span>
                                                    <font color="blue">已上傳成功</font>
                                                    <a href="#" onClick={(e) => openModal(0)} ><font color="red">(預覽)</font></a>
                                                </span>
                                            }
                                        </div>
                                        <div>
                                            <input type="button" value="選擇檔案" onClick={(e) => e.target.nextElementSibling.click()} style={{ margin: "0.5rem 0" }} />
                                            <input name="file" type="file" size="10" onChange={(e) => handleChange(1, e)} accept={fileDotType.join(",")} style={{ display: "none" }} />
                                            <font color="red">※如有上傳檔案請按【儲存】</font><br />
                                            {imgBase == null || Object.keys(imgBase).length == 0 ? null :
                                                imgBase[1] != null && imgBase[1].fileName ?
                                                    <img src={imgBase[1].base64String} alt={imgBase[1].fileName} height="110" />
                                                    :
                                                    imgBase[0] && imgBase[0].fileName?
                                                        <img src={imgBase[0].base64String} alt={imgBase[0].fileName} height="110" /> :
                                                        null
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <input id="putButton" name="button" type="button" name="savePic" value="儲存" onClick={(e) => saveDataAlert(1)} />
                                    </td>
                                    <td>
                                        <input className="color-3" name="button" type="button" name="removePic" value="刪除" onClick={(e) => delImg(1)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    <div align="center"><br />
                        <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
                    </div>

                </div>

                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBaseItem} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}


export default modify_EduFile;
