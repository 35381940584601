import "../js/jquery.min.js"; 
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_HOST,API_Get_Front_getNews } from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import Swal from 'sweetalert2';

function News() {
    const history = useHistory();

    const [dataList, setDataList] = useState([]); //文件列表

    useEffect(() => {
        getData();
    }, []);

    //取得資料
    function getData() {
        document.getElementById("loading").style.display = "block";
        API_Get_Front_getNews().then((response) => response.data)
            .then((data) => {


                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="最新消息" modalSide="最新消息" modalUrl={window.location.pathname} />

                <div id="content">
                    <div id="main">
                        <table className="datatable">
                            <tbody>
                                <tr>
                                    <th width="40">序號</th>
                                    <th>&nbsp;內容</th>
                                    <th width="100">日期</th>
                                </tr>
                               
                                {dataList.map((x, i) => (
                                    <tr>
                                        <td width="40">{i + 1}</td>
                                        <td>
                                            <div dangerouslySetInnerHTML={{ __html: x.msgData }}></div>
                                           
                                                {(x.fileData.length > 0 ? (
                                                    x.fileData.map((y, i2) => (
                                                        <div>下載檔案：<a href={`${API_HOST}/${y.fileUrl}`}>{y.fileName}</a></div>
                                                        ))
                                                ) : (
                                                        <span></span>
                                                        ))}
                                            
                                        </td>
                                        <td width="100">{x.msgPushDate.slice(0, 10)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    );
}


export default News;

