import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_Carousel } from '../global/constants';
import LeftSideW from '../components/partial/leftSide';

function Second_Aff_Signed() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    useEffect(() => {

    }, []);
    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">
                    <h1>修改切結書及同意書</h1>
                    <div >
                        <font color="blue" size="3" >

                        </font>
                        <br />
                        <font color="red">

                        </font>
                    </div>

                    <div id="status">

                    </div>

                    <div id="main">
                        <div>
                            主要內容
                        </div>
                        <div align="center">
                            <input name="button" type="button" onClick={() => (location.href = 'Ambition')} value="下一步" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Second_Aff_Signed;
