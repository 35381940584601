import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_Front_KindList,
    API_Get_RTModify_RTModifyget,
    API_Put_Modify_RTModifyput
} from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import LeftSideW from '../components/partial/leftSide';

import TWzipcode from 'react-twzipcode';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';

function SecondForm() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const date = new Date();
    const birYStar = date.getFullYear() - 1911 - 65; //生日年起迄
    const [rocDate, setRocDate] = useState("");

    const [KindArrayList, setKindArrayList] = useState([{}]); //報考類科下拉
    const [ExKindNo, setExKindNo] = useState(UserData.exKindNo); //報考類科

    const [resumeList, setResumeList] = useState([]); //經歷清單
    const [UdocList, setUdocList] = useState([]); //使用者上傳文件清單
    const [UserList, setUserList] = useState({}); //使用者資料清單

    const [photo, setPhoto] = useState("../images/photo-sample.gif");
    const [birthY, setbirthY] = useState(""); //生日-年
    const [birthM, setbirthM] = useState(""); //生日-月
    const [birthD, setbirthD] = useState(""); //生日-日

    const [CityName, setCityName] = useState(""); //住址-城市
    const [AreaName, setAreaName] = useState(""); //住址-地區
    const [AddrZIP, setAddrZIP] = useState(""); //住址-郵遞區號

    const [Add, setAdd] = useState({ "county": "基隆市", "district": "仁愛區", "zipcode": "200" }); //住址-郵遞區號

    const [filtered, setfiltered] = useState([]); //文件列表
    const [filteredd, setfilteredd] = useState([]); //文件列表


    const [Jobchk, setJobchk] = useState("0"); //現職 勾選
    const [C001chk, setC001chk] = useState(""); //c001 文件 勾選
    const [C001chkT, setC001chkT] = useState(""); //c001 文件 原因

    const [resumeSave, setResumeSave] = useState([]); //經歷清單
    const [next, setnext] = useState(""); //下一步
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [Complete, setComplete] = useState(""); //是否為填寫完成
    const areatId = [];//跳轉ID

    useEffect(() => {
        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        console.log(obj3);
                        setnext(obj3.NextUrl);
                    }
                });
            });
        });

        getFrontSet();
    }, []);

    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/";
                }
                if (data.code == "0000") {

                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })

                    getData();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }



            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }


    //取得是否報名表填寫完成
    function getData() {

        API_Get_RTModify_RTModifyget({
            "UserEKID": UserData.userEKID
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/"
                }
                if (data.code == "0000") {
                    console.log(data);
                    setExKindNo(data.result.User[0].ExKindNo);
                    setUdocList(data.result.data.docList);
                    setUserList(data.result.User[0]);
                    setComplete(data.result.User[0].UComplete);

                    var len = 3 - parseInt(data.result.data.resumeList.length, 10); //經歷
                    if (data.result.data.resumeList.length != 0) {
                        data.result.data.resumeList.map((item, i) => {
                            if (item.UserRStartDate != null) {
                                item.resumeYs = item.UserRStartDate.slice(0, 4) - 1911;
                                item.resumeMs = parseInt(item.UserRStartDate.slice(5, 7), 10);
                                item.resumeDs = parseInt(item.UserRStartDate.slice(8, 10), 10);
                            }
                            if (item.UserREndDate != null) {
                                item.resumeYe = item.UserREndDate.slice(0, 4) - 1911;
                                item.resumeMe = parseInt(item.UserREndDate.slice(5, 7), 10);
                                item.resumeDe = parseInt(item.UserREndDate.slice(8, 10), 10);
                            }
                        })
                        setResumeList(data.result.data.resumeList);
                    }

                    for (let i = 1; i <= len; i++) {
                        setResumeList(resumeList => [...resumeList, {
                            "UserRID": null,
                            "UserEKID": null,
                            "UserID": null,
                            "UserROrgName": null,
                            "UserRJobName": null,
                            "UserRStartDate": null,
                            "UserREndDate": null,
                            "UserROrd": null,
                            "CreateDate": null,
                            "CreateIP": null,
                            "resumeYs": "",
                            "resumeMs": "",
                            "resumeDs": "",
                            "resumeYe": "",
                            "resumeMe": "",
                            "resumeDe": ""

                        }]);
                    }


                    if (data.result.User[0].UBirDay != null) { //生日
                        console.log(data.result.User[0].UBirDay.slice(5, 7));
                        setbirthY(parseInt((data.result.User[0].UBirDay.slice(0, 4)) - 1911));
                        setbirthM(parseInt(data.result.User[0].UBirDay.slice(5, 7)));
                        setbirthD(parseInt(data.result.User[0].UBirDay.slice(8, 10)));
                    }

                    if (data.result.User[0].UPJobSch != null && data.result.User[0].UPJobSch != "") {  //現職 setJobchk(e);
                        setJobchk("1");
                    }

                    if (data.result.data.photo.length > 0) { //證件照
                        if (data.result.data.photo[0].file.length > 0) { //證件照
                            setPhoto(data.result.data.photo[0].file[0].base64String);
                        }
                    }

                    if (data.result.User[0].UCityName != "") {
                        setCityName(data.result.User[0].UCityName);
                    } else {
                        setCityName("基隆市");
                    }
                    if (data.result.User[0].UCityName != "") {
                        setAreaName(data.result.User[0].UAreaName);
                    } else {
                        setAreaName("仁愛區");
                    }
                    if (data.result.User[0].UCityName != "") {
                        setAddrZIP(data.result.User[0].UAddrZIP);
                    } else {
                        setAddrZIP("200");
                    }
                    if (data.result.User[0].UCityName == null && data.result.User[0].UAreaName == null && data.result.User[0].UAddrZIP == null) {
                        set("Address", Add);
                    }
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("loading").style.display = "none";
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });


        API_Get_Front_KindList({
            "ExType": ExKindNo.substr(0, 1)
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);

                    setKindArrayList(data.result.kind);
                    //setExKindNo(UserData.exKindNo);

                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    //setKindArrayList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
  
    //radio btn
    function chk(radioName, tagName) {

        var chkList = document.querySelectorAll("input[name='" + tagName + "']");
        chkList.forEach((element) => {

            if (element.checked == true) {
                set(radioName, element.value);

            }
        });
    }

    //生日
    function chk_birth(birth, birthName) {
        if (birth != "") {
            if (birthName == "birthY") {
                setbirthY(birth);
            } else if (birthName == "birthM") {
                setbirthM(birth);
            } else if (birthName == "birthD") {
                setbirthD(birth);
            }
            if (birthName == "birthY" && birthM != "" && birthD != "") {
                var birDay = (parseInt(birth, 10) + 1911) + "-" + autoSupplement(parseInt(birthM)) + "-" + autoSupplement(parseInt(birthD))
                set("UBirDay", birDay);
            } else if (birthName == "birthM" && birthY != "" && birthD != "") {
                var birDay = (parseInt(birthY, 10) + 1911) + "-" + autoSupplement(parseInt(birth)) + "-" + autoSupplement(parseInt(birthD))
                set("UBirDay", birDay);
            } else if (birthName == "birthD" && birthY != "" && birthM != "") {
                var birDay = (parseInt(birthY, 10) + 1911) + "-" + autoSupplement(parseInt(birthM)) + "-" + autoSupplement(parseInt(birth))
                console.log(birDay);
                set("UBirDay", birDay);
            }
        }
    }

    //現職
    function chk_show2(e) {
        setJobchk(e);

        if (e == "1") {
            document.querySelector("#teach_span").classList.remove("hide");
        } else {
            document.querySelector("#teach_span").classList.add("hide");
            set("UPJobSch", "");
        }
    }

    //地址
    function handleChange(data) {
        console.log(data);
        console.log(CityName);
        if (CityName != "" && AreaName != "" && AddrZIP != "") {
            set("Address", data);
        }
    }


    //編輯基本資料
    function set(objName, e) {

        if (objName == "Address") {
            var updateUserList = {
                ...UserList,
                UCityName: e.county,
                UAreaName: e.district,
                UAddrZIP: e.zipcode
            };
       
        } else {
            var updateUserList = { ...UserList, [objName]: e };
        }
        setUserList(updateUserList);
        //console.log(updateUserList);
    }

    //編輯經歷資料
    function setResume(index, e, objName) {

        const upList = resumeList.map((obj, i) => {
            if (i == index) {

                return { ...obj, [objName]: e };
            }
            return obj;
        });
        setResumeList(upList);
    }

    //檢查資料
    function chkData() {
        var result = true;
        const requiredlist = document.querySelectorAll(".required");
        requiredlist.forEach((element) => {

            if (element.type == "text" && element.name != "Teach_sch" && element.name != "phone") { //其它文字輸入框
                if (element.value == "") {
                    element.classList.add("error");
                    areatId.push(element.id);
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            } else if (element.type == "text" && element.name == "Teach_sch" && Jobchk == "1") { //現職有勾選 文字輸入框為必填
                if (element.value == "") {
                    element.classList.add("error");;
                    areatId.push(element.id);
                    result = false;
                } else {
                    element.classList.remove("error");
                }
            } else if (element.type == "text" && element.name == "phone") { //手機

                if (element.value == "") {
                    element.classList.add("error");
                    areatId.push(element.id);
                    result = false;
                } else {

                    element.classList.remove("error");

                    //手機格式
                    var rules = /^09\d{2}-\d{6}$/;

                    if (!rules.test(element.value)) {
                        document.querySelector("[id='" + element.name + "_" + element.id + "']").classList.remove("hide");
                        result = false;
                        areatId.push(element.name + "_" + element.id);
                    } else {
                        document.querySelector("[id='" + element.name + "_" + element.id + "']").classList.add("hide");
                    }
                }

            } else if (element.type == "radio") { //單選框

                const radiolist = document.querySelectorAll("[name='" + element.name + "']");
                var chk = "";
                radiolist.forEach((element2) => {

                    if (element2.checked != "") {
                        chk = element2.checked;
                    }
                });
                if (chk == "") {
                    document.querySelector("[id='" + element.name + "']").classList.remove("hide");
                    result = false;
                    areatId.push(element.name);
                } else {
                    document.querySelector("[id='" + element.name + "']").classList.add("hide");
                }
            } else if (element.name != "Teach_sch") { //下拉選單

                const selectlist = document.querySelectorAll("[name='" + element.name + "']");
                var chk = true;
                selectlist.forEach((element2) => {

                    if (element2.value == "") {
                        chk = false;
                    }
                });
                if (!chk) {
                    document.querySelector("[id='" + element.name + "']").classList.remove("hide");
                    result = false;
                    areatId.push(element.name);
                } else {
                    document.querySelector("[id='" + element.name + "']").classList.add("hide");
                }
            }
        });

        return result;
    }

    //數字補0
    function autoSupplement(val) {
        var n = String(val);
        return n.padStart(2, '0')

    }

    //下一步驟
    function Next() {
        if (UserList.UserDataLock == '1') {
            Swal.fire({
                icon: 'warning',
                text: "已下載報名表，無法變更報名表。",
            }).then((result) => {

                window.location.href = next;
            })

           
        } else {

       
        areatId.length = 0;

        var chk = chkData();
        var resumeSave = [];
        var docSave = [];

        resumeList.forEach((obj, i) => {
            var item = {
                "UserEKID": UserData.userEKID,
                "UserROrgName": resumeList[i].UserROrgName,
                "UserRJobName": resumeList[i].UserRJobName,
                "UserRStartDate": (resumeList[i].resumeYs != null && resumeList[i].resumeYs != "" ? (parseInt(resumeList[i].resumeYs, 10) + 1911) + "-" + autoSupplement(parseInt(resumeList[i].resumeMs)) + "-" + autoSupplement(parseInt(resumeList[i].resumeDs)) : null),
                "UserREndDate": (resumeList[i].resumeYe != null && resumeList[i].resumeYe != "" ? (parseInt(resumeList[i].resumeYe, 10) + 1911) + "-" + autoSupplement(parseInt(resumeList[i].resumeMe)) + "-" + autoSupplement(parseInt(resumeList[i].resumeDe)) : null),
            };
            resumeSave.push(item);
        });

        console.log("UserList");
        console.log(UserList);
        console.log("----------------------");
        console.log("resumeSave");
        console.log(resumeSave);
        console.log("----------------------");
      
        //chk = false;
        if (chk) {
            document.getElementById("loading").style.display = "block";
            API_Put_Modify_RTModifyput({
                "UserID": UserList.UserID,
                "UName": UserList.UName,
                "USex": UserList.USex,
                "UBirDay": UserList.UBirDay,
                "UAddrZIP": UserList.UAddrZIP,
                "UCityName": UserList.UCityName,
                "UAreaName": UserList.UAreaName,
                "UAddrs": UserList.UAddrs,
                "UTelO": UserList.UTelO,
                "UTelH": UserList.UTelH,
                "UPhone": UserList.UPhone,
                "UTeaEduSch": UserList.UTeaEduSch,
                "UPJobSch": UserList.UPJobSch,
                "UComplete": (chk ? true : false),
                "userResumes": resumeSave
            }).then((response) => response.data)
                .then((data) => {
                    if (!checkAuthToken(data)) {
                        window.location.href = "/";
                    }
                    console.log(data);
                    if (data.code == "0000") {
                        //console.log(next);
                        //history.push(next);
                        window.location.href = next;
                    } else if (data.code == "9998") {
                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        }).then((result) => {
                            if (data.result && data.result.redirectUrl) {
                                window.location.href = data.result.redirectUrl;
                            } else {
                                window.location.href = "/";
                            }
                        });
                    }
                    else {

                        Swal.fire({
                            icon: 'error',
                            text: data.message,
                        })
                    }
                    document.getElementById("loading").style.display = "none";
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401 || err.response.data.code == "1001") {
                        localStorage.clear();
                        history.push('/');
                    }
                    document.getElementById("loading").style.display = "none";
                })
        } else {
            Swal.fire({
                icon: 'error',
                text: "報名表尚未填寫完整，請重新確認",
            }).then((result) => {
                document.getElementById("loading").style.display = "block";
                setTimeout(() => {
                    console.log("Delayed for 1 second.");
                    document.querySelector("#" + areatId[0]).scrollIntoView(true);
                }, "500");

                API_Put_Modify_RTModifyput({
                    "UserID": UserList.UserID,
                    "UName": UserList.UName,
                    "USex": UserList.USex,
                    "UBirDay": UserList.UBirDay,
                    "UAddrZIP": UserList.UAddrZIP,
                    "UCityName": UserList.UCityName,
                    "UAreaName": UserList.UAreaName,
                    "UAddrs": UserList.UAddrs,
                    "UTelO": UserList.UTelO,
                    "UTelH": UserList.UTelH,
                    "UPhone": UserList.UPhone,
                    "UTeaEduSch": UserList.UTeaEduSch,
                    "UPJobSch": UserList.UPJobSch,
                    "UComplete": false,
                    "userResumes": resumeSave
                }).then((response) => response.data)
                    .then((data) => {
                        if (!checkAuthToken(data)) {
                            window.location.href = "/";
                        }
                        console.log(data);
                        if (data.code == "0000") {
                            console.log('dddd');
                            console.log(next);
                            //window.location.href = next;
        //history.push(next);
                        }
                        else {

                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            })
                        }
                        document.getElementById("loading").style.display = "none";
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push('/');
                        }
                        document.getElementById("loading").style.display = "none";
                    })
            })
            }
        }
    }


    return (
        <>
            <div id="master-main-bg">

                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />

                <div id="content">

                    <div id="main">

                        <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                        <br />

                        <table width="100%" border="0" cellPadding="5" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td width="5%">類科：</td>
                                    <td width="50%">
                                        {
                                            KindArrayList.length > 0 && ExKindNo ?
                                                KindArrayList.find(y => y.exKindNo == ExKindNo) ?
                                                    KindArrayList.find(y => y.exKindNo == ExKindNo).exKindName
                                                    : null
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="datatable">
                            <tbody>
                                <tr>
                                    <td width="10%">姓名<span className="color-3">＊</span></td>
                                    <td colSpan="2"><input className="required" id="UName" name="textfield" type="text" size="15" value={UserList.UName} onChange={(e) => { set("UName", e.target.value) }} /><br />(若您的姓名有特殊造字，或無法鍵入的中文字，煩請填入小寫英文「x」字母)</td>

                                    <td width="20%" colSpan="2" rowSpan="4">
                                        <div align="center">
                                            <img src={photo} width="105" height="140" /><br />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="100">性別<span className="color-3">＊</span></td>
                                    <td colSpan="2">

                                        <input className="required" onClick={(e) => chk("USex", "sex")} type="radio" value="M" name="sex" checked={UserList.USex === 'M'} />
                                        男
                                        <input className="required" onClick={(e) => chk("USex", "sex")} type="radio" value="F" name="sex" checked={UserList.USex === 'F'} />
                                        女 <span id="sex" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【性別】</span></td>
                                </tr>
                                <tr>
                                    <td>身分證字號：</td>
                                    <td colSpan="2">{UserList.UIDNo}</td>
                                </tr>
                                <tr>
                                    <td>出生日期<span className="color-3">＊</span></td>
                                    <td colSpan="2">
                                        民國
                                        <select className="required" id="birthY" name="birth" value={birthY} onChange={(e) => { chk_birth(e.target.value, "birthY") }}>
                                            <option value="" selected="selected">請選擇...</option>
                                            {[...new Array(46)].map((value, i) => (
                                                <option key={i} value={i + birYStar}>{i + birYStar}</option>
                                            ))}
                                        </select>
                                        年
                                        <select className="required" id="birthM" name="birth" value={birthM} onChange={(e) => { chk_birth(e.target.value, "birthM") }}>
                                            <option value="" selected="selected">請選擇...</option>
                                            {[...new Array(12)].map((value, i) => (
                                                <option key={i} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                        月
                                        <select className="required" id="birthD" name="birth" value={birthD} onChange={(e) => { chk_birth(e.target.value, "birthD") }}>
                                            <option value="" selected="selected">請選擇...</option>
                                            {[...new Array(31)].map((value, i) => (
                                                <option key={i} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                        日<span id="birth" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請輸入【出生日期】</span><br />
                                        <p>(<span className="color-3">限制</span>：具有中華民國國籍之國民，未達「公立學校教職員退休資遣撫卹條例」規定之屆齡退休條件，無教師法第19條情事及無教育人員任用條例第31條、第33條規定情事者，始得報考。)<br />(僅限民國45年08月01日(含)以後出生，才能參加報名。)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>現職</td>
                                    <td colSpan="3">
                                        <input id="Teach_subj" onClick={(e) => chk_show2(e.target.value)} type="checkbox" value={Jobchk == "0" ? "1" : "0"} name="Teach_subj" checked={Jobchk == "1"} />
                                        是否為現職教師 (若您為現職教師，請於勾選後填寫現職服務學校(含縣市))<br />
                                        <span id="teach_span" className={Jobchk == "0" || Jobchk == "" ? "hide" : ""}> 請填入現職服務學校：<input className="required" id="Teach_sch" type="text" name="Teach_sch" value={UserList.UPJobSch} onChange={(e) => { set("UPJobSch", e.target.value) }} /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>住址<span className="color-3">＊</span></td>
                                    <td colSpan="3">
                                        <TWzipcode className="required" css={['county-sel', 'district-sel', 'zipcode']} handleChangeCounty={handleChange} handleChangeDistrict={handleChange} handleChangeZipcode={handleChange}
                                            zipcodeValue={UserList.UAddrZIP} countyValue={UserList.UCityName} districtValu={UserList.UAreaName} />
                                        <input type="text" id="addr" name="addr" className="required Ariel" size="32" maxLength="100" value={UserList.UAddrs} onChange={(e) => { set("UAddrs", e.target.value) }} />
                                        門牌街道
                                    </td>
                                </tr>
                                <tr>
                                    <td>電話</td>
                                    <td colSpan="3"><span className="datatable-bg1">( O )：
                                        <input name="phone" type="text" className="Ariel" value={UserList.UTelO} id="tel1" size="20" maxLength="30" onChange={(e) => { set("UTelO", e.target.value) }} />
                                        <span className="sign">(ex: 02-22187777#分機)</span> {/*<span id="phone_tel1" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>【電話格式錯誤】</span>*/}</span><br />
                                        ( H )：
                                        <input name="phone" type="text" className="Ariel" value={UserList.UTelH} id="tel2" size="20" maxLength="30" onChange={(e) => { set("UTelH", e.target.value) }} />
                                        <span className="sign">(ex:02-22187777#分機)</span>{/*<span id="phone_tel2" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>【電話格式錯誤】</span>*/}<span> </span><br />
                                        <span className="font">手機<span className="color-3">＊</span>：</span>
                                        <input name="phone" type="text" className="Ariel required" value={UserList.UPhone} id="Smphone2" size="20" maxLength="30" onChange={(e) => { set("UPhone", e.target.value) }} />
                                        <span className="sign">(ex:0912-123456)</span><span id="phone_Smphone2" className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>【手機格式錯誤】</span><span></span></td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td colSpan="3">
                                        <input name="email" type="text" id="email" value={UserList.UEmail} disabled={UserList.UEmail != null ? "disabled" : ""} size="30" maxLength="100" />建議填入E-mail，以利日後寄送資料之用
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        師資培育課程修畢學校<span className="color-3">＊</span>
                                    </td>
                                    <td colSpan="3">
                                        <p>校名：<input className="required" name="Tc_GrdSch" type="text" id="Tc_GrdSch" maxLength="40" value={UserList.UTeaEduSch} onChange={(e) => { set("UTeaEduSch", e.target.value) }} /></p>
                                        <span className="color-3">請填寫校名全銜</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        <table className="datatable">
                            <tbody>
                                
                                <tr>
                                    <td width="10%" rowSpan="4" align="center" className="datatable-bg2">經歷<br />
                                        <br />      (請依序由上而下填)</td>
                                </tr>

                                {resumeList.map((x, index) => (
                                    <tr key={index} >
                                        <td colSpan="4" valign="top" className="datatable-bg2">機關（學校）：
                                            <input name="exp_school1" type="text" maxLength="10" size="18" value={x.UserROrgName} onChange={(e) => { setResume(index, e.target.value, "UserROrgName") }} />
                                            （職稱）：
                                            <input name="exp_sen1" type="text" size="10" value={x.UserRJobName} onChange={(e) => { setResume(index, e.target.value, "UserRJobName") }} />
                                            <br />
                                            服務期間：

                                            <select id={`resumeYs${index + 1}`} name={`resumes${index + 1}`} value={x.resumeYs} onChange={(e) => { setResume(index, e.target.value, "resumeYs") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(66)].map((value, j) => (
                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                ))}
                                            </select>
                                            年
                                            <select id={`resumeMs ${index + 1}`} name={`resumes${index + 1}`} value={x.resumeMs} onChange={(e) => { setResume(index, e.target.value, "resumeMs") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(12)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            月
                                            <select id={`resumeDs ${index + 1}`} name={`resumes${index + 1}`} value={x.resumeDs} onChange={(e) => { setResume(index, e.target.value, "resumeDs") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(31)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            日&nbsp;至&nbsp;
                                            <select id={`resumeYe${index + 1}`} name={`resumee${index + 1}`} value={x.resumeYe} onChange={(e) => { setResume(index, e.target.value, "resumeYe") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(66)].map((value, j) => (
                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                ))}
                                            </select>
                                            年
                                            <select id={`resumeMe ${index + 1}`} name={`resumee${index + 1}`} value={x.resumeMe} onChange={(e) => { setResume(index, e.target.value, "resumeMe") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(12)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            月
                                            <select id={`resumeDe ${index + 1}`} name={`resumee${index + 1}`} value={x.resumeDe} onChange={(e) => { setResume(index, e.target.value, "resumeDe") }}>
                                                <option value="" selected="selected">請選擇...</option>
                                                {[...new Array(31)].map((value, j) => (
                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                ))}
                                            </select>
                                            日
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td><font color="#CC0066">(修改資料後，務必按「下一步」，以完成修改動作)</font></td>
                                </tr>
                            </tbody>
                        </table>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                        <div align="center">
                            <input type="button" value="下一步" name="sub" onClick={(e) => Next()} style={{ width: "80px", margin: "0.4rem 1rem" }} />
                        </div>

                    </div>

                </div>

            </div>
        </>
    );
}

export default SecondForm;
