import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import { pageLis } from "../../global/componentDom";

function LeftSideW(props) {
    /*modalTitle測欄標題,modalSide類型,modalURL本頁所屬*/
    const [modalUrl, setModalUrl] = useState("");
    const [titleAry, setTitleAry] = useState([]);
    const [takeCap, setTakeCap] = useState(props.modalSide);
    const [menuList, setMenuList] = useState([]);

    useEffect(() => {
        if (takeCap === null) {
            setTitleAry([])
        } else if (props.modalTitle) {
            setTitleAry(props.modalTitle.split(''))
        } else {
            setTitleAry(["報", "名", "系", "統"]);
        }
        if (props.modalUrl) {
            setModalUrl(props.modalUrl.toLowerCase());
        }
        //console.log("setMenu")
        //1. set menu
        if (localStorage.getItem("isLogin") == "1") {
            //console.log("isLogin")
            let mudb = localStorage.getItem('menuList');
            if (mudb) {
                let menuData = JSON.parse(localStorage.getItem('menuList'));
                console.log("david2", menuData)
                if (menuData.length > 0) {//remove this if 不做例外
                    console.log("儲存成功");
                    return setMenuList(menuData);                    
                }
            }
        }//else {use backup menu}
        //2. set backup menu
        checkBackMenu();
    }, []);

    function checkBackMenu() {
        if (localStorage.getItem("defaultMenu")) {
            let tempList = JSON.parse(localStorage.getItem("defaultMenu"));
            if (tempList.length > 0) {
                console.log("davi1",tempList);
                setMenuList(tempList);
            }
        } else {
            window.setTimeout(function () {
                checkBackMenu();
            }, 100)
        }
    }

    function goHref(link, data) {
        if (data.ExSchdEndDate && data.ExSchdStartDate) {
            if (new Date() - new Date(data.ExSchdStartDate) > 0) {
                if (new Date() - new Date(data.ExSchdEndDate) > 0) {
                    //window.location.href = "../";
                    return alert("已經結束功能");
                }
            } else {
                //window.location.href = "../";
                return alert("尚未開放功能");
            }
        }
        window.location.href = link;
    }

    function logout() {
        localStorage.clear();
        window.location = "/";
        //history.push("/");
    }

    return (
        <div id="left">
            <div id="submenu-title">
                {titleAry.map((value, key) => (
                    <span key={key}>{value}</span>
                ))}
            </div>
            <div id="submenu">
                {/*{console.log(menuList, menuList[2].subMenu) }*/}
                <ul>
                    {
                        menuList == null || menuList.length == 0 ? null :
                            menuList.map((val, key) =>
                                Object.keys(val).length == 0 ? null :
                                    val.MenuCpation != takeCap ? null :
                                        val.subMenu.length == 0 ? null :
                                            val.subMenu.map((el, ky) => (
                                                localStorage.getItem("isLogin")==1 && el.FuncAction == "Login" ? null :
                                                    <li key={key + ky}>
                                                        {(el.subMenu && Object.keys(el.subMenu).length > 0 ?
                                                            <>
                                                                <a>{el.MenuCpation} </a>
                                                                <ul>
                                                                    {el.subMenu.map((val, k2) => (
                                                                        <li key={key + ky + k2} >
                                                                            <a href="#"
                                                                                className={(val.FuncUrl.toLowerCase() == modalUrl ? "current" : "")} onClick={() => goHref(val.FuncUrl, val)} >
                                                                                {val.MenuCpation}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                            :
                                                            el.FuncUrl == "/logout" || el.FuncUrl == "logout" ?
                                                                <a href="#" className="text-danger" onClick={logout} > {el.MenuCpation} </a>
                                                                :
                                                                <a href="#" className={(el.FuncUrl.toLowerCase() == modalUrl ? "current" : "")} onClick={() => goHref(el.FuncUrl, el)} > {el.MenuCpation} </a>
                                                        )}
                                                    </li>
                                            ))
                            )
                    }
                </ul>
            </div>
        </div>
    )
}
export default LeftSideW;