import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_Front_KindList,
    API_Post_Login_SignUp
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import ClientCaptcha from 'react-client-captcha';
import Swal from 'sweetalert2';

function Signuplogin() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [menuList, setMenuList] = useState([]);//選單

    const [ExTypeArrayList, setExTypeArrayList] = useState([{}]); //報名類別radio
    const [ExType, setExType] = useState(""); //報名類別
    const [KindArrayList, setKindArrayList] = useState([{}]); //報考類科下拉
    const [ExKindNo, setExKindNo] = useState(""); //報考類科
    const [UIDNo, setUIDNo] = useState(""); //身分證號
    const [Password, setPassword] = useState(""); //密碼
    const [CkPassword, setCkPassword] = useState(""); //確認密碼
    const [UEmail, setUEmail] = useState(""); //電子郵件
    const [captchaCode, setCaptchaCode] = useState('');//驗證碼圖片
    const [UserCaptchaCode, setUserCaptchaCode] = useState('');//User驗證碼



    useEffect(() => {
        //1. set menu
        if (localStorage.getItem("isLogin") == "1") {
            //console.log("isLogin")
            let mudb = localStorage.getItem('menuList');
            if (mudb) {
                let menuData = JSON.parse(localStorage.getItem('menuList'));
                if (menuData.length > 0) {//remove this if 不做例外
                    //console.log(menuData);
                    return setMenuList(menuData);
                }
            }
        }//else {use backup menu}
        //2. set backup menu
        checkBackMenu();
        
    }, []);
    //檢查開放期間過期
    useEffect(() => {
        let arq = menuList.find(x => x.MenuCpation == "初試報名系統");
        if (arq) {
            if (arq.ExSchdEndDate && arq.ExSchdStartDate) {

                if (new Date() - new Date(arq.ExSchdStartDate) > 0) {
                    if (new Date(arq.ExSchdEndDate) - new Date() > 0) {
                        return getExType();
                    } else {
                        console.log("too late");
                    }
                } else {
                    console.log("too early");
                }
            }
            Swal.fire({
                icon: 'error',
                text: "尚未開放功能",
            })

            return window.location.href = "../";
        }

    }, [menuList]);


    function checkBackMenu() {
        if (localStorage.getItem("defaultMenu")) {
            let tempList = JSON.parse(localStorage.getItem("defaultMenu"));
            if (tempList.length > 0) {
                //console.log(tempList);
                setMenuList(tempList);
            }
        } else {
            window.setTimeout(function () {
                checkBackMenu();
            }, 100)
        }
    }


    function getExType() {
        API_Get_Front_KindList()
            .then((response) => response.data)
            .then((data) => {

                if (data.code == "0000") {
                    console.log(data);
                    setExTypeArrayList(data.result.type);
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })

                    setExTypeArrayList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    window.location.href = "../";
                }
            });
    }

    function getKindList(type) {
        setExType(type);

        API_Get_Front_KindList({
            "ExType": type
        })
            .then((response) => response.data)
            .then((data) => {

                if (data.code == "0000") {
                    console.log(data);
                    setKindArrayList(data.result.kind);
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })

                    setKindArrayList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                //if (err.response.status === 401 || err.response.data.code == "1001") {
                //    localStorage.clear();
                //    history.push("/");
                //}
            });
    }
    //檢查必填 密碼 身分證號 驗證碼
    function formRequired() {
        var result = true;
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        const radioList = document.querySelectorAll('input[type="radio"]');
        if (ExType == "") {
            radioList.forEach((element) => {
                element.classList.add("error");
            });
        } else {
            radioList.forEach((element) => {
                element.classList.remove("error");
            });
        }

        return result;
    }

    //檢查密碼 身分證號 驗證碼
    function chkData() {
        //console.log('檢查密碼 身分證號 驗證碼');
        var result = true;
        var err = "";

        // 身分證字號
        var taiwanIdValidator = require("taiwan-id-validator2");
        if (taiwanIdValidator.isNationalIdentificationNumberValid(UIDNo) == false) {
            err += '身分證字號輸入錯誤<br/>';
            result = false;
        }

        if (Password.length < 5) {
            err += '密碼字數請設定5~10位字元<br/>';
            result = false;
        }

        if (Password != CkPassword) {
            err += '確認密碼輸入錯誤<br/>';
            result = false;
        }

        let regex = new RegExp(/^[_\.0-9a-z-]+@([0-9a-z][0-9a-z-]+\.)+[a-z]{2,3}$/g);
        if (regex.test(UEmail) == false) {
            err += 'E-mail輸入格試錯誤<br/>';
            result = false;
        }

        if (captchaCode.toLowerCase() != UserCaptchaCode.toLowerCase()) {
            err += '驗證碼輸入錯誤<br/>';
            result = false;
        }
        if (err != "") {
            Swal.fire({
                icon: 'error',
                html: err,
            })
        }
        return result;
    }

    //重新設定
    function Reset() {
        //console.log('重新設定');
        setExKindNo(""); //報考類科
        setUIDNo(""); //身分證號
        setPassword(""); //密碼
        setCkPassword(""); //確認密碼
        setUEmail(""); //電子郵件
        setUserCaptchaCode(""); //User驗證碼
    }

    function keyNext(e) {
        console.log(e);
        console.log(e.key);
        console.log(e.charCode);

        addEventListener("keypress", (e) => {
            if (e.keyCode === "Enter") {
                event.preventDefault();//讓enter無效
            }
        });
        console.log(e);
        console.log(e.key);
        console.log(e.charCode);

    }
    
    //儲存，下一步
    function Next(e) {
        //console.log('下一步');
       

        var result_Required = formRequired();
        if (result_Required && e.key != "Enter") {
            var result_chkData = chkData();

            if (result_chkData === true) {


                Swal.fire({
                    icon: 'warning',
                    text: "請再確次認信箱資訊，若填寫錯誤將無法接收報名認證函",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: '確定',
                    cancelButtonText: '取消'
                }).then((result) => {
                    if (result.isConfirmed && e.key != "Enter") {
                        console.log('儲存');
                        document.getElementById("loading").style.display = "block";


                        API_Post_Login_SignUp({
                            "UIDNo": UIDNo,
                            "Password": Password,
                            "UEmail": UEmail,
                            "ExKindNo": ExKindNo

                        })
                            .then((response) => response.data)
                            .then((data) => {

                                if (data.code == "0000") {
                                    document.getElementById("loading").style.display = "none";
                                    Swal.fire({
                                        icon: 'info',
                                        html: "<div style='text-align: left'>請至電子信箱：" + data.result.email + "<br/>收取【註冊認證信】。</div>",
                                    })
                                    //window.location = "/Signup/Signin";
                                    history.push("../Signup/Signin");
                                }
                                else {
                                    document.getElementById("loading").style.display = "none";
                                    Swal.fire({
                                        icon: 'error',
                                        text: data.message,
                                    })

                                    setDataList([]);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                if (err.response.status === 401 || err.response.data.code == "1001") {
                                    localStorage.clear();
                                    history.push("/");
                                }
                            });

                    } else {
                        document.getElementById("loading").style.display = "none";
                    }
                })

               
            }
        }




    }

    return (
        <>
            <div id="master-main-bg">

                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname} />

                <div id="content">
                    <h1>註冊報名</h1>
                    <div id="main">
                        <ul>
                            <li><font color='red'>密碼一設定完成，便無法重新設定、修改，請記牢，且妥善保管，勿外洩。 </font></li>
                            <li>密碼字數請設定5~10位字元，建議使用英數混合，保護資料安全。</li>
                            <li>如已註冊報名 請點選 <a href='Signin' >修改、確認、列印 報名資料</a></li>
                        </ul>

                        <fieldset>
                            <legend>註冊報名</legend>
                            <table width="650" border="0" cellPadding="5" cellSpacing="0">
                                <tbody>

                                    <tr >
                                        <td width="50">報名類別：</td>
                                        <td width="608">

                                            {/*<span className="font-2" style={{ marginLeft: "-60px" }}>*/}
                                            {/*    <input type="radio" onClick="" value={ExType} onChange={(e) => { setExType(e.target.value) }} />*/}
                                            {/*    <font size="3"><strong>國中</strong></font>*/}
                                            {/*</span>*/}
                                            <div>
                                                {ExTypeArrayList == null || ExTypeArrayList.length==0?null:
                                                    ExTypeArrayList.map((y, i) => (
                                                    i == 0
                                                        ? (
                                                            <span key={"ExTypeArrayList" + i} className="font-2" style={{ marginLeft: "-60px", marginRight: "10px" }}>
                                                                <input type="radio" name="KindList" value={y.value} onChange={(e) => getKindList(e.target.value)} />
                                                                <font size="3"><strong>{y.text}</strong></font>
                                                            </span>
                                                        )
                                                        : (
                                                            <span key={"ExTypeArrayList" + i} className="font-2" style={{ marginRight: "10px" }}>
                                                                <input type="radio" name="KindList" value={y.value} onChange={(e) => getKindList(e.target.value)} />
                                                                <font size="3"><strong>{y.text}</strong></font>
                                                            </span>
                                                        )
                                                ))}
                                            </div>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td >報考類科：</td>
                                        <td >
                                            <select name="exam_subj" id="exam_subj" style={{ marginLeft: "-60px" }} value={ExKindNo} onChange={(e) => { setExKindNo(e.target.value) }} data-required>
                                                <option value="">請選擇</option>
                                                {
                                                    KindArrayList.length == 0 || KindArrayList == null ?
                                                        <option disabled>類別無可報考類科</option> :
                                                        KindArrayList.map((y, i) => (
                                                            <option key={i} value={y.exKindNo}>{y.exKindName} </option>
                                                        ))}
                                            </select>(考生僅限報考一科)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>身分證號：</td>
                                        <td>
                                            <input name="login_id" type="text" style={{ marginLeft: "-60px" }} className="Ariel" id="login_id2" size="12" maxLength="10" value={UIDNo} onChange={(e) => { setUIDNo(e.target.value) }} data-required />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>密碼：</td>
                                        <td>
                                            <input name="login_pwd" type="password" style={{ marginLeft: "-60px" }} className="Ariel" id="login_pwd" size="12" maxLength="10" value={Password} onChange={(e) => { setPassword(e.target.value) }} data-required />
                                            (密碼字數請設定5~10位字元，建議使用英數混合，保護資料安全。)
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>確認密碼：</td>
                                        <td>
                                            <input name="login_pwd2" type="password" className="Ariel" style={{ marginLeft: "-60px" }} id="login_pwd2" size="12" maxLength="10" value={CkPassword} onChange={(e) => { setCkPassword(e.target.value) }} data-required />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>電子郵件：</td>
                                        <td>
                                            <input name="email" type="text" className="Ariel" style={{ marginLeft: "-60px" }} id="email" size="50" value={UEmail} onChange={(e) => { setUEmail(e.target.value) }} data-required />(請填寫有效的Email，註冊後將寄發帳戶啟用驗證信)
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><ClientCaptcha captchaCode={(code) => setCaptchaCode(code)} chars="0123456789" /></td>
                                        <td>
                                            <input name="NewCode" type="text" className="input" style={{ marginLeft: "-60px" }} id="NewCode" maxLength="4" size="12" value={UserCaptchaCode} onChange={(e) => { setUserCaptchaCode(e.target.value) }} data-required /> (請根據左方顯示輸入驗證碼)
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input type="hidden" name="ET" value="" />
                                            <input type="button" name="Submit" value="下一步" onClick={(e) => Next(e)} />
                                            <input type="button" name="Submit2" value="重新設定" onClick={Reset} />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </fieldset>

                    </div>
                </div>

            </div>

        </>
    );
}


export default Signuplogin;
