import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import News from './pages/News';
import Process from './pages/Process';
import Process01 from './pages/Process01';
import Process04 from './pages/Process04';
import Signup from './pages/Signup';
import Signin from './pages/Signin';

import Search from './pages/Search';
import Search_Score from './pages/Search_Score';
import Search2 from './pages/Search2';
import Search2_Score from './pages/Search2_Score';

import Signuplogin from './pages/Signuplogin';
import Modify_form from './pages/Modify_form';
import Modify_file from './pages/Modify_file';
import Modify_photo from './pages/Modify_photo';
import Modify_bankact from './pages/Modify_bankact';
import Modify_eduFile from './pages/Modify_eduFile';
import Modify_specialty from './pages/Modify_specialty';
import Modify_chinfo from './pages/Modify_chinfo';
import Modify_native from './pages/Modify_native';
import Modify_aborigine from './pages/Modify_aborigine';
import Modify_engfile from './pages/Modify_engfile';
import Modify_supfile from './pages/Modify_supfile';
import Modify_Awards_File from './pages/Modify_awardsFile';
import Modify_Bilingual_File from './pages/Modify_bilingualFile';
import Modify_Bankinfo from './pages/Modify_bankinfo';
import Modify_Bankinfo_Msg from './pages/Modify_bankinfoMsg';
import Modify_ShowPaper from './pages/Modify_showPaper';
import Modify_ShowDoc from './pages/Modify_showDoc';
import Modify_SpecialEducation from './pages/Modify_specialEducation';
import Signups from './pages/Second_Signups';
import Second_Flogin from './pages/Second_FLogin';
import Second_SFLogin from './pages/Second_SFLogin';
import Second_Form from './pages/Second_Form';
import Second_Form2 from './pages/Second_Form2';
import Second_Intro from './pages/Second_Intro';
import Second_bankact from './pages/Second_bankact';
import Second_Basic_Doc from './pages/Second_Basic_Doc';
import Second_file from './pages/Second_file';
import Second_engfile from './pages/Second_engfile';
import Second_Other_Doc from './pages/Second_Other_Doc';
import Second_Aff_Signed from './pages/Second_Aff_Signed';
import Second_Ambition from './pages/Second_Ambition';
import Second_Sign_Status from './pages/Second_Sign_Status';
import Second_Second_chinfo from './pages/Second_chinfo';
import Second_Show_From from './pages/Second_show_From';
import Second_Show_paper from './pages/Second_show_paper';
import Second_Score from './pages/Second_Score';

import Ambition_Login from './pages/Ambition_Login';
import Ambition_Edit from './pages/Ambition_Edit';
import Ambition_New from './pages/Ambition_New'; 

import RepairFile_Basic_Doc from './pages/RepairFile_Basic_Doc';
import RepairFile_engfile from './pages/RepairFile_engfile';
import RepairFile_file from './pages/RepairFile_file';
import RepairFile_Login from './pages/RepairFile_Login';

import Header from './components/partial/header';
import { defaultMenu } from './global/ToolUtil';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Maintenance from './pages/Maintenance';

//import "./css/layout.css";

const noDomList = {
    "noHeader": [`/stu_result/result_login`],
    "noFooter": [`/stu_result/result_login`],
}
const header = (
    <Header />
);
{
    localStorage.setItem("cookieCrack", "");
    if (!noDomList["noHeader"].includes(window.location.pathname.toLowerCase())) {
        defaultMenu();
        ReactDOM.render(header, document.getElementById('master-header'));
        if (localStorage.getItem('isLogin') == "1") {
            let dbmenu = localStorage.getItem('menuList');
            if (dbmenu) {
                let menuData = JSON.parse(localStorage.getItem('menuList'))
                console.log("david3", menuData)
                if (menuData.length > 0) {
                    menuData.map((obj, i) => {
                        obj.subMenu.map((obj2, i2) => {
                            if (obj2.FuncUrl == window.location.pathname + location.search) {
                                console.log(obj2);
                                localStorage.setItem("cookieCrack", obj2.MenuID);
                            } else {
                                obj2.subMenu.map((obj3, i3) => {
                                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                                        console.log(obj3);
                                        localStorage.setItem("cookieCrack", obj3.MenuID);
                                    }
                                });
                            }
                        });
                    });
                }
            }
        }
    }
}
const root = (

    <I18n>

        <BrowserRouter>

            <Switch>
                {/*<Route exact path="/" component={Maintenance} />*/}
                <Route exact path="/" component={News} />
                <Route exact path="/News" component={News} />

                <Route exact path="/Process/Process" component={Process} />
                <Route exact path="/Process/Process01" component={Process01} />
                <Route exact path="/Process/Process02" component={Process04} />

                <Route exact path="/Signup/Signup" component={Signup} />
                <Route exact path="/Signup/Signin" component={Signin} />
                <Route exact path="/Signup/Signuplogin" component={Signuplogin} />
                <Route exact path="/Signup/Modify_form" component={Modify_form} />
                <Route exact path="/Signup/Modify_file" component={Modify_file} />
                <Route exact path="/Signup/Modify_photo" component={Modify_photo} />
                <Route exact path="/Signup/Modify_bankact" component={Modify_bankact} />
                <Route exact path="/Signup/Modify_eduFile" component={Modify_eduFile} />
                <Route exact path="/Signup/Modify_specialty" component={Modify_specialty} />
                <Route exact path="/Signup/Modify_chinfo" component={Modify_chinfo} />
                <Route exact path="/Signup/Modify_native" component={Modify_native} />
                <Route exact path="/Signup/Modify_aborigine" component={Modify_aborigine} />
                <Route exact path="/Signup/Modify_engfile" component={Modify_engfile} />
                <Route exact path="/Signup/Modify_supfile" component={Modify_supfile} />
                <Route exact path="/Signup/Modify_awardsFile" component={Modify_Awards_File} />
                <Route exact path="/Signup/Modify_bilingualFile" component={Modify_Bilingual_File} />
                <Route exact path="/Signup/Modify_bankinfo" component={Modify_Bankinfo} />
                <Route exact path="/Signup/Modify_bankinfoMsg" component={Modify_Bankinfo_Msg} />
                <Route exact path="/Signup/Modify_showPaper" component={Modify_ShowPaper} />
                <Route exact path="/Signup/Modify_showDoc" component={Modify_ShowDoc} />
                <Route exact path="/Signup/Modify_specialEducation" component={Modify_SpecialEducation} />

                <Route exact path="/Second/Signups" component={Signups} />
                <Route exact path="/Second/Form" component={Second_Form} />
                <Route exact path="/Second/Form2" component={Second_Form2} />
                <Route exact path="/Second/FLogin" component={Second_Flogin} />
                <Route exact path="/Second/SFLogin" component={Second_SFLogin} />
                <Route exact path="/Second/Intro" component={Second_Intro} />
                <Route exact path="/Second/bankact" component={Second_bankact} />
                <Route exact path="/Second/Basic_Doc" component={Second_Basic_Doc} />
                <Route exact path="/Second/Second_file" component={Second_file} />
                <Route exact path="/Second/Second_engfile" component={Second_engfile} />
                <Route exact path="/Second/Other_Doc" component={Second_Other_Doc} />
                <Route exact path="/Second/Aff_Signed" component={Second_Aff_Signed} />
                <Route exact path="/Second/Ambition" component={Second_Ambition} />
                <Route exact path="/Second/Sign_Status" component={Second_Sign_Status} />
                <Route exact path="/Second/Second_chinfo" component={Second_Second_chinfo} />
                <Route exact path="/Second/Show_From" component={Second_Show_From} />
                <Route exact path="/Second/Show_paper" component={Second_Show_paper} />
                <Route exact path="/Second/Score" component={Second_Score} />

                <Route exact path="/Ambition/Login" component={Ambition_Login} />
                <Route exact path="/Ambition/Edit" component={Ambition_Edit} />
                <Route exact path="/Ambition/New" component={Ambition_New} />

                <Route exact path="/RepairFile/RepairFile_Basic_Doc" component={RepairFile_Basic_Doc} />
                <Route exact path="/RepairFile/RepairFile_engfile" component={RepairFile_engfile} />
                <Route exact path="/RepairFile/RepairFile_file" component={RepairFile_file} />
                <Route exact path="/RepairFile/Login" component={RepairFile_Login} />

                <Route exact path="/Search/index" component={Search} />
                <Route exact path="/Search/score" component={Search_Score} />
                <Route exact path="/SearchR/index2" component={Search2} />
                <Route exact path="/SearchR/score" component={Search2_Score} />

            </Switch>
        </BrowserRouter >
    </I18n>

);

ReactDOM.render(root, document.getElementById('master-main'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
