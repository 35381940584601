import { API_GET_EMail_UpFile, API_Get_Account_DefalultMenus } from "../global/constants";
import { checkAuthToken } from '../global/TokenUtil';

//打開Modal
export function OpenModal(id, layer = 1) {
    if (document.getElementById(id)) {
        var target = document.querySelector('#' + id);
        var layerClass = 'layer-' + layer;
        target.classList.add('active');
        target.classList.add(layerClass);
    }
}
//關閉Modal
export function CloseModal(id, layer = 1) {
    if (document.getElementById(id)) {
        var target = document.querySelector('#' + id);
        var layerClass = 'layer-' + layer;
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

}
//JS參數
export function InitJS() {
    /*hover秀出小選單*/
    if (document.querySelector('[data-hoverTrigger]')) {
        const hoverBtns = document.querySelectorAll('[data-hoverTrigger]');
        const hoverItems = document.querySelectorAll('[data-hoverItems]');
        //先移除監聽模式
        hoverBtns.forEach(element => {
            element.mouseenter = null;
        });
        hoverItems.forEach(element => {
            element.mouseleave = null;
        });
        //賦予監聽
        hoverBtns.forEach(element => {
            var parent = element.closest('.lt-row');
            element.addEventListener('mouseenter', function () {
                parent.classList.add('show-hovers')
            })
        });
        hoverItems.forEach(element => {
            var parent = element.closest('.lt-row');

            element.addEventListener('mouseleave', function () {
                parent.classList.remove('show-hovers')
            })
        });
    }

    /*顯示下拉*/
    const datalist = document.querySelectorAll("[data-datalist]");

    datalist.forEach((element) => {
        var dropmenu = element.querySelector(".datalist");
        var input = element.querySelector("input");
        //移除監聽
        input.keyup = null;
        input.addEventListener("keyup", function () {
            element.classList.add("show-datalist");
        });
        document.body.addEventListener("click", function (e) {
            var target = e.target;
            if (element.contains(target)) {
                //console.log(target);
                if (dropmenu.contains(target)) {
                    var value = target.dataset.value;
                    input.value = value;
                    element.classList.remove("show-datalist");
                } else {
                    //console.log('ddd');
                }
            } else {
                element.classList.remove("show-datalist");
            }
        });
    });


    var currentModal;
    let currentLevel = 1;
    const modals = document.querySelectorAll('[data-modal]');
    const modalBtns = document.querySelectorAll('[data-openmodal]');
    modals.forEach(element => {
        var modalBody = element.querySelector('.modal-container');
        var modalClose = element.querySelectorAll('[data-close]');
        var modalsBack = element.querySelector('[data-back]');
        element.click = null;
        //console.log(modalClose);
        element.addEventListener('click', function (e) {
            if (e.target == modalsBack) {
                var showLayerClass = 'has-layer-' + (currentLevel);
                currentLevel = currentLevel - 1;
                element.classList.remove('active');
                body.classList.remove(showLayerClass);
            } else if (e.target == element || e.target.hasAttribute('data-close')) {
                /*這邊可能要再增加一個判斷，判斷這個[data-close]是否要到指定層級*/

                var showLayerClass = 'has-layer-' + (currentLevel);
                if (typeof currentModal !== "undefined") {
                    currentModal.classList.remove('active');
                } else { }
                currentLevel = currentLevel - 1;
                element.classList.remove('active', 'layer-1', 'layer-2', 'layer-3', 'layer-4', 'layer-5');
                body.classList.remove('has-modal', showLayerClass);
            }
        });
    });

    /*上傳*/
    if (document.querySelector('[data-fakeUpload]')) {
        const item = document.querySelector('[data-fakeUpload]');
        const real = item.querySelector('.real');
        const fake = item.querySelector('.fake');
        real.change = null;
        real.addEventListener('change', function (e) {
            var path = e.target.value;
            var filename = path.replace(/^.*[\\\/]/, '');
            fake.value = filename;

        })
    }


    /*slide toggle 往下展開*/
    if (document.querySelector('[data-slidetoggle]')) {
        const items = document.querySelectorAll('[data-slidetoggle]');
        items.forEach(element => {
            var targetID = element.dataset.slidetoggle;
            var parent = element.closest('.lt-row');
            var target = document.querySelector('#' + targetID)
            console.log(target);
            element.click = null;
            element.addEventListener('click', function () {
                element.classList.toggle('rotate');
                target.classList.toggle('hide');
                parent.classList.toggle('highlight');
            })

        });
    }



}
//檔案上傳寄信
export function upFileMail(userID) {
    if (!userID) {
        return { "code": "9999", "message": "無效使用者" };
    }
    API_GET_EMail_UpFile(
        { "UserID": userID }
    ).then((response) => response.data)
        .then((data) => {
            //console.log(data);
            if (!checkAuthToken(data)) {
                window.location.href = "../";
            }
            return { "code": "0000", "message": "送出" };
        })
        .catch(err => {
            console.log(err);
            return { "code": "9999", "message": err }
        })
}
//載入預設選單
export function defaultMenu() {
    //alert("defual call");

    API_Get_Account_DefalultMenus()
        .then((response) => response.data)
        .then((data) => {
            //console.log("say my name");
            if (data.code == "0000") {
                let usdb = data.result;
                localStorage.setItem("defaultMenu", JSON.stringify(usdb));
            }
        })
        .catch(err => {
            console.log(err);
            if (err.response.status === 401 || err.response.data.code == "1001") {
                localStorage.setItem("defaultMenu", "");
            }
        })
}
