import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_RTConfirm_RTChinfo,
    API_Get_UpFile_GetData
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';

function ModifyChinfo() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const UserData = JSON.parse(localStorage.getItem('UserData')) || {};
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const date = new Date();
    const birYStar = date.getFullYear() - 1911 - 65; //生日年起迄
    const [rocDate, setRocDate] = useState("");

    const [KindArrayList, setKindArrayList] = useState([{}]); //報考類科下拉
    const [ExKindNo, setExKindNo] = useState(UserData.exKindNo); //報考類科
    const [imgBaseList, setimgBaseList] = useState({});//彈窗圖片容器
    const [imgBaseItem, setimgBaseItem] = useState({});//彈窗圖片容器
    const [resumeList, setResumeList] = useState([]); //經歷清單
    const [UdocList, setUdocList] = useState([]); //使用者上傳文件清單
    const [uUdocList, setuUdocList] = useState([]); //使用者上傳文件清單
    const [UserList, setUserList] = useState({}); //使用者資料清單

    const [photo, setPhoto] = useState("../images/photo-sample.gif");
    const [birthY, setbirthY] = useState(""); //生日-年
    const [birthM, setbirthM] = useState(""); //生日-月
    const [birthD, setbirthD] = useState(""); //生日-日

    const [CityName, setCityName] = useState(""); //住址-城市
    const [AreaName, setAreaName] = useState(""); //住址-地區
    const [AddrZIP, setAddrZIP] = useState(""); //住址-郵遞區號

    const [filtered, setfiltered] = useState([]); //文件列表

    const [C001chk, setC001chk] = useState(""); //c001 文件 勾選
    const [C001chkT, setC001chkT] = useState(""); //c001 文件 原因

    
    const [next, setnext] = useState(""); //下一步
    const [docMark, setUdocMark] = useState("");//說明內容
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號

    const [upfileList, setUpfileList] = useState([]); //資績清單
    const [upEngList, setUpEngList] = useState([]); //英文能力檢定證書
    const [upaborigineList, setUpaborigineList] = useState([]); //原住民清單
    const [upAwardsFile, setUpAwardsFile] = useState([]); //獲獎免初試證明	
    const [upbilingualFile, setUpbilingualFile] = useState([]); //雙語資格證明
  
    const [upQ100, setUpQ100] = useState([]); //學歷總分
    const [upeduFile, setUpeduFile] = useState([]); //碩博士學歷

    const [upQ200, setUpQ200] = useState([]); //經歷總分
 
    const [upsvr3Y, setUpsvr3Y] = useState([]); //連續服務滿三年
    const [upsvrcert, setUpsvrcert] = useState([]); //服務證明

    const [resumeScore, setResumeScore] = useState(""); //經歷自評分


    useEffect(() => {
        if (Object.keys(UserData).length == 0) {
            localStorage.clear();
            window.location.href = "/";
        }

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {

                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });

        getData();
    }, []);

    function getData() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "/";
                }
                if (data.code == "0000") {

                    API_Get_RTConfirm_RTChinfo({
                        "UserEKID": UserData.userEKID
                    })
                        .then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                window.location.href = "/"
                            }
                            if (data.code == "0000") {
                                
                                console.log(data);
                                setExKindNo(data.result.user[0].ExKindNo);
                                setUdocList(data.result.docList);
                                setuUdocList(data.result.docList);
                                setUserList(data.result.user[0]);
          
                                var i = 0;
                                var temp = [];
                                var aa = [];
                                var bb = [];
                                var sum = 0;

                                //文件
                                data.result.docType.map(obj => {
                                    obj.item = [];

                                    data.result.docBaseList.map(obj2 => {

                                        if (obj.DocKType == obj2.docKType) {
                                            obj2.doc =
                                            {
                                                "CodeText": obj.CodeText,
                                                "DocFileCnt": "1",
                                                "DocKMode": obj2.docKMode,
                                                "DocKType": obj2.docKType,
                                                "DocKUPMode": null,
                                                "DocMark1": obj2.docMark1,
                                                "DocMark2": obj2.docMark2,
                                                "DocMark3": obj2.docMark3,
                                                "DocName": obj2.docName,
                                                "DocNo": obj2.docNo,
                                                "DocRefFile": obj2.docRefFile,
                                                "EndM": "",
                                                "EndY": "",
                                                "StarM": "",
                                                "StarY": "",
                                                "UExDocATTID": null,
                                                "UExDocChk": (obj2.docNo == "B001" ? "1" : "0"),
                                                "UExDocMark1": null,
                                                "UExDocMark2": null,
                                                "UExDocMark3": null,
                                                "attFlag":null
                                                /*"UdT1List": []*/

                                            };
                                            //setUdocList(UdocList => [...UdocList, obj2.doc])
                                            if (obj2.dM1 != null) {

                                                obj2.dT1List.map((obj4, i4) => {
                                                    obj4.attr = [];
                                                    obj2.doc[obj4.docMark + i4] = "";
                                                    //bb.uDocMark = "";
                                                    //obj2.doc.UdT1List.push(bb);
                                                    data.result.codeBase.map(obj3 => {

                                                        if (obj4.docMark == obj3.codeField) {
                                                            aa.name = obj3.codeField;
                                                            aa.Cname = obj3.codeText;
                                                            aa.value = obj3.codeValue;
                                                            aa.chked = "0";

                                                            obj4.attr.push(aa);
                                                            aa = [];
                                                        }


                                                    });

                                                });

                                            }
                                            if (obj2.dM3 != null) {

                                                obj2.dT3List.map((obj4, i4) => {
                                                    obj4.value = "";
                                                });

                                            }

                                            data.result.docList.map(doc => {
                                                
                                                doc.StarY = "";
                                                doc.StarM = "";
                                                doc.EndY = "";
                                                doc.EndM = "";

                                                if (doc.docNo == obj2.docNo) {
                                                    if (doc.uExDocMark1 && obj2.dM1 == "R") {
                                                        doc.udT1List.map(docobj => {
                                                            obj2.dT1List.map(dTobj => {
                                                                dTobj.attr.map(attobj => {
                                                                    if (attobj.Cname == docobj.uDocMark) {
                                                                        attobj.chked = "1";
                                                                    }

                                                                })
                                                            })
                                                        })
                                                    }
                                                    if (doc.uExDocMark2 && obj2.dM2 == "CYM") {
                                                        doc.StarY = (doc.uExDocMark2.slice(0, 4)) - 1911;
                                                        doc.StarM = parseInt(doc.uExDocMark2.slice(5, 7), 10);
                                                    }
                                                    if (doc.uExDocMark3 && obj2.dM3 == "CYM") {
                                                        doc.EndY = (doc.uExDocMark3.slice(0, 4)) - 1911;
                                                        doc.EndM = parseInt(doc.uExDocMark3.slice(5, 7), 10);
                                                    }
                                                    if (doc.uExDocMark3 && obj2.dM3 == "T") {
                                                        doc.udT3List.map((docobj, docinx) => {
                                                            obj2.dT3List.map((dTobj, dtinx) => {

                                                                if (docobj.uDocMark != "" && docinx == dtinx) {
                                                                    dTobj.value = docobj.uDocMark;
                                                                }


                                                            })
                                                        })
                                                    }

                                                    /* obj2.doc = doc;*/
                                                    obj2.doc =
                                                    {
                                                        "CodeText": doc.codeText,
                                                        "DocFileCnt": doc.cocFileCnt,
                                                        "DocKMode": doc.docKMode,
                                                        "DocKType": doc.docKType,
                                                        "DocKUPMode": doc.docKUPMode,
                                                        "DocMark1": doc.docMark1,
                                                        "DocMark2": doc.docMark2,
                                                        "DocMark3": doc.docMark3,
                                                        "DocName": doc.docName,
                                                        "DocNo": doc.docNo,
                                                        "DocRefFile": doc.docRefFile,
                                                        "EndM": doc.EndM,
                                                        "EndY": doc.EndY,
                                                        "StarM": doc.StarM,
                                                        "StarY": doc.StarY,
                                                        "UExDocATTID": doc.uExDocATTID,
                                                        "UExDocChk": doc.uExDocChk,
                                                        "UExDocMark1": doc.uExDocMark1,
                                                        "UExDocMark2": doc.uExDocMark2,
                                                        "UExDocMark3": doc.uExDocMark3,
                                                        "attFlag": doc.attFlag
                                                        //"UdT1List": doc.udT1List
                                                    };
                                                    //setUdocList(doc);
                                                    const upDocList = UdocList.map((objj, iii) => {
                                                        if (objj.DocNo == obj2.docNo) {

                                                            return { ...objj, objj: doc };
                                                        }
                                                        return obj;
                                                    });
                                                    setUdocList(upDocList);

                                                    if (doc.uExDocChk == "1" && obj2.docDisplayChild == "1" && obj2.docGNo == "C001") {
                                                        //console.log(doc);
                                                        setC001chk(doc.docNo);
                                                        setC001chkT(doc.uExDocMark1);

                                                    }
                                                }
                                            });
                                            if (obj2.docGNo == null) {
                                                obj2.item = [];
                                                data.result.docBaseList.map(obj33 => {
                                                    if (obj33.docDisplayChild == "1" && obj33.docGNo == obj2.docNo) {
                                                        if (obj33.docNo != "B014-2") {
                                                            obj.Cnt = obj.Cnt + 1;
                                                            obj2.item.push(obj33);
                                                        }
                                                    }
                                                });

                                                i++;
                                                obj2.no = i;
                                                obj.item.push(obj2);
                                            } else {
                                                if (obj2.docNo == "B014-2" && obj.DocKType == "3") {
                                                    i++;
                                                    obj2.no = i;
                                                    obj.item.push(obj2);
                                                }

                                            }
                                        }

                                    });
                                    if (obj.item.length > 0) {
                                        setfiltered(filtered => [...filtered, obj])
                                    }
                                });

                                data.result.svrCert.map(dd => {
                                    dd.ys = dd.userSCStartDate.slice(0, 4) - 1911;
                                    dd.ms = parseInt(dd.userSCStartDate.slice(5, 7), 10);
                                    dd.ds = parseInt(dd.userSCStartDate.slice(8, 10), 10);

                                    dd.ye = dd.userSCEndDate.slice(0, 4) - 1911;
                                    dd.me = parseInt(dd.userSCEndDate.slice(5, 7), 10);
                                    dd.de = parseInt(dd.userSCEndDate.slice(8, 10), 10);
                                    setUpsvrcert(prevState => [...prevState, dd]);
                                });
                               
                                data.result.docList.map(dd => {
                                    if (dd.docRefFile == "Y" || dd.docRefFile == "9") {
                                        if(dd.docGNo == "B008") {//雙語資格證明
                                            setUpbilingualFile(prevState => [...prevState, dd]);
                                        } else if (dd.docGNo == "B011") {//獲獎免初試證明
                                            setUpAwardsFile(prevState => [...prevState, dd]);
                                        } else if (dd.docNo == "C014") {//原住民清單
                                            setUpaborigineList(prevState => [...prevState, dd]);
                                        } else if (dd.docGNo == "C010") {//英文能力檢定證書
                                            if (dd.attFlag =="Y") {
                                                setUpEngList(prevState => [...prevState, dd.file]);
                                            }
                                            
                                        } else if (dd.docNo == "B016") {//碩博士學歷
                                            console.log(dd);
                                            setUpeduFile(prevState => [...prevState, dd]);
                                        }
                                    }
                                });

                                data.result.qualList.map(dd => {
                                    if (dd.QualNo == "Q100") {//學歷
                                        setUpQ100(dd);
                                    }else if (dd.QualNo == "Q200") {//經歷
                                        setUpQ200(dd);
                                    } else if (dd.DocNo == "E001") {//連續服務滿三年
                                        console.log(dd);
                                        setUpsvr3Y(dd);
                                    } else if (dd.UExDocATTID != null) {
                                        setUpfileList(prevState => [...prevState, dd]);
                                    }
                                });
                                
                                //經歷
                                var len = 3 - parseInt(data.result.resumeList.length, 10);
                                if (data.result.resumeList.length != 0) {
                                    data.result.resumeList.map((item, i) => {
                                        if (item.UserRStartDate != null) {
                                            item.resumeYs = item.UserRStartDate.slice(0, 4) - 1911;
                                            item.resumeMs = parseInt(item.UserRStartDate.slice(5, 7), 10);
                                            item.resumeDs = parseInt(item.UserRStartDate.slice(8, 10), 10);
                                        }
                                        if (item.UserREndDate != null) {
                                            item.resumeYe = item.UserREndDate.slice(0, 4) - 1911;
                                            item.resumeMe = parseInt(item.UserREndDate.slice(5, 7), 10);
                                            item.resumeDe = parseInt(item.UserREndDate.slice(8, 10), 10);
                                        }
                                    })
                                    setResumeList(data.result.resumeList);
                                }
                               
                                //生日
                                if (data.result.user[0].UBirDay != null) {
                                    console.log(data.result.user[0].UBirDay.slice(5, 7));
                                    setbirthY(parseInt((data.result.user[0].UBirDay.slice(0, 4)) - 1911));
                                    setbirthM(parseInt(data.result.user[0].UBirDay.slice(5, 7)));
                                    setbirthD(parseInt(data.result.user[0].UBirDay.slice(8, 10)));
                                }
                                //證件照
                                if (data.result.photo[0].file.length > 0) { //證件照
                                    setPhoto(data.result.photo[0].file[0].base64String);
                                }

                                //住址
                                if (data.result.user[0].UCityName != "") {
                                    setCityName(data.result.user[0].UCityName);
                                }
                                if (data.result.user[0].UCityName != "") {
                                    setAreaName(data.result.user[0].UAreaName);
                                }
                                if (data.result.user[0].UCityName != "") {
                                    setAddrZIP(data.result.user[0].UAddrZIP);
                                }
                                console.log("UserList");
                                console.log(UserList);
                                document.getElementById("loading").style.display = "none";
                            }
                            else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                                document.getElementById("loading").style.display = "none";
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })

                                document.getElementById("loading").style.display = "none";
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push("/");
                            }
                        });

                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    //Open彈窗
    function openModal(donNo, base64) {
        //console.log(donNo);
        //console.log(upfileList);
        //console.log(uUdocList);
        if (donNo != "") {
        uUdocList.forEach((obj, i) => {
            if (obj.docNo == donNo) {
                setimgBaseItem(obj.file[0].base64String);
            }


        })
            var target = document.querySelector("#modal-showImg");
            var layerClass = "layer-1";
            target.classList.add("active");
            target.classList.add(layerClass);
        
            
        }      
        if (base64 != "") {
            setimgBaseItem(base64);
            var target = document.querySelector("#modal-showImg");
            var layerClass = "layer-1";
            target.classList.add("active");
            target.classList.add(layerClass);
        }
    }
    //Close彈窗
    function hideModal() {
        setimgBaseItem("");
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }
     //Open彈窗英文能力檢定證書
    function openModalEng() {
        console.log(upEngList);
        console.log('upEngList');
        var target = document.querySelector("#modal-showImgEng");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗-英文能力檢定證書
    function hideModalEng() {
        setimgBaseItem("");
        var target = document.querySelector('#modal-showImgEng');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }


    return (
        <>

            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    
                    <div id="main">
                        <h1>核對報名表</h1>
                        <div dangerouslySetInnerHTML={{ __html: docMark }}></div>
                        <table width="100%" border="0" cellPadding="5" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td width="5%">類科：</td>
                                    <td width="50%">
                                        <u><b>{UserList.ExKindName}</b></u>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="datatable">
                            <tbody>
                                <tr>
                                    <td width="40" rowSpan="2"><div align="center">姓名</div></td>
                                    <td width="60" rowSpan="2"><div align="center">{UserList.UName}</div></td>
                                    <td width="120"><div align="center">國民身分證統一編號</div></td>
                                    <td width="30" rowSpan="2"><div align="center">性<br />別</div></td>
                                    <td width="30" rowSpan="2">
                                        {
                                            UserList.USex == "M" ? (
                                                <div align="center">男</div>
                                            ) : (
                                                UserList.USex == "F" ? (
                                                    <div align="center">女</div>
                                                ) : (
                                                    <div align="center">未填</div>
                                                )
                                            )
                                        }
                                    </td>
                                    <td width="40" rowSpan="2"><div align="center">出生<br />日期</div></td>
                                    <td width="100" rowSpan="2"><div align="center">{birthY}年{birthM}月{birthD}日</div></td>
                                    <td width="100" rowSpan="5" align="center"><div align="center">  <img src={photo} width="105" height="140" /><br /></div></td>
                                </tr>
                                <tr>
                                    <td><div align="center">{UserList.UIDNo}</div></td>
                                </tr>
                                <tr >
                                    <td><div align="center">現職</div></td>
                                    <td colspan="6">{UserList.UPJobSch}</td>
                                </tr>
                                <tr>
                                    <td><div align="center">住址</div></td>
                                    <td colspan="6">　{UserList.UAddrZIP}&nbsp;{UserList.UCityName}&nbsp;{UserList.UAreaName}&nbsp;{UserList.UAddrs}</td>
                                </tr>
                                <tr>
                                    <td><div align="center">電話</div></td>
                                    <td colspan="6">　(O)：{UserList.UTelO}&nbsp; &nbsp; (H)：{UserList.UTelH}&nbsp; &nbsp; 手機：{UserList.UPhone}</td>
                                </tr>
                            </tbody>
                        </table>
                      
                        <table className="datatable fileTH">
                            <tbody>
                                <tr>
                                    <td width="104" className="datatable-bg2">項目</td>
                                    <td width="25" className="datatable-bg2">序</td>
                                    <td className="datatable-bg2">檢附之證明（請於空格內填入資料）</td>
                                    <td width="70" className="datatable-bg2">初審</td>
                                    <td width="70" className="datatable-bg2">複審</td>
                                </tr>
                            </tbody>
                        </table>

                        {filtered.map((x, i) => (

                            <table key={i} className="fileTd datatable ">
                                <tbody>
                                    <tr>
                                        <td width="104" rowSpan={x.Cnt + 10} className="datatable-bg2">{x.CodeText}</td>
                                    </tr>

                                    {x.item.map((y, i2) => (

                                        i == "0" && i2 == "0" ? (

                                            <tr key={i + i2} >
                                                <td width="25" className="datatable-bg3">{y.no}</td>
                                                <td className="datatable-bg3">
                                                    {y.doc.UExDocChk == '1' ? (
                                                        <span> <label style={{ fontSize: "16px" }}>☑</label>&nbsp; {y.docName}</span>
                                                    ) : (
                                                            <span> <label style={{ fontSize: "16px" }}>☐</label>&nbsp; {y.docName}</span>
                                                    )
                                                    }
                                                   
                                                </td>
                                                <td width="70" rowSpan={x.Cnt}>&nbsp;</td>
                                                <td width="70" rowSpan={x.Cnt}>&nbsp;</td>
                                            </tr>

                                        ) : (
                                                y.docGNo == null || y.docGNo == "C001" || y.docGNo == "B014" ? (
                                                    /* <span>{y.docName}</span>*/
                                                    y.docGNo == null && y.docNo != "C001" ?
                                                    (
                                                        <tr>
                                                            <td width="25" className="datatable-bg1">{y.no}</td>
                                                            <td className="datatable-bg1" >

                                                                {y.docRefFile != "N" ? (
                                                                    y.docDisplayChild == null ? (
                                                                        <span>
                                                                            
                                                                                {y.doc.UExDocChk == '1' ? (
                                                                                    <span> <label style={{ fontSize: "16px" }}>☑</label>&nbsp; {y.docName}</span>
                                                                                ) : (
                                                                                        <span> <label style={{ fontSize: "16px" }}>☐</label>&nbsp; {y.docName}</span>
                                                                                )
                                                                                }
                                                                                
                                                                            {y.docMark1 != null && (
                                                                                <span>：
                                                                                    {y.dM1 == "T" ? (
                                                                                            <span>
                                                                                                {y.doc.UExDocMark1}{y.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            </span>
                                                                                    ) : (
                                                                                        y.dM1 == "N" ? (
                                                                                                    <span>
                                                                                                        {y.doc.UExDocMark1}&nbsp;&nbsp;{y.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                               </span>
                                                                                        ) : (
                                                                                            y.dM1 == "R" ? (
                                                                                                y.dT1List.map((zz, i4) => (
                                                                                                    <span>
                                                                                                        {zz.attr.map((zy, i5) => (
                                                                                                            //y.doc.UdT1List.map((zyy, i6) => (
                                                                                                            <span>
                                                                                                                {zy.chked == "1" ? (
                                                                                                                    <span>{zy.Cname}</span>

                                                                                                                ): (
                                                                                                                <span></span>
                                                                                                                    )}
                                                                                                                {/*<input type="radio" value={zy.value} checked={zy.chked == "1"} name={zz.docMark} style={{ width: "60px" }} maxLength="6" onChange={(e) => setDoc(i, i2, y.doc.DocNo, e.target.value, zz.docMark, "R")} />{zy.Cname}*/}
                                                                                                            </span>
                                                                                                            //))checked={y.doc.UdT1List[i4].uDocMark == zy.value}
                                                                                                        ))}
                                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                ))
                                                                                            ) : (
                                                                                                y.dM1 == "CYM" ? (
                                                                                                    y.doc.UExDocChk == '1' ? (
                                                                                                        <span>
                                                                                                            {y.dT1}{y.doc.StarY}年{y.doc.StarM}月
                                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                                    ) : (
                                                                                                                        <span> {y.dT1}&nbsp; &nbsp; 年&nbsp; &nbsp; 月</span>
                                                                                                                    )
                                                                                                    
                                                                                                ) : (
                                                                                                    <span> </span>
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )}
                                                                                </span>
                                                                            )}
                                                                            {y.docMark2 != null && (
                                                                                y.dM2 == "T" ? (
                                                                                        <span>
                                                                                            {y.doc.UExDocMark2}{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        </span>
                                                                                ) : (
                                                                                    y.dM2 == "N" ? (
                                                                                                <span>
                                                                                                    {y.doc.UExDocMark2}&nbsp;&nbsp;{y.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                    ) : (
                                                                                        y.dM2 == "R" ? (
                                                                                            y.dT1List.map((zz, i4) => (
                                                                                                <span>
                                                                                                    {zz.attr.map((zy, i5) => (
                                                                                                        <span>{zy.Cname}</span>
                                                                                                    ))}
                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ))

                                                                                        ) : (
                                                                                            y.dM2 == "CYM" ? (
                                                                                                                y.doc.UExDocChk == '1' ? (
                                                                                                    <span>
                                                                                                        {y.dT2}{y.doc.StarY}年{y.doc.StarM}月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                                ): (
                                                                                                                <span> {y.dT2}&nbsp; &nbsp; 年&nbsp; &nbsp; 月</span>
                                                                                                            )
                                                                                                
                                                                                            ) : (
                                                                                                <span> </span>
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )

                                                                            )}
                                                                            {y.docMark3 != null && (
                                                                                    y.dM3 == "T" ? (
                                                                                        <span>
                                                                                            {y.dT3List.map((zz, i4) => (
                                                                                            <span>
                                                                                                    <span>
                                                                                                        {zz.value}&nbsp;&nbsp;{zz.docMark}
                                                                                                    </span>
                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            </span>
                                                                                            ))}
                                                                                    </span>
                                                                                      
                                                                                ) : (
                                                                                    y.dM3 == "N" ? (
                                                                                                <span>
                                                                                                    {y.doc.UExDocMark3}&nbsp;&nbsp;{y.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                    ) : (
                                                                                        y.dM3 == "R" ? (
                                                                                            y.dT1List.map((zz, i4) => (
                                                                                                <span>
                                                                                                    {zz.attr.map((zy, i5) => (
                                                                                                        <span>{zy.Cname}</span>
                                                                                                    ))}
                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ))
                                                                                        ) : (
                                                                                            y.dM3 == "CYM" ? (
                                                                                                                y.doc.UExDocChk == '1' ? (
                                                                                                    <span>
                                                                                                                        {y.dT3}{y.doc.EndY}年{y.doc.EndM}月
                                                                                                    </span>
                                                                                                                ): (
                                                                                                                <span> {y.dT3}&nbsp; &nbsp; 年&nbsp; &nbsp; 月</span>
                                                                                                            )
                                                                                               
                                                                                            ) : (
                                                                                                <span> </span>
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    ) : (
                                                                        <span>

                                                                        </span>
                                                                    )
                                                                ) : (
                                                                            <span>

                                                                                {y.doc.attFlag == "Y" ? (
                                                                                    <span> <label style={{ fontSize: "16px" }}>☑</label>&nbsp; {y.docName}</span>
                                                                                ): (
                                                                                        <span> <label style={{ fontSize: "16px" }}>☐</label>&nbsp; {y.docName}</span>
                                                                                    ) }
                                                                      
                                                                    </span>
                                                                )}
                                                                </td>
                                                                {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt + 10}></td>)}
                                                                {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt + 10}></td>)}
                                                        </tr>
                                                    )
                                                    :
                                                    (


                                                            y.docNo == "C001" ? (
                                                                <tr>
                                                                    <td width="25" className="datatable-bg1">{y.no}</td>
                                                                    <td>
                                                                        {
                                                                            y.item.map((yy, y2) => (
                                                                                <div>
                                                                                    {yy.doc.UExDocChk == '1' ? (
                                                                                        <span> <label style={{ fontSize: "16px" }}>☑</label>&nbsp; {yy.docName}</span>
                                                                                    ) : (
                                                                                        <span> <label style={{ fontSize: "16px" }}>☐</label>&nbsp; {yy.docName}</span>
                                                                                    )
                                                                                    }
                                                                                   
                                                                                    {yy.docMark1 != null && (
                                                                                        <span>：
                                                                                            {yy.dM1 == "T" ? (
                                                                                                <span>
                                                                                                    {yy.dT1}&nbsp;&nbsp;&nbsp;&nbsp;{yy.doc.UExDocMark1 }
                                                                                                </span>
                                                                                            ) : (
                                                                                                yy.dM1 == "N" ? (
                                                                                                    <span><br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        {yy.dT1}<input type="number" value={yy.doc.UExDocMark1} name={yy.docNo + yy.dM1} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark1")} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    yy.dM1 == "R" ? (
                                                                                                        yy.dT1List.map((zz, i4) => (
                                                                                                            <span>
                                                                                                                {zz.attr.map((zy, i5) => (
                                                                                                                    <span>
                                                                                                                        <input type="radio" value={zy.value} name={zz.docMark + i4} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, zz.docMark + i4, "R")} />{zy.Cname}
                                                                                                                    </span>
                                                                                                                ))}
                                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        yy.dM1 == "CYM" ? (
                                                                                                            <span>
                                                                                                                {yy.dT1}
                                                                                                                <select id="" name={yy.docNo + yy.dM1 + yy.doc.StarY} value={yy.doc.StarY} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(66)].map((value, j) => (
                                                                                                                        <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                年
                                                                                                                <select id="" name={yy.docNo + yy.dM1 + yy.doc.StarM} value={yy.doc.StarM} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                    <option value="" selected="selected">請選擇...</option>
                                                                                                                    {[...new Array(12)].map((value, j) => (
                                                                                                                        <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                    ))}
                                                                                                                </select>
                                                                                                                月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            <span> </span>
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                                    {yy.docMark2 != null && (
                                                                                        yy.dM2 == "T" ? (
                                                                                            <span>
                                                                                                <input type="text" value={yy.doc.UExDocMark2} name={yy.docNo + yy.dM2} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark2")} />{yy.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            </span>
                                                                                        ) : (
                                                                                            yy.dM2 == "N" ? (
                                                                                                <span>
                                                                                                    <input type="number" value={yy.doc.UExDocMark2} name={yy.docNo + yy.dM2} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark2")} />{yy.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ) : (
                                                                                                yy.dM2 == "R" ? (
                                                                                                    yy.dT1List.map((zz, i4) => (
                                                                                                        <span key={"Rzz" + i4} >
                                                                                                            {zz.attr.map((zy, i5) => (
                                                                                                                <span key={"Rzy" + i5} >
                                                                                                                    <input type="radio" value={zy.value} name={zz.docMark + i4} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, zz.docMark + i4, "R")} />{zy.Cname}
                                                                                                                </span>
                                                                                                            ))}
                                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ))

                                                                                                ) : (
                                                                                                    yy.dM2 == "CYM" ? (
                                                                                                        <span>
                                                                                                            {yy.dT2}
                                                                                                            <select id="" name={yy.docNo + yy.dM2 + yy.doc.StarY} value={yy.doc.StarY} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarY") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(66)].map((value, j) => (
                                                                                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            年
                                                                                                            <select id="" name={yy.docNo + yy.dM2 + yy.doc.StarM} value={yy.doc.StarM} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "StarM") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(12)].map((value, j) => (
                                                                                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span> </span>
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )

                                                                                    )}
                                                                                    {yy.docMark3 != null && (
                                                                                        yy.dM3 == "T" ? (
                                                                                            <span>
                                                                                                <input type="text" value={yy.doc.UExDocMark3} name={yy.docNo + yy.dM3} style={{ width: "100px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark3")} />{yy.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            </span>
                                                                                        ) : (
                                                                                            yy.dM3 == "N" ? (
                                                                                                <span>
                                                                                                    <input type="number" value={yy.doc.UExDocMark3} name={yy.docNo + yy.dM3} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, "UExDocMark3")} />{yy.dT3}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                </span>
                                                                                            ) : (
                                                                                                yy.dM3 == "R" ? (
                                                                                                    yy.dT1List.map((zz, i4) => (
                                                                                                        <span key={"zz" + i4} >
                                                                                                            {zz.attr.map((zy, i5) => (
                                                                                                                <span key={"zy" + i5} >
                                                                                                                    <input type="radio" value={zy.value} name={zy.name} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, yy.doc.DocNo, e.target.value, zz.docMark + i4, "R")} />{zy.Cname}
                                                                                                                </span>
                                                                                                            ))}
                                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        </span>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    yy.dM3 == "CYM" ? (
                                                                                                        <span>
                                                                                                            {yy.dT3}
                                                                                                            <select id="" name={yy.docNo + yy.dM3 + yy.doc.EndY} value={yy.doc.EndY} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "EndY") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(66)].map((value, j) => (
                                                                                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            年
                                                                                                            <select id="" name={yy.docNo + yy.dM3 + yy.doc.EndM} value={yy.doc.EndM} onChange={(e) => { setDoc(i, i2, yy.doc.DocNo, e.target.value, "EndM") }}>
                                                                                                                <option value="" selected="selected">請選擇...</option>
                                                                                                                {[...new Array(12)].map((value, j) => (
                                                                                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                            月
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span> </span>
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )}
                                                                                    <br /> <span id={yy.docNo} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", fontWeight: "bold" }}>請填入【資料】</span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </td>
                                                                    {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={i2 == "0" ? x.Cnt : "0"}></td>)}
                                                                    {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={i2 == "0" ? x.Cnt : "0"}></td>)}
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <td width="25" className="datatable-bg1">{y.no}</td>
                                                                    <td className="datatable-bg1"  >

                                                                        {y.docRefFile != "N" ? (
                                                                            y.docDisplayChild != null ? (
                                                                                    <span>

                                                                                        {y.doc.attFlag == "Y" ? (
                                                                                            <span> <label style={{ fontSize: "16px" }}>☑</label>&nbsp; {y.docName}</span>
                                                                                        ) : (
                                                                                            <span> <label style={{ fontSize: "16px" }}>☐</label>&nbsp; {y.docName}</span>
                                                                                        )}

                                                                                    </span>
                                                                            ) : (
                                                                                <span>

                                                                                </span>
                                                                            )
                                                                        ) : (
                                                                            <span>
                                                                                {y.docName}
                                                                                <font color="red">※上傳相關證明後，此項目將於表單列印時自動勾選。</font>

                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt + 10}></td>)}
                                                                    {i2 == "0" && (<td width="70" className="datatable-bg1" rowSpan={x.Cnt + 10}></td>)}
                                                                </tr>

                                                            )




                                                    )

                                            ) : (
                                                <tr className="hide"><td></td></tr>
                                            )
                                        )


                                    ))}

                                </tbody>
                            </table>

                        ))
                        }
                        {/*經歷*/}
                        <table className="fileTd datatable">
                            <tbody>
                                <tr>
                                    <td colSpan="5" className="datatable-bg4">※上述所有證件於進入複試報名時審查，正本驗畢當場發還。</td>
                                </tr>
                                <tr>
                                    <td width="104" rowSpan="4" align="center" className="datatable-bg2">經歷</td>
                                </tr>

                                {resumeList.map((x, index) => (
                                    <tr key={index} >
                                        {(x.UserROrgName != null && (
                                            <td colSpan="4" valign="top" className="datatable-bg2">
                                                <u>{x.UserROrgName}</u>機關（學校）<u>{x.UserRJobName}</u>（職稱）服務期間：{x.resumeYs}&nbsp; 年&nbsp;{x.resumeMs}&nbsp; 月&nbsp;{x.resumeDs}&nbsp; 日 至 {x.resumeYe}&nbsp; 年&nbsp;{x.resumeMe}&nbsp; 月&nbsp;{x.resumeDe}&nbsp; 日
                                            </td>
                                            ))}
                                       
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/*資績分數自評分*/}
                        <table>
                            <tbody>
                                <tr>
                                    <td>資績分數自評分</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                            <tbody>
                                <tr>
                                    <td colSpan="6"><b>學歷部分(最高2分)</b></td>
                                </tr>
                                <tr>
                                    <td colSpan="3">最高學歷</td>
                                    <td>檔案上傳狀態</td>
                                    <td>自評分</td>
                                    <td>採計分</td>
                                </tr>
                                {upeduFile.map((item, i) => (

                                    <tr>
                                        <td colSpan="3">{item.uExDocMark1}</td>
                                        <td>{item.attFlag == "Y" ? (
                                            <span>
                                                <font color="blue">已上傳成功</font>
                                                {item.file.map((item2, i2) => (
                                                    <a href="#" onClick={(e) => openModal("", item2.base64String)} ><font color="red">(預覽)</font></a>
                                                ))}
                                            </span>
                                        ) : (
                                            <span>無資料</span>
                                        )}</td>
                                        <td>{upQ100.UQualScore}</td>
                                        <td>{upQ100.UQualScoreCF}</td>
                                    </tr>
                                ))}
                                
                                <tr>
                                    <td colSpan="6"><b>經歷部分(最高18分)</b></td>
                                </tr>
                                <tr>
                                    <td>學年度</td>
                                    <td>服務學校</td>
                                    <td>服務時間起迄</td>
                                    <td>檔案上傳狀態</td>
                                    <td>自評分</td>
                                    <td>採計分</td>
                                </tr>
                                {upsvrcert.map((item, i) => (

                                    <tr>
                                        <td>{item.userSCYear}</td>
                                        <td>{item.userSCCityNo}&nbsp;{item.job}&nbsp;{item.sch}&nbsp;{item.userSCSchName}</td>
                                        <td>自{item.ys}年{item.ms}月{item.ds}日&nbsp; 
                                            至&nbsp;{item.ye}年{item.me}月{item.de}日</td>
                                        <td>{item.userSCATTID != null ? (
                                            <span>
                                                <font color="blue">已上傳成功</font>
                                                {item.file.map((item2, i2) => (
                                                    <a href="#" onClick={(e) => openModal("", item2.base64String)} ><font color="red">(預覽)</font></a>
                                                ))}
                                            </span>

                                        ) : (
                                                <span>服務年資　<font color="#A0A0A0">無資料</font></span>
                                        )}</td>
                                        <td>{item.uSerSCScore}</td>
                                        <td>{item.UQualScoreCF}</td>
                                    </tr>
                                ))}
                                {Object.keys(upsvr3Y).length > 0  && (
                                    <tr>
                                        <td colSpan="4"> <span>
                                            <label style={{ fontSize: "16px" }}>☑</label>{upsvr3Y.QualName}
                                        </span></td>
                                        <td>{upsvr3Y.UQualScore}</td>
                                        <td>{upsvr3Y.UQualScoreCF}</td>
                                    </tr>
                                    )}
                                
                             


                                {upfileList.map((item, i) => (

                                    <tr>
                                        <td colSpan="3">{item.DocName}</td>
                                        <td>
                                            <span>
                                                <font color="blue">已上傳成功</font>
                                                {item.DocNo == "C010-1" || item.DocNo == "C010-2" ? (
                                                   
                                                    <a href="#" onClick={(e) => openModalEng()} ><font color="red">(預覽)</font></a>
                                                   
                                                ): (
                                                         <a href="#" onClick={(e) => openModal(item.DocNo,"")} ><font color="red">(預覽)</font></a>
                                                    ) }
                                               
                                            </span>

                                        </td>
                                        <td>{item.UQualScore}</td>
                                        <td>{item.UQualScoreCF}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="4">經歷部分總分</td>
                                    <td>{upQ200.UQualScore}</td>
                                    <td>{upQ200.UQualScoreCF}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td>戶籍謄本&原住民</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                            <tbody>
                                <tr>
                                    <td width="75%">項目</td>
                                    <td>檔案上傳狀態</td>
                                </tr>
                                {upaborigineList.map((item, i) => (

                                    <tr>
                                        <td>{item.docName}</td>
                                        <td>{item.file.length > 0 ? (
                                            <span>
                                                <font color="blue">已上傳成功</font>
                                                {item.file.map((item2, i2) => (
                                                   /* <a data-fancybox="edu" href={item2.base64String} data-src="">(預覽)</a>*/
                                                     <a href="#" onClick={(e) => openModal("",item2.base64String)} ><font color="red">(預覽)</font></a>
                                                ))}
                                            </span>

                                        ) : (
                                                <span><font color="#A0A0A0">無資料</font></span>
                                        )}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td>獲獎免初試證明</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                            <tbody>
                                <tr>
                                    <td width="75%">項目</td>
                                    <td>檔案上傳狀態</td>
                                </tr>
                                {upAwardsFile.map((item, i) => (

                                    <tr>
                                        <td>{item.docName}</td>
                                        <td>{item.file.length > 0 ? (
                                            <span>
                                                <font color="blue">已上傳成功</font>
                                                {item.file.map((item2, i2) => (
                                                    <a href="#" onClick={(e) => openModal("", item2.base64String)} ><font color="red">(預覽)</font></a>
                                                ))}
                                            </span>

                                        ) : (
                                                <span><font color="#A0A0A0">無資料</font></span>
                                        )}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td>雙語資格證明</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                            <tbody>
                                <tr>
                                    <td width="75%">項目</td>
                                    <td>檔案上傳狀態</td>
                                </tr>
                                {upbilingualFile.map((item, i) => (
                                    
                                    <tr>
                                        <td>{item.docName}</td>
                                        <td>{item.file.length > 0 ? (
                                            <span>
                                                <font color="blue">已上傳成功</font>
                                                {item.file.map((item2, i2) => (
                                                     <a href="#" onClick={(e) => openModal("",item2.base64String)} ><font color="red">(預覽)</font></a>
                                                ))}
                                            </span>

                                        ): (
                                                <span><font color="#A0A0A0">無資料</font></span>
                                            )}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        
                        <div align="center"><br />
                            {/*<input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />*/}
                        </div>
                    </div>

                </div>

                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBaseItem} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modal-showImgEng" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>

                               
                                {(upEngList.length > 0 && (
                                    upEngList.map((item, i) => (
                                        item.map((item2, i2) => (
                                        <img id="viewImg" src={item2.base64String} name="Std_img" style={{ width: "100%" }} />
                                        ))
                                    ))
                                    ))
                                    }

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModalEng} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>                             

            </div>
        </>
    );
}


export default ModifyChinfo;
