import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_Front_getFrontSet,
    API_POST_Login_ScoreSingin
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import ClientCaptcha from 'react-client-captcha';
import { checkAuthToken } from "../global/TokenUtil";
import Swal from 'sweetalert2';

import login_bt from '../images/login/login-bt.jpg';
import png_0010 from '../images/index/png-0010.png';

function Search2() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    const [UIDNo, setUIDNo] = useState(""); //身分證號
    const [Password, setPassword] = useState(""); //密碼
    const [captchaCode, setCaptchaCode] = useState('');//驗證碼圖片
    const [UserCaptchaCode, setUserCaptchaCode] = useState('');//User驗證碼

    const [findPwdCodeTimeShow, setfindPwdCodeTimeShow] = useState(false);//驗證碼到期時間顯示

    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    useEffect(() => {
        localStorage.setItem("cookieCrack", "53");
        getFrontSet();
    }, []);

    //檢查必填 密碼 身分證號 驗證碼
    function formRequired(selector) {
        var result = true;
        const requiredlist = document.querySelectorAll(selector + " [data-required]");
        requiredlist.forEach((element) => {

            if (element.value == "") {
                element.classList.add("error");
                result = false;
            } else {
                element.classList.remove("error");
            }
        });

        return result;
    }

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_Front_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                //if (!checkAuthToken(data)) {
                //    window.location.href = "../";
                //}
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        //setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    //if need 下一步請求
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }


    //重新設定
    function Reset() {
        const requiredlist = document.querySelectorAll("[data-required]");
        requiredlist.forEach((element) => {

            element.classList.remove("error");
        });
        //console.log('重新設定');
        setUIDNo(""); //身分證號
        setPassword(""); //密碼
        setUserCaptchaCode(""); //User驗證碼
    }

    //檢查密碼 身分證號 驗證碼
    function chkData(type) {
        //console.log('檢查密碼 身分證號 驗證碼');
        var result = true;
        var err = "";

        // 身分證字號
        //var taiwanIdValidator = require("taiwan-id-validator2");標準檢驗

        //if (taiwanIdValidator.isNationalIdentificationNumberValid(UIDNo) == false) {
        if (!/^\d{4}$/.test(UIDNo)) {
            err += '輸入身分證字號末四碼<br/>';
            result = false;
        }

        if (type == 'login') {
            if (Password.length < 0) {
                err += '准考證號碼不能為空<br/>';
                result = false;
            }
            if (captchaCode.toLowerCase() != UserCaptchaCode.toLowerCase()) {
                err += '驗證碼輸入錯誤<br/>';
                result = false;
            }
        }
        if (err != "") {
            Swal.fire({
                icon: 'error',
                html: err,
            })
        }
        return result;
    }

    //儲存，下一步
    function Next() {
        console.log('下一步');
        //document.getElementById("loading").style.display = "block";
        //console.log(document.querySelectorAll("#login [data-required]"));
        var result_Required = formRequired('#login');
        if (result_Required) {
            var result_chkData = chkData("login");

            if (result_chkData === true) {
                console.log('儲存');
//TODO api內建examNo 需後端配合
                API_POST_Login_ScoreSingin({
                    "ExamNo": "CurrExNoB",
                    "UserIDNo": UIDNo,
                    "UserPassNo": Password,
                })
                    .then((response) => response.data)
                    .then((data) => {
                        console.log(data);
                        localStorage.clear();
                        if (!checkAuthToken(data)) {
                            /* window.location = "../signup/Signin";*/
                            //history.push("../signup/Signin");
                        }
                        if (data.code == "0000") {
                           
                            if (data.result  && data.result.length > 0) {
                                let userPass = {
                                    "UserPassNo": data.result[0].UserPassNo, //准考證號
                                    "SResult": data.result[0].ExamNo,   //初試複試
                                }
                                localStorage.setItem("userPass", JSON.stringify(userPass));
                                window.location = "/SearchR/score";
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: "資料不齊全,無法登入",
                                })
                                //    .then((result) => {
                                //    window.location = "/Search/index";
                                //});
                                document.getElementById("loading").style.display = "none";
                                
                                //history.push("/Search/index");
                                setUserCaptchaCode(""); //User驗證碼
                                document.getElementById("retryButton").click();
                            }
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: data.message,
                            });
                            setUserCaptchaCode(""); //User驗證碼
                            document.getElementById("retryButton").click();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 401 || err.response.data.code == "1001") {
                            localStorage.clear();
                            history.push("/");
                        }
                    });
            }
        }

    }

    function goEnter(e) {
        if (e.key === "Enter") {
            if (UIDNo && Password && UserCaptchaCode) {
                Next();
            }
        }
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="線上查分" modalSide="" modalUrl={window.location.pathname} />
                <div id="content">
                    <div id="main">
                        <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>

                        <fieldset>
                            <legend>複試查分</legend>
                            <table id="login" width="650" border="0" cellPadding="5" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td width="50">准考證號碼：</td>
                                        <td width="608">
                                            <input name="login_pwd" type="text" style={{ marginLeft: "-60px" }} className="Ariel" id="login_pwd" size="12" maxLength="10" value={Password} onChange={(e) => { setPassword(e.target.value) }} onKeyPress={goEnter} data-required />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>身分證字號(末四碼)：</td>
                                        <td>
                                            <input name="login_id" type="text" style={{ marginLeft: "-60px" }} className="Ariel" id="login_id2" size="12" maxLength="4" value={UIDNo} onChange={(e) => { setUIDNo(e.target.value) }} onKeyPress={goEnter} data-required />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><ClientCaptcha captchaCode={(code) => setCaptchaCode(code)} chars="0123456789" /></td>
                                        <td>
                                            <input name="NewCode" type="text" className="input" style={{ marginLeft: "-60px" }} id="NewCode" maxLength="4" size="12" value={UserCaptchaCode} onChange={(e) => { setUserCaptchaCode(e.target.value) }} onKeyPress={goEnter} data-required /> (請根據左方顯示輸入驗證碼)
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input type="button" name="Submit" value="登入" onClick={Next} />
                                            <input type="button" name="Submit2" value="重新設定" onClick={Reset} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default Search2;
