import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_RTConfirm_RegData,
    API_Get_Print_RTRegFormPDF
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';
import getDate from "date-fns/esm/getDate/index.js";

function Second_show_From() {
    const history = useHistory();

    const [fileData, setFileData] = useState([]);
    const [errText, setErrText] = useState("資料載入中");

    const UserData = JSON.parse(localStorage.getItem('UserData'));

    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [blobFile, setBlobFile] = useState("");

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        //console.log("Next");
                        //console.log(obj3.NextUrl);
                    }
                });
            });
        });

        getFrontSet();
    }, []);

    //取得資料
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);

                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getVaildFrom();
                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

    }

    function getVaildFrom() {
        API_Get_RTConfirm_RegData({
            "UserEKID": UserData.userEKID
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    let isB001 = false, isB002 = false, isWish = false;
                    let checkFile = true;
                    if (data && data.result && data.result.docList && data.result.docList.length > 0) {
                        for (let obj of data.result.docList) {
                            if (obj.DocNo == "B001") {
                                if (obj.UExDocATTID.includes("成功")) {
                                    isB001 = true;
                                }
                            } else if (obj.DocNo == "B002") {
                                if (obj.UExDocATTID.includes("成功")) {
                                    isB002 = true;
                                }
                            } else if (obj.DocNo == "Wish") {
                                if (obj.UExDocATTID.includes("已填寫")) {
                                    isWish = true;
                                }
                            }
                            if (isB002 && isB001 && isWish) { break; }
                        }
                    }
                    if(checkFile){alert("測試下載模式");isB001 = true; isB002 = true;}
                    if (!isB002 || !isB001) {
                        setErrText("必傳資料：國民身分證 或 畢業證書 尚未上傳完整，請至 各項報名表狀態 確認進度");
                    } else if (!isWish) {
                        setErrText("必填資料：志願填寫 尚未填寫完整，請至 各項報名表狀態 確認進度");
                    } else {
                        setErrText("")
                        //getData();
                    }
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("loading").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }
    //取得下載檔
    function getData() {
        Swal.fire({
            icon: 'warning',
            text: "報名表下載後，無法再修改複試報名資料，確定要下載? ",
        }).then((result) => {
            if (result.isConfirmed) {
                document.getElementById("loading").style.display = "block";
                if (blobFile) {
                    window.open(blobFile, "_blank");
                } else {
                    console.log("請求列印");
                    API_Get_Print_RTRegFormPDF({
                        "UserEKID": UserData.userEKID
                    }).then((response) => response.data)
                        .then((data) => {
                            console.log(data);
                            if (!checkAuthToken(data)) {
                                window.location.href = "../";
                            }

                            if (data.code == "0000") {
                                try {
                                    b64toBlob(data.result.Base64);
                                } catch (e) {
                                    console.log(e);
                                    Swal.fire({
                                        icon: 'error',
                                        text: "本地接收失敗，請重新下載",
                                    })
                                }
                            }
                            else if (data.code == "4007") {//未核發淮考證
                                setErrText(data.message);
                            } else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })
                            }
                            document.getElementById("loading").style.display = "none";
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push('/');
                            }
                            document.getElementById("loading").style.display = "none";
                        })
                }
            }
        });
    }
    //將base64檔
    function b64toBlob(b64Data) {
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        console.log(url);
        window.open(url, "_blank");
        setBlobFile(url);
        return url;
    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //history.push(next);
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    <table className="datatable">
                        <tbody>
                            <tr>
                                <th width="40">項目</th>
                                <th width="438">表件名稱</th>
                                <th width="226">備註</th>
                            </tr>

                            {errText ?
                                <tr>
                                    <td colSpan="3">{errText}</td>
                                </tr> :
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <p onClick={() => getData()} style={{ color: "blue", cursor: "pointer", textDecoration: "underline", fontWeight: "bold", display: "inline-block" }} >
                                            <font size="4">
                                                下載報名表
                                            </font>
                                        </p>
                                    </td>
                                    <td>
                                        <font size="4">
                                            報名表下載後將無法編輯複試報名資料及檔案上傳。
                                            <br />請將報名表文件列印後妥善保存。
                                        </font>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    <div align="center">
                        <input name="button1" type="button" onClick={(e) => Next()} value="下一步" style={{ width: "80px", height: "35px", margin: "0.4rem 0" }} />
                    </div>

                </div>
            </div>

        </>
    );
}


export default Second_show_From;
