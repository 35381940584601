import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
	API_Get_UpFile_getFrontSet,
	API_Get_Front_PayInfo
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';

function modify_Bankinfo_Msg() {
	const history = useHistory();

	const [userPayNo, setUserPayNoo] = useState(""); //帳號
	const [userPayMoney, setUserPayMoney] = useState(""); //金額
	const [userPayDate, setUserPayDate] = useState(""); //繳費期限
	const [exSchdStartDate, setExSchdStartDate] = useState(""); //繳費開始日期
	const [exSchdEndDate, setExSchdEndDate] = useState(""); //繳費結束日期

	const UserData = JSON.parse(localStorage.getItem('UserData'));

	const menuList = JSON.parse(localStorage.getItem('menuList'));
	const [next, setnext] = useState(""); //下一步
	const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
	const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
	const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

	useEffect(() => {

		menuList.map((obj, i) => {
			obj.subMenu.map((obj2, i2) => {
				obj2.subMenu.map((obj3, i3) => {
					if (obj3.FuncUrl == window.location.pathname + location.search) {
						setnext(obj3.NextUrl);
						//console.log("Next");
						//console.log(obj3.NextUrl);
					}
				});
			});
		});

        getData();
	}, []);

	//取得資料
	function getData() {
		document.getElementById("loading").style.display = "block";
		API_Get_UpFile_getFrontSet({
			"FrontUrl": window.location.pathname,
			"FrontUrlCode": null
		}).then((response) => response.data)
			.then((data) => {
				if (!checkAuthToken(data)) {
					window.location.href = "../";
				}
				//console.log(data);
				if (data.code == "0000") {
					console.log(data);

					data.result.map((obj, i) => {
						if (obj.frontUrlTextCode == "page") {
							setUdocMark_p(obj.frontUrlText)
						}
						if (obj.frontUrlTextCode == "top") {
							setUdocMark_t(obj.frontUrlText)
						}
						if (obj.frontUrlTextCode == "bottom") {
							setUdocMark_b(obj.frontUrlText)
						}
					})

					API_Get_Front_PayInfo({
						"UserEKID": UserData.userEKID
					}).then((response) => response.data)
						.then((data) => {
							if (!checkAuthToken(data)) {
								window.location.href = "../";
							}
							//console.log(data);
							
							if (data.code == "0000") {
								console.log(data);
								if (data.result.userPayNo == null) {
									document.getElementById("paytype").innerHTML = "<font color='red'>(請至 3-1 核對報名表資料，確認報名資料無誤後，點選「確認無誤」後，才可產生繳費資訊。。)</font>";
									Swal.fire({
										icon: 'warning',
										text: '請至【3-1 核對報名表資料】，確認報名資料無誤後，點選「確認無誤」後，才可產生繳費資訊。'
									})
								} else {
									setUserPayNoo(data.result.userPayNo)
									setUserPayMoney(data.result.userPayMoney)

									var Ys = data.result.exSchdStartDate.slice(0, 4) - 1911;
									var Ms = parseInt(data.result.exSchdStartDate.slice(5, 7), 10);
									var Ds = parseInt(data.result.exSchdStartDate.slice(8, 10), 10);
									var ds = new Date(data.result.exSchdStartDate);
									switch (ds.getDay()) {
										case 0:
											var ws = "日";
											break;
										case 1:
											var ws = "一";
											break;
										case 2:
											var ws = "二";
											break;
										case 3:
											var ws = "三";
											break;
										case 4:
											var ws = "四";
											break;
										case 5:
											var ws = "五";
											break;
										case 6:
											var ws = "六";
											break;
									}


									var Ye = data.result.exSchdEndDate.slice(0, 4) - 1911;
									var Me = parseInt(data.result.exSchdEndDate.slice(5, 7), 10);
									var De = parseInt(data.result.exSchdEndDate.slice(8, 10), 10);
									var de = new Date(data.result.exSchdEndDate);
									console.log(de.getDay());
									switch (de.getDay()) {
										case 0:
											var we = "日";
											break;
										case 1:
											var we = "一";
											break;
										case 2:
											var we = "二";
											break;
										case 3:
											var we = "三";
											break;
										case 4:
											var we = "四";
											break;
										case 5:
											var we = "五";
											break;
										case 6:
											var we = "六";
											break;
									}

									setUserPayDate("自" + Ys + "年" + Ms + "月" + Ds + "日（星期" + ws + "）10:00至" + Ye + "年" + Me + "月" + De + "日（星期" + we +"）15:30止。")
									
                                }
								document.getElementById("loading").style.display = "none";
							}
							else if (data.code == "9998") {
								Swal.fire({
									icon: 'error',
									text: data.message,
								}).then((result) => {
									if (data.result && data.result.redirectUrl) {
										window.location.href = data.result.redirectUrl;
									} else {
										window.location.href = "/";
									}
								});
								document.getElementById("loading").style.display = "none";
							}
							else {
								Swal.fire({
									icon: 'error',
									text: data.message,
								})
								document.getElementById("loading").style.display = "none";
							}
						})
						.catch(err => {
							console.log(err);
							if (err.response.status === 401 || err.response.data.code == "1001") {
								localStorage.clear();
								history.push('/');
							}
						})
				}
				else if (data.code == "9998") {
					Swal.fire({
						icon: 'error',
						text: data.message,
					}).then((result) => {
						if (data.result && data.result.redirectUrl) {
							window.location.href = data.result.redirectUrl;
						} else {
							window.location.href = "/";
						}
					});
					document.getElementById("loading").style.display = "none";
				}
				else {
					Swal.fire({
						icon: 'error',
						text: data.message,
					})
				}
			})
			.catch(err => {
				console.log(err);
				if (err.response.status === 401 || err.response.data.code == "1001") {
					localStorage.clear();
					history.push('/');
				}
			})


	}

	//下一步驟
	function Next() {
		window.location.href = next;
        //history.push(next);
	}

    return (
        <>
            <div id="master-main-bg">
				<LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
					<div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
					<div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
					<div >
						<div class="faqs">
							<ol>
								<li>繳款方式。</li>
								<div id="paytype">
									繳款方式：實體ATM、網路ATM、網路銀行<br />
									初試報名費：新臺幣 {userPayMoney}元整。（轉帳之手續費自行另付）。<br />
									轉帳銀行別：012&nbsp;台北富邦銀行&nbsp;<br />
									轉帳帳號(專屬帳號)：{userPayNo}<br />
									轉帳時間：{userPayDate}。
								</div>
								<li>※若逾時轉帳繳費，則無法報名，請應考者注意。</li>
								<div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>

							</ol>
						</div>
					</div><br />
					<div align="center"><br />
						<input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
					</div>
					
					
					
                </div>
            </div>



        </>
    );
}


export default modify_Bankinfo_Msg;
