import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_Carousel } from '../global/constants';
import LeftSideW from '../components/partial/leftSide';

function Second_bankact() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    useEffect(() => {

    }, []);
    /*修改個人金融帳戶*/
    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <h1>上傳個人金融帳戶</h1>
                    <div >
                        <font color="blue" size="3" >

                        </font>
                        <br />
                        <font color="red">

                        </font>
                    </div>

                    <div id="status">

                    </div>

                    <div id="main">
                        <span className="color-3">※ <strong>「＊」</strong></span>為必填欄位 / 欄位內容勿留空格<br />
                        <span className="color-3">※請上傳 .jpg、.png 檔案，檔案大小請小於2MB</span>
                        <br />
                        <br />
                        <form>
                            <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                                <tr>
                                    <td>
                                        <label for="bankcode"><font color="#FF0000">＊</font>銀行代碼</label>
                                        <input id="bankcode" type="text" className="form-control" name="bankcode" value="822" required="" /><br />
                                        <a href="https://www.fisc.com.tw/tc/service/branch.aspx" target="_blank"><small> 財政部跨行通匯金融機構代號查詢 </small></a>
                                    </td>
                                    <td>
                                        <label for="bankname"><font color="#FF0000">＊</font>銀行名稱</label>
                                        <input id="bankname" type="text" className="form-control" name="bankname" value="CTBC bank" required="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label for="branchcode"><font color="#FF0000">＊</font>分行代碼</label>
                                        <input id="branchcode" type="text" className="form-control" name="branchcode" value="392" required="" />
                                    </td>
                                    <td>
                                        <label for="branchname"><font color="#FF0000">＊</font>銀行名稱</label>
                                        <input id="branchname" type="text" className="form-control" name="branchname" value="1" required="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label for="bankUAcc"><font color="#FF0000">＊</font>銀行帳號</label>
                                        <input id="bankUAcc" type="text" className="form-control" name="bankUAcc" value="00000000000000" required="" />
                                    </td>
                                    <td>
                                        <label>銀行戶名</label>
                                        <input type="text" className="form-control" name="" value="測試2葦" required="" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <label><font color="#FF0000">＊</font>存摺封面 (有戶名&amp;帳號) - 檔案上傳</label>
                                        <div className="custom-file mb-3 text-center">
                                            <label className="btn btn-info" for="cover">選擇檔案</label>
                                            <input type="file" id="cover" name="files" accept="image/*" className="dp_none" />
                                        </div>
                                        <br />
                                        <font color="blue">已上傳成功</font><a data-fancybox="1" href="#" data-src="%"><font color="red">(預覽)</font></a>
                                        <br />
                                        <img src="" name="cover_img" height="150" />
                                        <input type="hidden" id="coverFile" value="Y" />
                                    </td>
                                </tr>
                            </table>
                        </form>

                        <div align="center">
                            <input name="button" type="button" id="saveBtn" value="儲存" />
                            <input name="button" type="button" onClick={() => (location.href = 'Basic_Doc')} value="下一步" />
                        </div>

                    </div>

                </div>
            </div>



        </>
    );
}


export default Second_bankact;
