import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_Get_Wish_QueryWishData,
    API_PUT_Wish_UPWishData
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';
import Swal from 'sweetalert2';
import { sortable } from 'react-sortable';


function Second_Ambition() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");

    const [stuKind, setStuKind] = useState({});//考生志願資料
    const [itemList, setItemList] = useState([/*
        {
            "wishName": "介壽國中",//學校名稱
            "wishOrd": 0,//學校編號
            "wishAmount": 2,//學校釋出名額
        },
        {
            "wishName": "民生國中",
            "wishOrd": 1,
            "wishAmount": 1,
        },
        {
            "wishName": "興雅國中",
            "wishOrd": 2,
            "wishAmount": 2,
        },
        {
            "wishName": "仁愛國中",
            "wishOrd": 3,
            "wishAmount": 1,
        },
        {
            "wishName": "大安國中",
            "wishOrd": 4,
            "wishAmount": 1,
        },
        {
            "wishName": "中正國中",
            "wishOrd": 5,
            "wishAmount": 1,
        },
        {
            "wishName": "蘭雅國中",
            "wishOrd": 6,
            "wishAmount": 1,
        },
        {
            "wishName": "新民國中",
            "wishOrd": 7,
            "wishAmount": 1,
        },
        {
            "wishName": "石牌國中",
            "wishOrd": 8,
            "wishAmount": 1,
        },
        {
            "wishName": "和平高中(國中部)",
            "wishOrd": 9,
            "wishAmount": 1,
        }
    */]);//志願排序
    const [flowItem, setFlowItem] = useState();
    const [orginList, setOrginList] = useState([]);//志願原狀態
    const [editMode, setEditMode] = useState(false);//編輯中

    const UserData = JSON.parse(localStorage.getItem('UserData'));//使用者資料
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方
    //非雙語類組者顯示文字
    const [nonNative, setNonNative] = useState("");

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();
    }, []);

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    //console.log(data);
                    data.result.map((obj, i) => {
                        //setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {
        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/Second/Form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得資料
    function getData() {
        //return document.getElementById("loading").style.display = "none";
        API_Get_Wish_QueryWishData()
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    if (data.result.uwData) {
                        setOrginList(data.result.uwData.map(x => x));//x=>x 避免寫入記憶體位置
                        setItemList(data.result.uwData.map(x => x));//x=>x 避免寫入記憶體位置
                    } else if (data.result.wbList) {
                        setOrginList(data.result.wbList.map(x => x));//x=>x 避免寫入記憶體位置
                        setItemList(data.result.wbList.map(x => x));//x=>x 避免寫入記憶體位置
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            text: "無志願可填寫",
                        })
                    }
                    setStuKind(data.result.getExamKind[0]);

                    document.getElementById("loading").style.display = "none";

                } else if (data.code == "3015") {//非雙語類組者
                    setNonNative(data.message);
                    Swal.fire({
                        icon: 'warning',
                        text: "非報考雙語師資者，請勿上傳雙語資格證明",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "3037") {//非雙語類組者
                    setNonNative(data.message);
                    Swal.fire({
                        icon: 'warning',
                        text: "初試未勾選上傳附件資料，不可上傳。",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {

                            //history.push('Sign_Status');
                        }
                    })


                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

    }

    //儲存資料
    function updateWish() {
        if (itemList == null || itemList.length == 0) { return; }
        var putObj = new Array;
        for (var i1 = 0; i1 < itemList.length; i1++) {
            console.log(itemList[i1].wishName)
            putObj.push({
                "UserEKID": UserData.userEKID,
                "WishOrd": (i1 + 1),
                "WishNo": itemList[i1].wishNo
            })
        }
        //return console.log(putObj);
        document.getElementById("loading").style.display = "block";
        API_PUT_Wish_UPWishData(putObj)
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/Second/Form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //Sortable 套件 拖拉選單 參考；https://www.npmjs.com/package/react-sortable
    //單筆物件
    class Item extends React.Component {
        render() {
            const otem = itemList.find(x => x.wishName == this.props.children);
            //console.log(this.props["data-id"],this.props.children);
            return (
                <tr {...this.props}>
                    <td style={{ background: (otem.isEdit) }} >
                        <div className="d-inline-flex align-items-center">
                            <span className="d-block px-2">{(this.props["data-id"] + 1)}</span>
                            <div className="d-flex flex-column">
                                <span className="hoverArrow arrowUp" onClick={(e) => moveOneStep(0, this.props["data-id"])} ></span>
                                <span className="hoverArrow arrowDown" onClick={(e) => moveOneStep(1, this.props["data-id"])} ></span>
                            </div>
                        </div>

                    </td>
                    <td style={{ background: (otem.isEdit) }} >
                        {this.props.children}
                    </td>
                    <td style={{ background: (otem.isEdit) }} >
                        {otem.wishAmount}
                    </td>
                </tr>
            )
        }
    }
    var SortableItem = sortable(Item);
    //主體外框
    class SortableList extends React.Component {
        state = {
            items: this.props.items
        };
        onSortItems = (items) => {
            let deColor = items.map(x => {
                x.isEdit = false;
                return x
            })
            this.setState({
                items: deColor
            });
            //console.log(deColor);
            setItemList(deColor);
            setEditMode(true);
        }
        // Watch for changes in the itemList prop
        componentDidUpdate(prevProps) {
            if (prevProps.items !== this.props.items) {
                this.setState({
                    items: this.props.items
                });
            }
        }

        render() {
            const { items } = this.state;
            var listItems = items.map((item, i) => {
                return (
                    <SortableItem
                        key={i}
                        onSortItems={this.onSortItems}
                        items={items}
                        sortId={i}
                        moveonestep={this.props.moveOneStep}>
                        {item.wishName}
                    </ SortableItem>
                );
            });
            //dom元件 此處使用tbody做外框 通常建議使用ul 內部元件用li
            //item資料 此處使用object 通常建議使用array
            return (
                <tbody className="sortable-list">
                    {listItems}
                </tbody>
            )
        }
    };
    //END Sortable 套件
    //移動上下一項目 方向,原項次
    function moveOneStep(dir, preO) {

        let dirNo = itemList.length;
        if (dir === 1) {
            dirNo = preO + 1;
        } else if (dir === 0) {
            dirNo = preO - 1;

        } else {
            return null
        }
        if (dirNo < 0) { dirNo = 0 }
        let fula = [...itemList];
        let pie = fula.splice(preO, 1)[0];
        pie.isEdit = "#beebbe";
        fula = fula.map(x => { x.isEdit = ""; return x });
        fula.splice(dirNo, 0, pie);
        setItemList(fula);
        setEditMode(true);
    }
    //END 拖拉選單

    //重新填寫
    function Reset() {
        setItemList(orginList.map(x => x));//x=>x 避免寫入記憶體位置
        //console.log(orginList)
        setEditMode(false);
    }

    //志願清單編輯是否編輯判定
    //useEffect(() => {
    //    setEditMode(false);
    //    if (itemList.length > 0 && orginList.length > 0) {
    //        for (var i1 = 0; i1 < itemList.length; i1++) {
    //            if (itemList[i1].wishNo != orginList[i1].wishNo) {
    //                console.log(itemList[i1].wishName);
    //                setEditMode(true);
    //                break;
    //            }
    //        }
    //    }
    //}, [itemList]);
    //下一步驟
    function Next() {
        if (!editMode) {
            Swal.fire({
                icon: 'warning',
                text: "請先完成志願儲存",
            })
        } else {
            window.location.href = next;
        }
    }

    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="志願選填" modalSide="志願選填" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>

                    <h1>志願選填</h1>
                    <div id="status">
                        <div style={{ width: "100%", border: "1px solid #f2f2f2", padding: "0.5rem 1rem", textAlign: "left", letterSpacing: "1px" }} >
                            1. 調整順序後請按「儲存」即完成志願選填。
                        </div>
                    </div>

                    <div id="main">
                        <div style={{ padding: "1.5rem 0" }} >
                            <div>
                                {
                                    stuKind == null || Object.keys(stuKind).length == 0 ? null :
                                        "類科：" + (stuKind.ExKindName || "")
                                }
                            </div>
                            <table className="formNews table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }} >志願順序</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }} >學校</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }} >缺額</th>
                                    </tr>
                                </thead>
                                {
                                    itemList.length > 0 ?
                                        <SortableList items={itemList} /> :
                                        <tbody>
                                            <tr>
                                                <td colSpan="3">無可編輯志願</td>
                                            </tr>
                                        </tbody>
                                }
                                <tfoot>
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: "center", verticalAlign: "middle", backgroundColor: "#e3e3e3" }} >
                                            <input type="button" value="儲存" style={{ margin: "0.4rem" }} onClick={updateWish} />
                                            <input type="button" value="重新填寫" style={{ margin: "0.4rem" }} onClick={Reset} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                        <div align="center">
                            <input name="button" type="button" onClick={Next} value="下一步" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Second_Ambition;
