import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_Print_RegFormPDF
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import Swal from 'sweetalert2';

function modify_ShowPaper() {
    const history = useHistory();
    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [uName, setuName] = useState("");//姓名
    const [uidNo, setuidNo] = useState("");//身份證號
    const [exKindName, setexKindName] = useState("");//類組

    const [fileData, setfileData] = useState([]);//下載文件
    const [blobData, setBlobData] = useState();//本地文件位置
    //未核發淮考證顯示文字
    const [nonNative, setNonNative] = useState("");

    useEffect(() => {
        /*getData();*/
    }, []);

    //取得資料
    function getData() {
        document.getElementById("loading").style.display = "block";
        //return null;
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": null
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })

                    API_Get_Print_RegFormPDF({
                        "UserEKID": UserData.userEKID
                    }).then((response) => response.data)
                        .then((data) => {
                            if (!checkAuthToken(data)) {
                                window.location.href = "../";
                            }
                            //console.log(data);

                            if (data.code == "0000") {
                                console.log(data);
                                setuName(UserData.uName);
                                setuidNo(UserData.uidNo);
                                setexKindName(UserData.exKindName);
                                setfileData(data.result);
                                try {
                                    b64toBlob(data.result.Base64);
                                } catch (e) {
                                    Swal.fire({
                                        icon: 'error',
                                        text: "本地接收失敗",
                                    }).then((result) => {
                                        history.push("/");
                                    });
                                }
                                document.getElementById("loading").style.display = "none";
                            } else if (data.code == "4007") {//未核發淮考證
                                setNonNative(data.message);
                                document.getElementById("loading").style.display = "none";
                            } else if (data.code == "9998") {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                }).then((result) => {
                                    if (data.result && data.result.redirectUrl) {
                                        window.location.href = data.result.redirectUrl;
                                    } else {
                                        window.location.href = "/";
                                    }
                                });
                                document.getElementById("loading").style.display = "none";
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                })
                                document.getElementById("loading").style.display = "none";
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            if (err.response.status === 401 || err.response.data.code == "1001") {
                                localStorage.clear();
                                history.push('/');
                            }
                        })

                }
                else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })


    }

    function b64toBlob(b64Data) {
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        console.log(url);
        window.open(url, "_blank");
        setBlobData(url);
        return url;
    }
    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="初試報名系統" modalSide="初試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    {/*<div>*/}
                    {/*    登入者：<span className="color-1">{uName}&nbsp;&nbsp;</span>*/}
                    {/*    身分證號：<span className="color-1">{uidNo}&nbsp;&nbsp;</span>*/}
                    {/*    報考類科：<span className="color-1">{exKindName}</span>*/}

                    {/*</div>*/}
                    <table className="datatable">
                        <tbody>
                            <tr>
                                <th width="40">項目</th>
                                <th width="438">表件名稱</th>
                                <th width="226">備註</th>
                            </tr>
                            {nonNative != "" && (
                                <tr><td colSpan="3">{nonNative}</td></tr>
                            )}

                            {fileData == null || Object.keys(fileData).length == 0 ? 
                                <tr>                                  
                                    <td colSpan="3">
                                        <span>尚未開始列印報名表</span>
                                    </td>
                                </tr>
                                :
                                <tr>
                                    <td>1</td>
                                    <td>
                                        報名表
                                    </td>
                                    <td>{blobData ?
                                        <a href={blobData} download={`113 報名表${UserData.uName}-${uidNo}`} >下載</a> :
                                        <span>檔案生成中...</span>
                                        }</td>
                                </tr>
                                }
                        </tbody>
                    </table>
                </div>
                <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
            </div>



        </>
    );
}


export default modify_ShowPaper;
