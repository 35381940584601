import "../js/jquery.min.js";
import "../css/layout.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import {
    API_Get_UpFile_getFrontSet,
    API_Get_UpFile_getUComplete,
    API_POST_RTUpFile_GetMarkUP,
    API_PUT_RTUpFile_SaveRTFileData
} from '../global/constants';
import LeftSideW from '../components/partial/leftSide';
import { checkAuthToken } from '../global/TokenUtil';
import { upFileMail } from '../global/ToolUtil';
import Swal from 'sweetalert2';

function Second_Other_Doc() {
    const history = useHistory();
    //環境參數
    const [fileMaxSize, setFileMaxSize] = useState(26214400);//檔案尺寸上限
    const [fileType, setFileType] = useState(["image/jpg", "image/jpeg", "image/png"]);//檔案類型限制
    const [fileDotType, setFileDotType] = useState([".jpg", ".png"]);//檔案類型限制
    const [uploadDoc, setUploadDoc] = useState("");//本頁使用檔案類型代號

    const UserData = JSON.parse(localStorage.getItem('UserData'));
    const [fileList, setFileList] = useState([]);//資料容器
    const [itemList, setItemList] = useState([]);//資料容器
    const [imgBase, setImgBase] = useState([]);//伺服器取得圖片容器
    const [imgBaseItem, setimgBaseItem] = useState({});//彈窗圖片容器
    const [file, setFile] = useState();
    const uploadCode = new URLSearchParams(location.search).get("Param");//本頁使用檔案類型代號
    const menuList = JSON.parse(localStorage.getItem('menuList'));
    const [next, setnext] = useState(""); //下一步
    const [docMark_p, setUdocMark_p] = useState("");//說明內容-整頁
    const [docMark_t, setUdocMark_t] = useState("");//說明內容-上方
    const [docMark_b, setUdocMark_b] = useState("");//說明內容-下方

    const [docNo, setDocNo] = useState("");
    const [nonNative, setNonNative] = useState(""); //非雙語類組者顯示文字

    const date = new Date();
    const birYStar = date.getFullYear() - 1911 - 65; //生日年起迄

    useEffect(() => {

        menuList.map((obj, i) => {
            obj.subMenu.map((obj2, i2) => {
                obj2.subMenu.map((obj3, i3) => {
                    if (obj3.FuncUrl == window.location.pathname + location.search) {
                        setnext(obj3.NextUrl);
                        console.log("Next");
                        console.log(obj3.NextUrl);
                    }
                });
            });
        });
        getFrontSet();
    }, []);

    //取得頁面說明文字
    function getFrontSet() {
        document.getElementById("loading").style.display = "block";
        API_Get_UpFile_getFrontSet({
            "FrontUrl": window.location.pathname,
            "FrontUrlCode": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                //console.log(data);
                if (data.code == "0000") {
                    console.log(data);
                    data.result.map((obj, i) => {
                        setUploadDoc(obj.frontUrlCode);
                        if (obj.frontUrlTextCode == "page") {
                            setUdocMark_p(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "top") {
                            setUdocMark_t(obj.frontUrlText)
                        }
                        if (obj.frontUrlTextCode == "bottom") {
                            setUdocMark_b(obj.frontUrlText)
                        }
                    })
                    getUComplete();
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得是否報名表填寫完成
    function getUComplete() {

        API_Get_UpFile_getUComplete().then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    getData();
                } else if (data.code == "3019") {
                    Swal.fire({
                        icon: 'warning',
                        text: "請先確認報名表是否填寫完整並按【下一步】 進行後續操作!",
                    }).then((result) => {

                        history.push("/signup/modify_form");
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                    document.getElementById("loading").style.display = "none";
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
    }

    //取得資料
    function getData() {
        API_POST_RTUpFile_GetMarkUP({
            "DocNo": uploadCode
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);

                if (data.code == "0000") {
                    setItemList(data.result.Item);
                    setDocNo(data.result.DocNo);
                    var item = [];
                    data.result.data.dataList.map((obj, i) => {
                        obj.base64 = "";

                        obj.starY = "";
                        obj.starM = "";
                        obj.endY = "";
                        obj.endM = "";
                        if (obj.file.length > 0) {
                            console.log(imgBase);

                            obj.file.map((obj2, i2) => {
                                obj.base64 = obj2.base64String;
                            });
                        }


                        if (obj.uExDocMark2 && obj.dM2 == "CYM") {
                            obj.starY = (obj.uExDocMark2.slice(0, 4)) - 1911;
                            obj.starM = parseInt(obj.uExDocMark2.slice(5, 7), 10);
                        }
                        if (obj.uExDocMark3 && obj.dM3 == "CYM") {
                            obj.endY = (obj.uExDocMark3.slice(0, 4)) - 1911;
                            obj.endM = parseInt(obj.uExDocMark3.slice(5, 7), 10);
                        }

                        item.push(obj);
                    });
                    console.log(item);
                    setFileList(item);
                    document.getElementById("loading").style.display = "none";

                } else if (data.code == "3015") {//非雙語類組者
                    setNonNative(data.message);
                    Swal.fire({
                        icon: 'warning',
                        text: "非報考雙語師資者，請勿上傳雙語資格證明",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "3037") {//非雙語類組者
                    setNonNative(data.message);
                    Swal.fire({
                        icon: 'warning',
                        text: "初試未勾選上傳附件資料，不可上傳。",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "3039") {//非雙語類組者
                    setNonNative(data.message);
                    Swal.fire({
                        icon: 'warning',
                        text: "資料已審核，不可更改資料，若需查看請至【3-1】。",
                    })
                    document.getElementById("loading").style.display = "none";
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                }
                else {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {

                            //history.push('Sign_Status');
                        }
                    })


                }

            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })

    }

    //編輯文件資料
    function setDoc(index, index2, docNo, e, objName, type = null) {
        console.log(index, index2, docNo, e, objName, type);
            const upDocList = fileList.map((obj, i) => {
                switch (docNo) {
                    case 'B002':
                        if (type == "R") {
                            obj.dT1List[index] = objName;
                        } else {
                            if (objName == "uExDocMark2") {
                                obj[objName] = e;
                            } else if (objName == "uExDocMark3"){
                                obj.dT3List[index].rName = e;
                                
                            }
                        }
                        return { ...obj };
                        break;
                    case 'B003':
                    case 'B004':
                    case 'B005':
                    case 'B006':
                    case 'C001-3':
                        obj[objName] = e;
                        return { ...obj };
                        break;
                }
            });
        setFileList(upDocList);
       
    }


    //上傳檔案
    function handleChange(index, event) {
        document.getElementById("loading").style.display = "block";
        console.log(index);
        if (event.target.files.length > 0) {
            console.log(event.target.files);

            if (event.target.files[0].size > fileMaxSize) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "上傳檔案過大"
                    })
                )

            }
            if (!fileType.includes(event.target.files[0].type)) {
                event.target.value = "";
                return (
                    Swal.fire({
                        icon: 'error',
                        text: "請選擇檔案格式 jpg 或 png"
                    })
                )
            }
            setFile(event.target.files[0]);
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function () {
                var list = {};
                if (index == 0) {
                    document.getElementById("loading").style.display = "none";
                    setImgBase({ [index]: reader.result });
                } else {
                    document.getElementById("loading").style.display = "none";
                    setImgBase({ ...imgBase, [index]: reader.result });
                }
            }
            document.querySelector(".viewImg" + index).classList.remove('hide');
            document.getElementById("Img").classList.add("hide");
            fileList[index].base64 = "";
            console.log(fileList);
        } else {
            document.getElementById("loading").style.display = "none";
        }
    }

    //Open彈窗
    function openModal(index) {
        setimgBaseItem(fileList[index].base64);
        var target = document.querySelector("#modal-showImg");
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }

    //Close彈窗
    function hideModal() {
        var target = document.querySelector('#modal-showImg');
        var layerClass = "layer-1";
        target.classList.remove('active');
        target.classList.remove(layerClass);
    }

    //儲存提示訊息
    function saveDataAlert(index) {
        var f = document.getElementsByName("file" + index)[0].value;
        if (fileList[index].uExDocATTID !=null && f=="") {

        }
       
        //var f = document.querySelector("input[type='file']");
        //console.log(f.value)
        
        if (f == "" && fileList[index].uExDocATTID == null) {
            Swal.fire({
                icon: 'error',
                text: '請上傳檔案'
            })
        } else {
            Swal.fire({
                icon: 'warning',
                text: "請務必留意上傳文件之正確性及是否符合相關條件；倘於審查時發現資績證明文件有誤，該資績分數不予採計",
            }).then((result) => {

                saveImg(index);
            })
        }


    }

    //數字補0
    function autoSupplement(val) {
        var n = String(val);
        return n.padStart(2, '0')

    }

    //儲存
    function saveImg(index) {
        document.getElementById("loading").style.display = "block";

        if (document.getElementById("putButton" + index) && !document.getElementById("putButton" + index).disabled) {
            document.getElementById("putButton" + index).disabled = true;
        } else {
            return null;
        }
        //console.log(file);
      
        var chk = true;
        var Mark1 = "", Mark2 = "", Mark3 = "";
        switch (fileList[index].docNo) {
            case 'B002':
                var k1 = '', k3 = '';
                fileList[index].dT1List.forEach((obj, i) => {
                    k1 += obj.rName + ",";
                })
                Mark1 = k1.substring(0, k1.length - 1);

                if (fileList[index].uExDocMark2 == "") {
                    chk = false;
                } else {
                    Mark2 = fileList[index].uExDocMark2;
                }
               
                fileList[index].dT3List.forEach((obj, i) => {
                    
                    if (obj.rName == "") {
                        chk = false;
                    } else {
                        k3 += obj.rName + ",";
                    }
                })
                Mark3 = k3.substring(0, k3.length - 1);
                break;
            case 'B003':
            case 'B004':
            case 'C001-3':

                if (fileList[index].uExDocMark1 == "" && fileList[index].dM1 != null) {
                    chk = false;
                } else {
                    Mark1 = fileList[index].uExDocMark1;
                }
                if (fileList[index].uExDocMark2 == "" && fileList[index].dM2 != null) {
                    chk = false;
                } else {
                    Mark2 = fileList[index].uExDocMark2;
                }
                if (fileList[index].uExDocMark3 == "" && fileList[index].dM3 != null) {
                    chk = false;
                } else {
                    Mark3 = fileList[index].uExDocMark3;
                }
                break;
            case 'B005':
            case 'B006':
                if (fileList[index].uExDocMark1 == "" || fileList[index].uExDocMark1 == "0") {
                    chk = false;
                } else {
                    Mark1 = fileList[index].uExDocMark1;
                }
                if (fileList[index].starY == "" || fileList[index].starM == "") {
                    chk = false;
                } else {
                    Mark2 = (parseInt(fileList[index].starY, 10) + 1911) + "-" + autoSupplement(parseInt(fileList[index].starM)) + "-" + autoSupplement(parseInt("01"));
                }
                if (fileList[index].endY == "" || fileList[index].endM == "") {
                    chk = false;
                } else {
                    Mark3 = (parseInt(fileList[index].endY, 10) + 1911) + "-" + autoSupplement(parseInt(fileList[index].endM)) + "-" + autoSupplement(parseInt("01"));
                }
                break;
        }

        if (!chk) {
            document.getElementById("loading").style.display = "none";
            Swal.fire({
                icon: 'error',
                text:"【"+ fileList[index].docName +"】資料尚未填寫完整，請重新確認",
            })
            document.getElementById("putButton" + index).disabled = false;
        } else {

        
        //console.log(Mark1);
        //console.log(Mark3);
        //return false;

        API_PUT_RTUpFile_SaveRTFileData({
            "UserID": UserData.userID,
            "DocNo": fileList[index].docNo,
            "DocName": fileList[index].docName,
            "GATTID": fileList[index].uExDocATTID,// --附件檔案流水號
            "UserEKID": fileList[index].userEKID,// --使用考報考類組序號
            "UExDocID": fileList[index].uExDocID,// --使用者文件流水號
            "DocRefFile": fileList[index].docRefFile,// --Y // N
            "UExDocMark1": Mark1,
            "UExDocMark2": Mark2,
            "UExDocMark3": Mark3,
            "File": (file === undefined ? fileList[index].file : [{ "FileName": (file.name ? file.name : uploadDoc + "undefindedFile.jpg"), "Base64String": imgBase[index] }])
        }).then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location.href = "../";
                }
                console.log(data);
                if (data.code == "0000") {
                    document.getElementById("loading").style.display = "none";
                    imgBase[index] = "";
                    Swal.fire({
                        icon: 'success',
                        text: "儲存成功",
                    })
                    getData();
                    console.log(upFileMail(UserData.userID));
                } else if (data.code == "9998") {
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    }).then((result) => {
                        if (data.result && data.result.redirectUrl) {
                            window.location.href = data.result.redirectUrl;
                        } else {
                            window.location.href = "/";
                        }
                    });
                    document.getElementById("loading").style.display = "none";
                } else {
                    document.getElementById("loading").style.display = "none";
                    Swal.fire({
                        icon: 'error',
                        text: data.message,
                    })
                }
                document.getElementById("putButton" + index).disabled = false;
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push('/');
                }
            })
        }
    }

    //下一步驟
    function Next() {
        window.location.href = next;
        //console.log(imgBase);
    }

    
    return (
        <>
            <div id="master-main-bg">
                <LeftSideW modalTitle="複試報名系統" modalSide="複試報名系統" modalUrl={window.location.pathname + window.location.search} />
                <div id="content">
                    <div dangerouslySetInnerHTML={{ __html: docMark_p }}></div>
                    <div dangerouslySetInnerHTML={{ __html: docMark_t }}></div>
                    <table className="datatable" style={{ borderBottom: "2px solid var(--secondry-color)" }}>
                        <tbody>
                            <tr>
                                <td width="40%">上傳說明</td>
                                <td width="40%">瀏覽|選擇檔案</td>
                                <td width="20%">儲存</td>
                            </tr>

                            {fileList == null || fileList.length == 0 ?
                                <tr>
                                    <td colSpan="4">
                                        {nonNative}
                                        {/*這裡顯示可能是本土語言無效 或是 雙語無效*/}
                                        {/*2-6,2-12共用網址 有點難分辨這部分要怎麼打*/}
                                    </td>
                                </tr>
                                :
                                fileList.map((y, i) => (
                                    <tr key={"fileList" + i} >
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div style={{ padding: "0 7px" }}>
                                                    <span>
                                                        {y.docName}<br />
                                                        {itemList.map((x, i2) => (
                                                            y.dM1 != null && (
                                                                
                                                                x.dM1 == 'T' ? (
                                                                    docNo == 'C001' ? (
                                                                        y.uExDocChk == '1' &&(
                                                                            <span>{x.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <input type="text" value={y.uExDocChk == '1' ? y.uExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "200px" }} onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, "uExDocMark1")} />
                                                                                <br />
                                                                            </span>
                                                                            )
                                                                    ):(
                                                                        <span>
                                                                            <input type="text" value={y.uExDocChk == '1' ? y.uExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "250px" }} onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, "uExDocMark1")} />{x.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <br />
                                                                        </span>
                                                                    )
                                                                ) : (
                                                                    x.dM1 == 'N' ? (
                                                                        <span>
                                                                            <input type="number" min="0" value={y.uExDocChk == '1' ? y.uExDocMark1 : ""} name={y.docNo + y.dM1} style={{ width: "60px" }} maxLength="1" onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, "uExDocMark1")} />{x.dT1}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span id={y.docNo + y.dM1} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                            <br />
                                                                        </span>
                                                                    ) : (
                                                                        x.dM1 == 'R' ? (
                                                                                    y.dT1List.map((xy, i3) => (
                                                                                        <span >
                                                                                            {x.dT1List.map((zz, i4) => (
                                                                                                xy.rField == zz.rField && (
                                                                                                    <span >
                                                                                                        <input id={"engBLv" + zz.rNo} type="radio" value={zz.rNo} name={zz.rField} checked={xy.rName == zz.rName} onChange={(e) => setDoc(i3, i2, y.docNo, e.target.value, zz, "R")} />
                                                                                                        {zz.rName}
                                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;


                                                                                                    </span>
                                                                                                )


                                                                                            ))}
                                                                                            < br />
                                                                                        </span>
                                                                            ))
                                                                        ) : (
                                                                            x.dM1 == 'CYM' ? (
                                                                                <span>
                                                                                    {x.dT1}
                                                                                    <select id="" name={y.docNo + y.dM1 + y.starY} value={y.uExDocChk == '1' ? y.doc.StarY1 : ""} onChange={(e) => { setDoc(i, i2, y.docNo, e.target.value, "starY") }}>
                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                        {
                                                                                            [...new Array(66)].map((value, j) => (
                                                                                                <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    年
                                                                                    <select id="" name={y.docNo + y.dM1 + y.starM} value={y.uExDocChk == '1' ? y.starM : ""} onChange={(e) => { setDoc(i, i2, y.docNo, e.target.value, "starM") }}>
                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                        {
                                                                                            [...new Array(12)].map((value, j) => (
                                                                                                <option key={j} value={j + 1}>{j + 1}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <br />
                                                                                </span>
                                                                            ) : (
                                                                                <span></span>
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                         ))}
                                                        {itemList.map((x, i2) => (
                                                            y.dM2 != null && (
                                                                x.dM2 == 'T' ? (
                                                                    <span>
                                                                        <input type="text" value={y.uExDocChk == '1' ? y.uExDocMark2 : ""} name={y.docNo + y.dM1} style={{ width: "200px" }} onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, "uExDocMark2")} />{x.dT2}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <br />
                                                                    </span>
                                                                ) : (
                                                                    x.dM2 == 'N' ? (
                                                                        <span>
                                                                            <br />
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <input type="number" min="0" value={y.uExDocChk == '1' ? y.uExDocMark2 : ""} name={y.docNo + y.dM2} style={{ width: "60px" }} maxLength="1" onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, "uExDocMark2")} />{x.dT2}
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span id={y.docNo + y.dM2} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                            <br />
                                                                        </span>
                                                                    ) : (
                                                                        x.dM2 == 'R' ? (
                                                                            y.dT1List.map((xy, i3) => (
                                                                                x.dT1List.map((zz, i4) => (
                                                                                    <span>
                                                                                        <br />
                                                                                        <span style={{ marginLeft: "30px" }}>{i4 + 1}. </span>
                                                                                        {zz.attr.map((zy, i5) => (
                                                                                            <span>
                                                                                                <input type="radio" value={zy.value} checked={zy.chked == "1"} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                            </span>
                                                                                        ))}
                                                                                    </span>
                                                                                ))
                                                                            ))
                                                                        ) : (
                                                                            x.dM2 == 'CYM' ? (
                                                                                <span>
                                                                                    {x.dT2}
                                                                                    <select id="" name={y.docNo + y.dM1 + y.starY} value={y.uExDocChk == '1' ? y.starY : ""} onChange={(e) => { setDoc(i, i2, y.docNo, e.target.value, "starY") }}>
                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                        {
                                                                                            [...new Array(66)].map((value, j) => (
                                                                                                <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    年
                                                                                    <select id="" name={y.docNo + y.dM1 + y.starM} value={y.uExDocChk == '1' ? y.starM : ""} onChange={(e) => { setDoc(i, i2, y.docNo, e.target.value, "starM") }}>
                                                                                        <option value="" selected="selected">請選擇...</option>
                                                                                        {
                                                                                            [...new Array(12)].map((value, j) => (
                                                                                                <option key={j} value={j + 1}>{j + 1}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <br />
                                                                                </span>
                                                                            ) : (
                                                                                <span></span>
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        ))}
                                                        {
                                                            itemList.map((x, i2) => (
                                                                y.dM3 != null && (
                                                                    x.dM3 == 'T' ? (
                                                                        <span>
                                                                            {
                                                                                y.dT3List.map((xy, i4) => (
                                                                                    x.dT3List.map((xx, i3) => (
                                                                                        i4 == i3 && (
                                                                                            i3 == 1 ? (
                                                                                                <span>
                                                                                                    <span>
                                                                                                        <input type="text" value={xy.rName} name={y.docNo + i3} style={{ width: "200px" }} onChange={(e) => setDoc(i4, i2, y.docNo, e.target.value, "uExDocMark3", "T")} />{xx.rName}
                                                                                                    </span>
                                                                                                    <font color="red">請填入組別或不分組。</font>
                                                                                                </span>
                                                                                            ) : (
                                                                                                <span>
                                                                                                    <span>
                                                                                                            <input type="text" value={xy.rName} name={y.docNo + i3} style={{ width: "200px" }} onChange={(e) => setDoc(i4, i2, y.docNo, e.target.value, "uExDocMark3", "T")} />{xx.rName}<br />
                                                                                                    </span>
                                                                                                </span>
                                                                                            )
                                                                                        )
                                                                            
                                                                                    ))
                                                                                ))
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        x.dM3 == 'N' ? (
                                                                            <span>
                                                                                <br />
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <input type="number" min="0" value={y.uExDocChk == '1' ? y.uExDocMark3 : ""} name={y.docNo + y.dM2} style={{ width: "60px" }} maxLength="1" onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, "uExDocMark3")} />
                                                                                {x.dT3
                                                                                }&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <span id={y.docNo + y.dM2} className="hide" style={{ color: "#0007ef", fontSize: "16px", marginLeft: "15px", marginRight: "15px", fontWeight: "bold" }}>填入數字需大於0</span>
                                                                            </span>
                                                                        ) : (
                                                                            x.dM3 == 'R' ? (
                                                                                x.dT1List.map((zz, i4) => (
                                                                                    <span>
                                                                                        <br />
                                                                                        <span style={{ marginLeft: "30px" }}>{i4 + 1}. </span>
                                                                                        {
                                                                                            zz.attr.map((zy, i5) => (
                                                                                                <span>
                                                                                                    <input type="radio" value={zy.value} checked={zy.chked == "1"} name={zz.docMark} style={{ width: "60px" }} onChange={(e) => setDoc(i, i2, y.docNo, e.target.value, zz.docMark, "R")} />{zy.Cname}
                                                                                                </span>
                                                                                            ))
                                                                                        }
                                                                                    </span>
                                                                                ))
                                                                            ) : (
                                                                                x.dM3 == 'CYM' ? (
                                                                                    <span>
                                                                                        {x.dT3}
                                                                                        <select id="" name={y.docNo + y.dM3 + y.endY} value={y.uExDocChk == '1' ? y.endY : ""} onChange={(e) => { setDoc(i, i2, y.docNo, e.target.value, "endY") }}>
                                                                                            <option value="" selected="selected">請選擇...</option>
                                                                                            {
                                                                                                [...new Array(66)].map((value, j) => (
                                                                                                    <option key={j} value={j + birYStar}>{j + birYStar}</option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                        年
                                                                                        <select id="" name={y.docNo + y.dM3 + y.endM} value={y.uExDocChk == '1' ? y.endM : ""} onChange={(e) => { setDoc(i, i2, y.docNo, e.target.value, "endM") }}>
                                                                                            <option value="" selected="selected">請選擇...</option>
                                                                                            {
                                                                                                [...new Array(12)].map((value, j) => (
                                                                                                    <option key={j} value={j + 1}>{j + 1}</option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                        月
                                                                                    </span>
                                                                                ) : (
                                                                                    <span></span>
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            ))
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            {y.base64 != "" && y.uExDocChk == '1' && (
                                                <div>
                                                    <font color="blue">已上傳成功</font>
                                                    <a href="#" onClick={(e) => openModal(i)} ><font color="red">(預覽)</font></a>
                                                </div>
                                            )}

                                            <div>
                                                {y.docRefFile != '9' && y.uExDocChk == '1' ? (
                                                  
                                                    y.userDataLock == '1' ?(
                                                        <span>已下載報名表，無法變更檔案。</span>
                                                    ) : (
                                                        y.uDocCF != '0' && y.uDocCF != null ? (
                                                            <span>已審核，無法變更檔案。</span>
                                                        ) : (
                                                            <span>
                                                                <input name={`file${i}`} type="file" size="10" onChange={(e) => handleChange(i, e)} accept={fileDotType.join(",")} />
                                                                <br />
                                                                <font color="red">※如有上傳檔案請按【儲存】</font>
                                                                <br />
                                                            </span>
                                                        )

                                                    )
                                                ) : (
                                                    <span>初試未勾選上傳附件資料，不可上傳。</span>
                                                )}

                                                <img className={y.uExDocChk == '1' && y.base64 != "" ? "" : "hide"} id="Img" src={y.base64} name="Std_img" height="110" />
                                                <img className={`hide viewImg${i}`} src={imgBase[i]} name="Std_img" height="110" />
                                            </div>
                                        </td>
                                        <td>
                                            {y.docRefFile != '9' ? (
                                                y.uExDocChk == '1' && (

                                                    y.userDataLock == '1' ? (
                                                        <span>已下載報名表，無法變更檔案。</span>
                                                    ) : (
                                                        y.uDocCF != '0' && y.uDocCF != null ? (
                                                            <span>已審核，無法變更檔案{y.uDocCF}</span>
                                                        ) : (
                                                            <input type="button" id={`putButton${i}`} name="saveImg" onClick={(e) => saveDataAlert(i)} value="儲存" />
                                                        )
                                                    )
                                                )
                                            ) : (
                                                    <span>初試上傳附件資料，無法變更。</span>
                                                )}
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                    <div dangerouslySetInnerHTML={{ __html: docMark_b }}></div>
                    <div align="center"><br />
                        <input name="button" type="button" onClick={Next} value="下一步" style={{ margin: "0.4rem 0" }} />
                    </div>

                </div>

                <div id="modal-showImg" className="modal large" data-modal>
                    <div className="modal-container">
                        <div className="modal-body" id="accenable">
                            <fieldset>
                                <legend>圖片預覽</legend>
                                <img id="viewImg" src={imgBaseItem} name="Std_img" style={{ width: "100%" }} />

                            </fieldset>
                            <br />
                            <div className="btn-holder text-center">
                                <input name="button" type="button" onClick={hideModal} value="關閉" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default Second_Other_Doc;
